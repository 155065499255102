export function abs(n, type) {
  if (typeof n !== 'number')
    return null
  return (n === 0 ? '' : (n > 0 ? '+' : '-')) + format(Math.abs(n), type)
}

export function format(n, type, decimalPoints = 2, fallback) {
  if (typeof n !== 'number') {
    if (fallback) return fallback
    n = 0
  }
  if (type === 'float') {
    return JSON.stringify(n).replace('.', ',')
  }
  const params = type === 'integer' ? { maximumFractionDigits: 0 } : { minimumFractionDigits: decimalPoints, maximumFractionDigits: decimalPoints }
  if (type === 'currency')
    params.currency = 'BRL'
  const res = n.toLocaleString('pt-BR', params)
  return (type === 'percent') ? res + '%' : res
}

export const formatterBRL = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })

export function convertToInternationalCurrencySystem(labelValue, typeFormatted) {
  if (!labelValue) return
  const formattedNumber = (value, factor, type) => {
    const result = typeFormatted === 'integer' ? (Math.abs(Number(value)) / factor).toFixed(0) : (Math.abs(Number(value)) / factor).toFixed(2)
    return `${result.toString().replace('.', ',')} ${type}`
  }
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e+9

    ? formattedNumber(labelValue, 1.0e+9, 'B')
    // Six Zeroes for Millions 
    : Math.abs(Number(labelValue)) >= 1.0e+6

      ? formattedNumber(labelValue, 1.0e+6, 'M')
      // Three Zeroes for Thousands
      : Math.abs(Number(labelValue)) >= 1.0e+3

        ? formattedNumber(labelValue, 1.0e+3, 'K')

        : Math.abs(Number(labelValue));

}