import Modal from "react-modal"
import { MdClose } from "react-icons/md"
import { LAYOUT_BREAKPOINT_WIDTH_MD } from 'const'

function ModalShowGuide({ isModalOpen, closeModal = () => {}, card, width }) {
  const mobile = width < LAYOUT_BREAKPOINT_WIDTH_MD

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={{ 
        content: { 
          padding: 0,
          maxWidth: `${mobile ? '100%' : '70%'}`,
          maxHeight: '87%',
          margin: 'auto',
          marginRight: mobile ? -40 : "auto",
          marginLeft: mobile ? -40 : "auto",
          borderRadius: '12px' 
        }
      }}
    >
      <div className="relative h-full overflow-hidden">
        <button className="sticky float-right right-2 top-2 text-xl text-primary" title="Fechar" onClick={closeModal}>
          <MdClose />
        </button>
        {card?.link &&
          <div className="w-full h-full p-4 flex items-center">
            <iframe src={card.link} className="w-full aspect-video mini-scrollbar" allow='autoplay' title={card.title} />
          </div>
        }
      </div>
    </Modal>
  )
}

export default ModalShowGuide