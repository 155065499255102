import { useContext, useEffect, useState } from "react"
import ComDinheiroApi from 'api/ComDinheiroApi'
import AuthContext from "context/AuthContext"

export function useWalletUrl() {

  const [urlWallet, setUrlWallet] = useState(null)  
  const { userInfo: { userIntegration } } = useContext(AuthContext)
  const isConnected = !!userIntegration?.isConnected

  useEffect(() => {
    if (urlWallet) return
    ComDinheiroApi.getWalletUrlIframe().then(({ URL }) => {
      if (URL) setUrlWallet(URL)
    })
  }, [urlWallet, setUrlWallet])

  return { urlWallet, isConnected }

}