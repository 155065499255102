import PreferencesApi from "api/PreferencesApi"
import QuoteApi from "api/QuoteApi"
import CompaniesApi from 'api/CompaniesApi'
import BalanceSheetsApi from 'api/BalanceSheetsApi'
import { sortBy as sortByFn } from "lodash"
import { createContext, useContext, useEffect, useState } from "react"
import PreferencesContext from "./PreferencesContext"
import { usePermission } from "hooks/usePermission"
import { Keys, KEYS_API } from "const"
import StatisticApi from "api/StatisticApi"

export const QuotationPanelContext = createContext({
  lists: null,
  activeList: null,
  origins: [],
  permission: null,
  viewTypes: [],
  widgetSize: 1,
  getRealSize: () => new DOMRect(),
  createList: (newList) => { },
  updateList: (id, toUpdateProps) => { },
  selectListById: () => { },
  removeSymbol: (listId, symbolToRemove) => { },
  deleteList: (listIdd) => { },
  getListById: (id) => { },
})

export function QuotationPanelContextProvider({ children, widgetProps, widgetSize }) {
  const [lists, setLists] = useState(null)
  const [activeList, setActiveList] = useState(null)
  const [sortBy, setSortBy] = useState(widgetProps.params?.sortBy || null)
  const [viewTypes, setViewTypes] = useState([{ type: 'list', key: 'CardView', isActive: true }, { type: 'table', key: 'TableView', isActive: false }])
  const [display, setDisplay] = useState()
  const { events: preferencesEvents } = useContext(PreferencesContext)
  const [tableView, setTableView] = useState({ index: 0, tab: { name: 'PRINCIPAL' } })
  const [loadingList, setLoadingList] = useState(false)
  const quotationPermission = usePermission('QuotationPanel')
  const defaultTabs = [{ name: "PRINCIPAL" }, { name: "VALUATION" }, { name: "RENTABILIDADE" }]

  const ordered = (allLists) => sortByFn(allLists, [x => x.type, x => x.name.trim().toLowerCase()])

  useEffect(() => {
    PreferencesApi.getPanelQuotationList().then(async lists => {
      const permissonedLists = lists.map(item => {
        const tabs = []
        item.principal && tabs.push({ name: "PRINCIPAL" })
        item.valuation && tabs.push({ name: "VALUATION" })
        item.rentability && tabs.push({ name: "RENTABILIDADE" })
        item.tabs = item.order > 0 ? tabs : defaultTabs
        return { ...item, order: item.order || 0 }
      })
      setLists(ordered(permissonedLists))
      selectList(lists.find(x => x.id === widgetProps?.params?.activeListId) || lists.find(x => x.order === 1))
    })
  }, [])

  useEffect(() => {
    const arr = viewTypes.map((viewType) => {
      const foundPermission = quotationPermission?.childrens?.find((permission) => permission.key === viewType.key)
      return { ...viewType, isActive: viewType.isActive ? viewType.isActive : !!foundPermission }
    })
    setViewTypes(arr)
    const foundType = arr.find(x => x.isActive)
    setDisplay(foundType?.type || null)
  }, [])

  useEffect(() => {
    const callback = (interestList) => {
      setLists(x => x?.map(list => interestList.id === list.id ? interestList : list))
      if (activeList.id === interestList.id)
        setActiveList(interestList)
    }
    preferencesEvents.on('changeInterestList', callback)
    return () => preferencesEvents.off('changeInterestList', callback)
  }, [activeList, preferencesEvents, setLists])

  useEffect(() => {
    if (display === 'table') {
      if (tableView?.index === 2) profitabilityViewInTable(activeList)
      if (tableView?.index === 1) valuationViewInTable(activeList)
    }
  }, [tableView, display])

  const createList = async newList => {
    return PreferencesApi.createPanelQuotationList([newList]).then(res => {
      res[0].tabs = defaultTabs
      setLists(l => ordered([...l, ...res]))
      selectList(res[0]) // Set first list 
    })
  }

  const updateList = async (id, toUpdateProps) => {
    const list = lists.find(x => x.id === id)
    if (!list) throw new Error(`List id ${id} not found!`)
    for (const key in list) {
      if (toUpdateProps && toUpdateProps[key])
        list[key] = toUpdateProps[key]
    }
    await PreferencesApi.updatePanelQuotationList(list)
    selectList(list, true)
  }

  const updateSymbolsAndSelectList = (list) => {
    setLists((lists) => lists.map((x) => (list.id === x.id) ? list : x))
    setActiveList({ ...list })
  }

  const selectList = async (list, updatedSortBy = false) => {
    if (display === 'table' && !updatedSortBy && tableView.index !== 0) {
      setTableView({ index: 0, tab: { name: 'PRINCIPAL' } })
    }

    const response = await requestFieldsApi(list.symbols)

    // updateListLocal({ ...list, symbols: response })
    // setActiveList({ ...list, symbols: response })
    updateSymbolsAndSelectList({ ...list, symbols: response })

    if (widgetProps?.params?.activeListId !== list.id)
      updateParams({ activeListId: list.id })
  }

  const selectListById = (id) => {
    const found = getListById(id) || lists[0]
    selectList(found)
  }

  const getListById = (id) => {
    return lists.find(x => x.id === id)
  }

  const removeSymbol = (listId, { symbol, origin }) => {
    const list = getListById(listId)
    if (!list) throw new Error(`List id ${listId} not found!`)

    const index = list.symbols.findIndex(s => symbol === s.symbol && origin === s.origin)
    if (index === -1)
      throw new Error(`Symbol ${symbol} not found in list ${list.name}!`)
    list.symbols.splice(index, 1)
    return updateList(listId, { symbols: list.symbols })
  }

  const deleteList = async (listId) => {
    await PreferencesApi.deletePanelQuotationList(listId)
    setLists(oldLists => ordered(oldLists.filter(list => list.id !== listId)))
    if (activeList.id === listId) setActiveList(lists.find(list => list.id !== listId))
  }

  const updateSortBy = (colAccessor, order) => {
    const newSortBy = {
      col: colAccessor,
      order
    }
    const multiplier = order === 'ASC' ? 1 : -1
    const symbols = [...activeList.symbols].sort((a, b) => a[colAccessor] > b[colAccessor] ? (1 * multiplier) : (a[colAccessor] < b[colAccessor] ? (-1 * multiplier) : 0))
    updateParams({ sortBy: newSortBy })
    setSortBy(newSortBy)
    updateList(activeList.id, { symbols })
  }

  const toggleDisplay = (item) => {
    if (!item.isActive) return
    const newDisplay = item.type
    updateParams({ display: newDisplay })
    setDisplay(newDisplay)
  }

  function updateParams(value) {
    const newParams = { ...widgetProps.params, ...value }
    widgetProps.updateWidgetParams({ id: widgetProps.id, params: newParams })
  }

  const moveRow = (dragIndex, hoverIndex) => {
    if (!dragIndex) return
    const dragRecord = activeList.symbols[dragIndex]
    const newsSymbols = [...activeList.symbols]
    newsSymbols.splice(dragIndex, 1)
    newsSymbols.splice(hoverIndex, 0, dragRecord)
    updateParams({ sortBy: null })
    updateList(activeList.id, { symbols: newsSymbols })
  }

  async function requestFieldsApi(list) {
    setLoadingList(true)
    return await Promise.all(
      list.map(async (item) => {
        if (!item.name || item.cdec === null) return await QuoteApi
          .marketIndicators(item.origin, item.symbol, `${Keys.NOME},${Keys.CDEC}`)
          .then((r) => {
            if (r) return ({ ...item, name: r[0].fieldsMap[0].value, cdec: r[0].fieldsMap[1].value })
            return item
          })
        return item
      })
    ).finally(() => setLoadingList(false))
  }

  async function valuationViewInTable(list) {
    try {
      setLoadingList(true)
      const response = await Promise.all(list?.symbols.map(async (el) => {
        if (!el.company) {
          const companyResponse = await CompaniesApi.searchCompanyInfo(el.symbol).then(e => e ? e[0] : null)
          return { ...el, company: companyResponse, tradingName: companyResponse?.nomeFantasia }
        }
        return el
      }))
      updateSymbolsAndSelectList({ ...list, symbols: response })
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingList(false)
    }
  }

  async function profitabilityViewInTable(list) {
    setLoadingList(true)
    try {
      const fields = `${KEYS_API.MARGEM_BRUTA},${KEYS_API.MARGEM_EBITDA},${KEYS_API.MARGEM_LIQUIDA},${KEYS_API.MARGEM_OPERACIONAL},${KEYS_API.ROE},${KEYS_API.ROI}`

      const response = await Promise.all(list?.symbols.map(async (r) => {
        if (!r.companie) {
          r = { ...r, companie: await CompaniesApi.searchCompanyInfo(r.symbol).then(e => e ? e[0] : null) }
        }
        if (r.companie?.cnpj) {
          const result = await BalanceSheetsApi.financialIndicators({ cnpj: r.companie.cnpj, fields, periodo: 'trimestral', acumulado: false })
          const resFormatted = (result.length > 6 ? (() => {
            const mostRecentDate = new Date(Math.max(...result.map(obj => new Date(obj.dataFechamento))))
            const filteredResult = result.filter(obj => new Date(obj.dataFechamento).getTime() === mostRecentDate.getTime())
            return filteredResult.some(obj => obj.natureza === 'Consolidado')
              ? filteredResult.filter(obj => obj.natureza === 'Consolidado')
              : filteredResult
          })() : result)
            .map((r) => {
              const { id } = r
              return (r.valor ? { [id]: r.valor } : { [id]: r.valorIsolado })
            })
          const resResult = Object.assign({}, ...resFormatted)
          return { ...r, ...resResult }
        }
        return r
      }))
      // updateListLocal({ ...list, symbols: response })
      // setActiveList({ ...list, symbols: response })
      updateSymbolsAndSelectList({ ...list, symbols: response })

    } catch (error) {
      console.error(error)
    } finally {
      setLoadingList(false)
    }
  }

  return (
    <QuotationPanelContext.Provider value={{
      lists,
      activeList,
      widgetProps,
      sortBy,
      display,
      tableView,
      loadingList,
      widgetSize,
      quotationPermission,
      viewTypes,
      getRealSize: () => (document.getElementById(widgetProps.id) || document.body)?.getBoundingClientRect(),
      setLoadingList,
      setTableView,
      updateParams,
      createList,
      toggleDisplay,
      updateList,
      getListById,
      selectListById,
      removeSymbol,
      deleteList,
      updateSortBy,
      moveRow
    }}
    >
      {children}
    </QuotationPanelContext.Provider>
  )
}

async function concatDinamycLists(lists) {

  const ibovDataset = await StatisticApi.fetchIBOVQuotationAsset()

  const itemsToAdd = [
    {
      name: "IBOV",
      symbols: ibovDataset
    },
    {
      name: "Maiores Altas do IBOV",
      symbols: ibovDataset.sort((a, b) => a.var < b.var ? 1 : -1).map(({ symbol, origin }) => ({ symbol, origin })).splice(0, 20)
    },
    {
      name: "Maiores Baixas do IBOV",
      symbols: ibovDataset.sort((a, b) => a.var > b.var ? 1 : -1).map(({ symbol, origin }) => ({ symbol, origin })).splice(0, 20),
    }
  ].map((x, i) => ({ ...x, id: -i, type: 'DEFAULT_LIST' }))

  return lists.concat(itemsToAdd)

}

export default QuotationPanelContext
