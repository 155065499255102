import { useEffect, useState } from "react"
import SocketBroker from 'api/SocketBroker'

export function useSymbol(symbol, origin, prop = null, props = null) {

  const [data, setData] = useState(null)

  useEffect(() => {
    setData(null)
    const subscriptionId = SocketBroker.subscribe({ symbol, origin }, (data) => setData(data))
    return () => SocketBroker.unsubscribe(subscriptionId)
  }, [symbol, origin])



  if (data && prop) return data[prop]
  if (data && props) return { ...data, ...props }
  return data
}

export function RawSymbolInfo({ symbol, origin, prop = null }) {

  const val = useSymbol(symbol, origin, prop)
  return val
}

export default useSymbol