import { useEffect, useState } from "react"
import moment from 'moment'
import { Keys } from "const"
import useSymbol from "hooks/useSymbol"
import { abs, format } from "helpers/numberHelper"
import Delay from "./Delay"

export function IbovInfo() {

  const today = new Date()
  const IBOV = useSymbol('IBOV', 2)
  const [dateLast, setDateLast] = useState(null)
  const [data, setData] = useState({ percentageChange: null, variationPoints: null })

  useEffect(() => {
    const date = IBOV && IBOV[Keys.DULTCOT] && moment(IBOV[Keys.DULTCOT]).unix() * 1000
    setDateLast(new Intl.DateTimeFormat('pt-BR', { month: '2-digit', day: '2-digit' }).format(date))
    if (IBOV) {
      if (IBOV[Keys.VAR]) setData((e) => ({ ...e, percentageChange: IBOV[Keys.VAR] }))
      if (IBOV[Keys.DIFFEC]) setData((e) => ({ ...e, variationPoints: IBOV[Keys.DIFFEC] }))
    }
  }, [IBOV])

  return (
    <>
      <div className="flex flex-row space-x-2">
        <div className="flex items-center space-x-1">
          <h3 className="font-semibold">IBOVESPA</h3>
          <Delay item={IBOV} />
        </div>
        {
          IBOV && (
            <div className="font-bold space-x-1" title={`IBOVESPA: ${format(IBOV[Keys.ULTCOT], 'integer')} pts`}>
              <span className="text-lg">{format(IBOV[Keys.ULTCOT], 'integer')} pts</span>
              {data && (!data.percentageChange || data.percentageChange === 0) ? (
                <span className="pl-2 text-xs text-gray-500 font-medium">{dateLast}</span>
              ) : (
                <span className={`bg-opacity-20 px-1 rounded text-sm ${data?.percentageChange < 0 ? 'bg-loss text-loss' : 'bg-profit text-profit'}`}>
                  {format(data?.variationPoints, 'integer')} ({abs(data?.percentageChange, "percent")})
                </span>
              )
              }
            </div>
          )
        }
      </div>
      {data && data.variationPoints && data.variationPoints !== 0 ? (
        <div className="space-x-2 font-semibold">
          <span className="text-sm font-bold">
            Variação das ações do Ibovespa em % no dia {today.toLocaleDateString()}
          </span>
        </div>
      ) : (
        <div className="space-x-2 font-semibold">
          <span className="text-sm">
            Aguardando a abertura do mercado
          </span>
        </div>
      )}
    </>
  )
}