import { useContext, useEffect, useState } from "react"
import { Tooltip } from "react-tooltip"
import { MdAdd, MdRemove } from "react-icons/md"
import PreferencesContext from "context/PreferencesContext"
import { useCustomId } from "hooks/useCustomId"

export function BtnToggleFavoriteList({ fund, className }) {
  const [inMyList, setInMyList] = useState(false)
  const id = useCustomId(`toggle_my-list_funds`)
  const { favoriteFundList, toggleFavoriteList, isFundInFavoriteList } = useContext(PreferencesContext)

  useEffect(() => {
    setInMyList(isFundInFavoriteList(fund))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favoriteFundList, fund, toggleFavoriteList])

  return fund && (
    <button
      className={`w-4 h-4 flex justify-center items-center rounded-full font-semibold text-base ${className} ${inMyList ? 'bg-secondary text-primary' : 'bg-primary text-secondary'}`}
      id={id}
      onClick={() => toggleFavoriteList(fund)}>
      {inMyList ? <MdRemove /> : <MdAdd />}
      {id && (
        <Tooltip anchorSelect={'#' + id} className="opacity-100 text-center bg-primary p-[5px] font-semibold text-xs">
          {inMyList ? `Excluir dos fundos favoritos` : `Adicionar aos fundos favoritos`}
        </Tooltip>
      )}
    </button>
  )
}