import { useEffect, useContext, useState } from "react"
import { TradingView } from "charting_library/charting_library.standalone"
import WidgetContextualMenu from "shared/WidgetContextualMenu"
import AuthContext from 'context/AuthContext'
import { Datafeed, actionDataPersistence } from "components/trading_view/datafeed"
import { bodyOverrides } from "components/trading_view/helpers"
import { WidgetTitle, WidgetLinkTitle } from "shared/WidgetTitle"
import useTitle from "hooks/useTitle"
import DeniedPermissionWidget from "shared/DeniedPermissionWidget"
import { LAYOUT_BREAKPOINT_WIDTH_MOBILE } from "const"
import useMaxWindowSize from "hooks/useMaxWindowSize"

const TradingViewChart = () => {

  const dataChartDefault = {
    symbol: "Bovespa:PETR4",
    interval: "1D",
  }
  let data
  const dataStorage = window.localStorage.getItem('dataSymbol')
  if (dataStorage) data = JSON.parse(dataStorage) 
  const intervalStorage = window.localStorage.getItem('tradingview.chart.lastUsedTimeBasedResolution')

  const { userInfo } = useContext(AuthContext)
  const [dataPersistence, setDataPersistence] = useState(data ? data : dataChartDefault)

  useEffect(() => {
    if (data && Object.keys(data).length === 0) {
      window.localStorage.setItem('dataSymbol', JSON.stringify(dataChartDefault))
    } else {
      window.localStorage.setItem('dataSymbol', JSON.stringify(dataPersistence))
    }
  }, [dataPersistence])

  useEffect(() => {

    const script = document.createElement("script")
    script.type = "text/jsx"
    script.src = "%PUBLIC_URL%/charting_library/charting_library.js"

    document.head.appendChild(script)
    window.planActive = userInfo.signature
    window.tvWidget = new TradingView.widget({
      //debug: true,
      symbol: dataPersistence.symbol,
      interval: intervalStorage || dataChartDefault.interval,
      container: "tv_chart_container",
      datafeed: Datafeed(),
      client_id: 'https://www.valorone.com.br',
      user_id: userInfo.email,
      auto_save_delay: 2,
      load_last_chart: true,
      autosize: true,
      library_path: "/charting_library/",
      locale: "pt",
      timezone: "America/Sao_Paulo",
      loading_screen: { backgroundColor: "#ffffff" },
      settings_overrides: bodyOverrides,
      numeric_formatting: { decimal_sign: "," },
      time_scale: {
        min_bar_spacing: 4,
      },
      time_frames: [
        { text: "1d", resolution: "1", description: "1 minutos", title: '1D' },
        { text: "5d", resolution: "15", description: "15 minutos", title: '5D' },
        { text: "1m", resolution: "60", description: "60 minutos", title: '1M' },
        { text: "6m", resolution: "D", description: "1 dia", title: '6M' },
        { text: "1w", resolution: "D", description: "1 dia", title: 'YTD' },
        { text: "1y", resolution: "1W", description: "1 semana", title: '1A' },
        { text: "5y", resolution: "1M", description: "1 mês", title: '5A' },
      ],
      disabled_features: [
        'header_settings',
        'timezone_menu',
        'time_frames'
      ],
      enabled_features: [
        'adaptive_logo',
        'display_data_mode',
        'hide_image_invalid_symbol'
      ]
    })

    window.tvWidget.subscribe('onAutoSaveNeeded', () => {
      actionDataPersistence(setDataPersistence)
    })

    return () => script.remove()
  }, [])

  return <div className="w-full h-full" id="tv_chart_container" />
}

export function GraphicsWidget({ id, ...props }) {

  const isMobile = useMaxWindowSize(LAYOUT_BREAKPOINT_WIDTH_MOBILE)
  const widgetSize = props[isMobile ? 'mobile' : 'desktop'].w

  return (
    <div className="bg-white h-full rounded flex flex-col">
      <div className="drag-handle pt-4 px-4">
        <WidgetContextualMenu removeItem={() => props.removeItem(id)} />
        <WidgetLinkTitle title="Análise Gráfica" route="/analise-grafica" />
      </div>
      <DeniedPermissionWidget widgetSize={widgetSize} widgetType="MarketMap">
        <div className="flex-grow">
          <TradingViewChart />
        </div>
      </DeniedPermissionWidget>
    </div>
  )
}

export function GraphicsPage() {
  useTitle('Análise Gráfica')

  return (
    <div className="bg-white h-full rounded flex flex-col">
      <div className="pt-4 px-4">
        <WidgetTitle title="Análise Gráfica" />
      </div>
      <div className="flex-grow">
        <TradingViewChart />
      </div>
    </div>
  )
}

