import { createContext, useEffect, useState, useMemo } from "react"
import QuoteApi from "api/QuoteApi"
import PanelInvestApi from "api/PanelInvestApi"
import { Keys } from "const"

export const AlertMonitorContext = createContext({
  listsPanel: [],
  activeList: [],
  updateListsPanel: (key, params) => {},
  selectedActiveList: (list) => {},
  saveLists: () => {},
  cancelUpdateList: () => {}
})

export function AlertMonitorProvider({ children }) {

  const [ listsPanel, setListsPanel ] = useState([])
  const [ listsPanelDefault, setListsPanelDefault ] = useState([])
  const [ activeList, setActiveList ] = useState([])

  const configItems = useMemo(() => [
    { id: Keys.VAR, firstText: 'Variações relevantes no dia: acima de', lastText: 'ou abaixo de' },
    { id: Keys.VARMES, firstText: 'Variações relevantes nos últimos 30 dias: acima de', lastText: 'ou abaixo de' }

  ], [])

  const listsDefault = useMemo(() => [
    { id: 1, name: 'Minha carteira' },
    { id: 2, name: 'Favoritos' },
  ], [])


  async function fetchLists() {
    const response = await Promise.all(listsDefault.map(async (list) => {
      const res = await PanelInvestApi.getListId({id: list.id}).then((data) => {
        const updateData = data.map((el) => {
          const configs = updatedConfigItems(el.panelInvestDatas)
          return { ...el, panelInvestDatas: configs}
        })
        return updateData
      })
      return { ...list, ...res[0] }
    }))
    setListsPanel(response)
    setListsPanelDefault(response)
  }

  async function saveLists() {
    const body = listsPanel.find(({ id }) => id === activeList.id)
    return await PanelInvestApi.updateLists(body)
  }

  async function selectedActiveList(list) {
    if (!list) return
    if (list.panelInvestSymbols.length > 0) {
      const res = await getFieldsList(list.panelInvestSymbols)
      setListsPanel((lists) => {
        return lists.map((item) => {
          if (item.id === list.id) return { ...item, panelInvestSymbols: res }
          return item
        })
      })
      setActiveList({ ...list, panelInvestSymbols: res })
      return
    }
    setActiveList(list)
  }

  async function getFieldsList(list) {
    const mapFields = {}
    const response = await Promise.all(list.map(async (e) => {
      const res = await QuoteApi.marketIndicators(e.origin, e.symbol, `${Keys.NOME},${Keys.FONTE}`)
        res[0]?.fieldsMap.forEach((item) => {
          if (!item.value) return
          if (item.fieldId === Keys.NOME) mapFields['name'] = item.value
          if (item.fieldId === Keys.FONTE) mapFields['nameOrigin'] = item.value
        })
      return {...e, ...mapFields }
    }))
    return response
  }

  function updateListsPanel(params) {
    setListsPanel((lists) => {
      return lists.map((list) => {
        return list.id === activeList.id ? { ...list, ...params } : list
      })
    })
  }

  function updatedConfigItems(list) {
    const configContet = configItems.map((item) => {
      const res = list.find((e) => e.config === item.id)
      return { ...item, ...res }
    })
    return configContet
  }

  function cancelUpdateList() {
    setListsPanel(listsPanelDefault)
  }

  useEffect(() => {
    fetchLists()
  }, [])

  useEffect(() => {
    if (!activeList.id) selectedActiveList(listsPanel[1])
  }, [listsPanel])

  return (
    <AlertMonitorContext.Provider
      value={{
        listsPanel,
        activeList,
        updateListsPanel,
        selectedActiveList,
        saveLists,
        cancelUpdateList
      }}
    >
      {children}
    </AlertMonitorContext.Provider>
  )
}

