import { Link, useNavigate, } from 'react-router-dom'

export function WidgetTitle({ title }) {
  return (
    <h3 className="text-lg text-primary font-bold uppercase mb-2">{title}</h3>
  )
}

export function WidgetLinkTitle({ route, title }) {

  const navigate = useNavigate();


  return (
    <Link to={route}>
      <h2 href={route} onTouchEnd={() => navigate(route)} className="text-lg text-primary font-bold uppercase mb-2 hover:opacity-90 inline-block">
        {title} &gt;
      </h2>
    </Link>
  )
}