import { Outlet, useLocation, Navigate } from "react-router-dom"
import Header from "layout/Header"
import BottomNav from "./BottomNav"
import { PreferencesContextProvider } from "context/PreferencesContext"
import { QuickQuotationModalContextProvider } from "context/QuickQuotationModalContext"
import { AuthContextProvider } from "context/AuthContext"
import { ModalUsageGuide } from "pages/UsageGuide"
import { useEffect, useRef } from "react"

function MainLayout() {
  const { pathname } = useLocation()
  const mainRef = useRef()
  useEffect(() => {
    mainRef?.current?.scrollTo({ top: 0 })
  }, [pathname, mainRef])

  return (
    <AuthContextProvider>
      <PreferencesContextProvider>
        <QuickQuotationModalContextProvider>
          <div className="height-full flex flex-col" >
            <Header />
            <main ref={mainRef} className={`flex-grow height-full overflow-x-hidden overflow-y-auto`}>
              <Outlet />
              {pathname === '/' && <Navigate to="workspace" />}
              <ModalUsageGuide />
            </main>
            <BottomNav />
          </div>
        </QuickQuotationModalContextProvider>
      </PreferencesContextProvider>
    </AuthContextProvider>
  )
}

export default MainLayout
