import React, { useState, useEffect } from 'react'
import useTitle from "hooks/useTitle"
import HealthApi from "api/HealthApi"

const HealthCheck = () => {
  useTitle('Healthcheck')
  const [data, setData] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HealthApi.fetchHealth()
        setData(response)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [])

  if (!data) {
    return 'Loading...'
  }

  return (<code>{JSON.stringify(data)}</code>)
}

export default HealthCheck  