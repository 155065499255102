export function capitalize(string) {
  if(typeof string !== 'string') return string
  const arrayString = string.split(' ').map((e) => {
    if(e) return `${String(e[0]).toUpperCase()}${String(e).slice(1).toLowerCase()}`
  })
  return arrayString.join(' ')
}

export function whiteSpaces(string) {
  const args = string.trim()
  return args.replace(/\s{2,}/g, ' ')
}