import { Link } from "react-router-dom"
import useTitle from "hooks/useTitle"
import Logo from 'assets/logo-valor-one.svg'
import { useContext } from "react"
import AuthContext from "context/AuthContext"

function Unauthorized() {
  useTitle('Não autorizado')
  const { onLogout } = useContext(AuthContext)

  const goHome = () => {
    deleteAllCookies()
    localStorage.clear()
    sessionStorage.clear()
    onLogout()
  }

  return (
    <section className="flex flex-col items-center justify-center space-y-10 h-screen">
      <Link to="/" className="min-w-[200px]">
        <img src={Logo} alt="Valor One" className="h-16" />
      </Link>
      <h1 className="text-5xl text-center mb-4 text-gray-700">
        401 | Não autorizado
      </h1>
      <div className="text-center">
        {/* <Link to="/" className="link font-bold">Voltar para página inicial</Link> */}
        <button onClick={() => goHome()} className="link font-bold">Voltar para página inicial</button>
      </div>
    </ section>
  )
}


function deleteAllCookies() {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}


export default Unauthorized