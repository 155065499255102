import { FetchError, InternalFetchError } from "errors/FetchError";
import { BASE_URL, getApiHeaders, headers, customFetch } from "./settings";

const URL = 'Account';
const URL_NOTIFICATION = 'Notification';

const AuthorizationApi = {
  authorize: function (data) {
    return customFetch(`${BASE_URL}/${URL}/authorization`, {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    }).then((response) => response.json());
  },
  cancelDegustation: async function (body) {
    try {
      const res = await customFetch(`${BASE_URL}/${URL}/canceldegustation`, { method: 'GET', headers: getApiHeaders() })
      const resBody = await res.json()
      if (!res.ok) throw new FetchError(res, resBody)
      return resBody
    } catch (error) {
      if (error instanceof FetchError) throw error
      throw new InternalFetchError(error)
    }
  },
  subscribeOneSignal: function (data) {
    return customFetch(`${BASE_URL}/${URL_NOTIFICATION}/subscribeonesignalplayerid`, {
      method: "POST",
      headers: getApiHeaders(),
      body: JSON.stringify(data),
    }).then((response) => response.json());
  },
  entryScreen: async (status) => {
    return await customFetch(`${BASE_URL}/${URL}/entryscreen/${status}`, {
      method: "PUT",
      headers: getApiHeaders()
    }).then((response) => response.json());
  }
};

export default AuthorizationApi;
