import Swal from 'sweetalert2'
import IcoWarning from "assets/icons/warning.svg"


export const warningAlert = Swal.mixin({
  title: 'Tem certeza que deseja fazer isso?',
  text: "Essa ação é irreversível!",
  icon: 'warning',
  showCancelButton: true,
  reverseButtons: true,
  confirmButtonColor: '#472f92',
  cancelButtonColor: '#7a7a7a',
  confirmButtonText: 'CONFIRMAR',
  cancelButtonText: 'CANCELAR'
})

export const confirmation = Swal.mixin({
  title: 'Tem certeza que deseja fazer isso?',
  text: "Essa ação é irreversível!",
  imageUrl: IcoWarning,
  showCancelButton: true,
  reverseButtons: true,
  confirmButtonColor: '#472f92',
  cancelButtonColor: '#9ADE31',
  confirmButtonText: 'CONECTAR',
  cancelButtonText: 'CANCELAR',
  customClass: {
    title: 'modal-title',
    text: 'modal-text',
    confirmButton: 'modal-confirm-button',
    cancelButton: 'modal-cancel-button',
  }
})

export const warningMessage = Swal.mixin({
  title: null,
  text: null,
  icon: 'warning',
  confirmButtonColor: '#472f92',
  confirmButtonText: 'FECHAR',
})

