import { FundsContext } from 'context/FundsContext'
import { useContext, useState } from "react"
import Modal from "react-modal"
import CloseButton from "shared/CloseButton"
import { toast } from 'react-toastify'
import NotificationModal from 'shared/modal/NotificationModal'
import { ModalAddFunds } from "shared/modal/Funds/ModalAddFunds"
import { ModalManageFunds } from "shared/modal/Funds/ModalManageFunds"
import { MdClear } from 'react-icons/md'
import useWindowSize from "hooks/useWindowSize"
import { LAYOUT_BREAKPOINT_WIDTH_MOBILE } from "const"
import { whiteSpaces } from 'helpers/stringHelper'

export function ModalCreateFundsList({ isOpen = true, closeModal, saveList }) {
  const { width } = useWindowSize()
  const isMobile = width < LAYOUT_BREAKPOINT_WIDTH_MOBILE

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={{ content: {
      maxWidth: 648,
      borderRadius: 12,
      margin: "auto",
      marginRight: isMobile ? -40 : "auto",
      marginLeft: isMobile ? -40 : "auto",
    } }}>
      <CloseButton className='absolute right-5 top-5 z-50' size={20} color={'#472F92'} onClick={closeModal} />
      <div className="h-full flex flex-col">
        <ContentCreateFundsList close={closeModal} saveList={saveList} isMobile={isMobile} />
      </div>
    </Modal>
  )
}

export function ContentCreateFundsList({ close, saveList, isMobile }) {

  const { createListPanel, listPanel, listActive } = useContext(FundsContext)
  const [isOpenNotification, setIsOpenNotification] = useState({ status: false })
  const [isOpenNotificationCreate, setIsOpenNotificationCreate] = useState({ status: false })
  const [isOpenModalAdd, setIsOpenModalAdd] = useState(false)
  const [isOpenModalManage, setIsOpenModalManage] = useState(false)

  const [nameNewList, setNameNewList] = useState('')
  
  const onSubmit = async () => {
    const name = nameNewList
    if (!name) {
      toast.error('O campo nome é obrigatório!')
      return
    }
    if (listPanel?.length >=5) {
      setIsOpenNotificationCreate({ status: true })
      return
    }
    try {
      if (!saveList) {
        await createListPanel({ Name: whiteSpaces(name) })
        toast.success(`Lista ${name} criada com sucesso!`)
        setIsOpenModalAdd(true)

      } else {
        await createListPanel({ Name: whiteSpaces(name), CNPJNumbers: [...listActive?.funds.map(({ cnpjNumberClean }) => cnpjNumberClean)] })
        toast.success(`Lista ${name} criada com sucesso!`)
        close()
      }
    } catch (error) {
      setIsOpenNotification({ status: true, msg: error.message })
    }
  }

  return (
    <div className="h-full flex flex-col justify-between" >
      <div className='flex flex-col space-y-9'>
        <h2 className="font-bold text-xl text-primary uppercase">{saveList ? 'Salvar como lista' : 'Criar uma nova lista'}</h2>
        <form className="relative space-y-2">
          <label htmlFor="panelName" className="label font-semibold text-primary text-base uppercase">Defina o nome da nova lista</label>
          <input
            onChange={(e) => setNameNewList(e.target.value.toUpperCase())}
            onKeyDown={(e) => {
              if(e.code === 'Enter') return onSubmit()
            }}
            value={nameNewList}
            id="panelName"
            type="text"
            className="input px-[30px] py-4 text-dark-gray"
            maxLength={20}
            placeholder="Digite aqui o nome (máx. 20 caracteres)"
            autoFocus
          />
          {nameNewList && <button className="absolute right-3 top-10 text-primary" onClick={() => setNameNewList('')}><MdClear /></button>}
          <span className='flex justify-end text-xs text-dark-gray'>{`${nameNewList?.length}/20`}</span>
        </form>
      </div>
      <div className='flex justify-center'>
        <button onClick={onSubmit} disabled={!!!nameNewList} className={`btn ${nameNewList ? 'btn-primary text-secondary' : 'bg-[#EDEDED] text-grayDisabled'}`}>
          {saveList ? 'Salvar' : 'Adicionar fundos'}
        </button>
      </div>
      {isOpenModalAdd && <ModalAddFunds closeModal={() => {
        close()
        setIsOpenModalAdd(false)
      }} />}
      {isOpenModalManage && <ModalManageFunds closeModal={() => {
        close()
        setIsOpenModalManage(false)
      }} />}
      <NotificationModal
        isOpen={isOpenNotification.status}
        closeModal={() => setIsOpenNotification({ status: false })}
        title={isOpenNotification?.msg}
        style={{ content: {
          maxWidth: 380,
          maxHeight: isMobile ? 260 : 230,
          borderRadius: 10,
          margin: "auto",
          marginRight: isMobile ? -40 : "auto",
          marginLeft: isMobile ? -40 : "auto",
        }}}
        actionButtons='one'
        closeLabel='fechar'
      />
      <NotificationModal
        isOpen={isOpenNotificationCreate.status}
        closeModal={() => setIsOpenNotificationCreate({ status: false })}
        style={{ content: { maxWidth: 380, maxHeight: 300, borderRadius: 10, margin: "auto" } }}
        successModal={() => setIsOpenModalManage(true)}
        title={'Você já alcançou o número máximo de listas personalizadas.'}
        successLabel='SIM'
        closeLabel='NÃO'
        actionButtons>
        <span>Gostaria de excluir alguma lista existente?</span>
      </NotificationModal>
    </div>
  )
}
