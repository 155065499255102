import { memoize } from "lodash"
import { BASE_URL, getApiHeaders, memoizeResolver, customFetch } from "./settings"

const URL = 'News'

const Api = {

  fetchNewsList: memoize(async function (data = {}) {
    try {
      // data.categories = null
      delete data.timer
      delete data.category
      if (!data.categories?.length) data.categories = undefined
      if (!data.companies?.length) data.companies = undefined
      const response = await customFetch(`${BASE_URL}/${URL}/NewsFeed`, {
        method: 'POST',
        headers: getApiHeaders(),
        body: JSON.stringify(data)
      })
      if (response.status === 204) {
        return []
      } else if (response.status !== 200) {
        console.error(response)
        throw new Error(response.status)
      }
      return response.json()
    } catch (error) {
      console.error(error)
      return null
    }

  }, memoizeResolver),

  getUrl: memoize(async function (type) {

    const urlMap = {
      video: 'playlistvideo',
      podcast: 'podcastlist',
    }

    if (!urlMap[type]) {
      console.error(`Type "${type}" not found on urlMap!`)
      return null
    }

    try {
      const response = await customFetch(`${BASE_URL}/${URL}/${urlMap[type]}`, { headers: getApiHeaders() })
      return response.json()
    } catch (error) {
      console.error(error)
      return null
    }
  }, memoizeResolver),

  getCategories: memoize(async function () {
    try {
      const response = await customFetch(`${BASE_URL}/${URL}/GetThemes`, { headers: getApiHeaders() })
      return response.json()
    } catch (error) {
      console.error(error)
      return null
    }
  }, memoizeResolver)

}

export default Api