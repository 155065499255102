import LoadingSVG from 'assets/loading.svg'

export function Loading({ size = 50, ...props }) {
  return (
    <img src={LoadingSVG} width={size + 'px'} {...props} alt="Carregando..." />
  )
}

export function LoadingBox({ size = 50 }) {
  return (
    <div className="h-full w-full flex items-center justify-center">
      <Loading size={size} />
    </div>
  )
}

export default Loading