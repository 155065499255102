import { useState } from "react"
import { MdClose } from "react-icons/md"
import SymbolAutocomplete from "./SymbolAutocomplete"
import { useEffect } from "react"
import { toast } from "react-toastify"

function SymbolSelectorAutocomplete({ symbolsList = [], onChange = () => {}, maxSymbolsLength = 100 }) {

  const [symbols, setSymbols] = useState([])

  useEffect(() => { setSymbols(symbolsList) }, [symbolsList])

  if (!Array.isArray(symbolsList)) return null

  const selectSymbol = ({ symbolCode: symbol, originId: origin }) => {

    if (maxSymbolsLength && symbols.length === maxSymbolsLength) {
      toast.warning(`Máximo de ${maxSymbolsLength} ativos atingido!`)
      return
    }

    if (symbols.some(x => x.symbol === symbol)) {
      toast.warning(`O ativo ${symbol} já está na incluso na lista!`)
      return
    }
    const newSymbolsList = [...symbols, { symbol, origin }] 
    setSymbols(newSymbolsList)
    onChange(newSymbolsList)
  }

  const removeSymbol = (symbol) => {
    if (symbols.every(x => x.symbol !== symbol)) 
      return
    const newSymbolsList = symbols.filter(s => s.symbol !== symbol)
    setSymbols(newSymbolsList)
    onChange(newSymbolsList)
  }

  return (
    <>
      <SymbolAutocomplete setSymbol={selectSymbol} />
      <div className="rounded border flex-1 p-2 h-44 overflow-y-scroll">
        { symbols.map(item => (
          <div className="pl-3 pr-2 py-1 rounded-lg inline-flex items-center space-x-1 bg-gray-100 mr-2 mb-2" key={`symbol-selector-${item.symbol}`}>
            <span className="font-semibold text-gray-800">{item.symbol}</span>
            <button className="text-sm text-gray-400 hover:text-gray-500" title={`Remover ${item.symbol}`} onClick={() => removeSymbol(item.symbol)}><MdClose /></button>
          </div>
        )) }
        { !symbols.length && (<div className="text-sm text-gray-400 m-2">Nenhum ativo selecionado</div>)}
      </div>
      <div className="text-right text-sm font-semibold text-gray-400">
        Adicionando {symbols.length} ativos
      </div>
    </>
  )
}

export default SymbolSelectorAutocomplete