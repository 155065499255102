import { HelpTooltip } from "shared/HelpTooltip"

export function RatingCustom({id, icon, label, value, position, description, classContainer, classContent }) {
  let valueShow
  if(!value) valueShow = 0
  valueShow = value
  return (
    <div className={`${classContainer}`}>
      <span className='space-x-1'>
        <span>{label}</span>
        {description && <HelpTooltip id={id + "_hanking_custom"} className="w-[232px] p-[5px] text-xs leading-4 rounded font-bold" place={position}>
          {description}
        </HelpTooltip>}
      </span>
      {icon 
        ? <div className='flex'>
          {[...Array(4)].map((_,i) => <div key={`iconPosition${i}`} className={`${valueShow < i+1 ? 'text-[#dbdbdb]' : 'text-primary'}`}>{icon}</div>)}
        </div>
        : <div className={`${classContent} w-full flex items-end gap-x-1`}>
          {[...Array(10)].map((_, i) => <div key={`barPosition${i}`} className={`${valueShow < i+1 ? 'bg-[#dbdbdb]' : 'bg-primary'} w-1 flex-none`} style={{height:`${12+(i*2)}px`}}></div>)}
          <span className="flex-none text-xs text-dark-gray">{`${valueShow} de 10`}</span>
        </div>
      }
    </div>
  )
}