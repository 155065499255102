import useTitle from "hooks/useTitle"

const H = ({ children }) => <span className="font-bold underline">{children}</span>

export default function Contract() {
  useTitle('Contrato de Assinatura')

  return (
    <section className="container mx-auto">
      <div className="p-12 flex flex-col space-y-4 bg-white">

        <h1 class="text-primary text-2xl font-bold">CONTRATO DE ASSINATURA DO VALOR ONE</h1>

        <h2 className="font-bold text-lg">1. CARACTERÍSTICAS E CONDIÇÕES GERAIS </h2>

        <p>1.1. O <H>“Valor One”</H> é um produto com conteúdo e tecnologias que tem por objetivo simplificar a vida do investidor ou interessado, de forma personalizável e de acordo com o perfil do <H>“Assinante”</H>.</p>

        <p>1.1.1. É certo que, para assinar o Valor One, o Assinante deverá: (i) ter 18 (dezoito) anos completos, no mínimo, e ser plenamente capaz; (ii) ser portador de CPF válido; (iii) ser residente e domiciliado no Brasil (“Território”); (iv) ter acesso à Internet e arcar com todos os custos relacionados; (v) ter realizado o devido cadastro para acesso, na forma do item 4.2. e subitens; (vi) ter optado por uma forma de pagamento válida, aceitável e emitida no Território.</p>

        <p>1.1.2. Ao assinar o Valor One, o Assinante passa a ter acesso a um conjunto de informações, incluindo, mas não se limitando, ao conteúdo técnico, político, econômico, financeiro e legal, bem como acesso a ferramentas para auxílio de análises e projeções econômicas (<H>“Conteúdo”</H>). </p>

        <p>1.2. O Valor One é de propriedade exclusiva da Editora Globo S/A., com sede na Rua Marquês de Pombal, nº 25, Cento, Rio de Janeiro/RJ, CEP 20230-240 e inscrita no CNPJ sob n.º 04.067.191/0001-60 (<H>“Editora Globo”</H>), sendo ineficaz qualquer disposição ou entendimento contrário, não devendo, ainda, qualquer cláusula deste <H>“Termo”</H> ser interpretada como transferência ou cessão de direito de propriedade e/ou transferência de tecnologia. </p>

        <p>1.2.1. O Valor One é protegido pela legislação de direitos autorais e tratados internacionais pertinentes, sendo todos os direitos de propriedade intelectual pertencentes à Editora Globo, ou a terceiros, parceiros comerciais e fornecedores, conforme aplicável. Assim, permanecem expressamente reservados todos e quaisquer direitos não conferidos por este Termo. </p>

        <p>1.3. O uso do Valor One está expressamente condicionado à aceitação deste Termo, das políticas da Editora Globo abaixo mencionadas, bem do <H>“Termo de Uso do Valor One”</H>, sempre por uma pessoa civil capaz. Portanto, ao utilizá-lo, o Assinante os aceita, sem reservas ou ressalvas, por meio de aceite eletrônico dado em quadro específico para tanto. </p>

        <p>1.3.1. Conforme acima, ao utilizar os Serviços, o Assinante confirma que concorda com: (i) a Política de Privacidade disponível no link <a className="text-primary" href="https://privacidade.globo.com/privacy-policy/">https://privacidade.globo.com/privacy-policy/</a> e autoriza que seus dados sejam utilizados de acordo com tal política; (ii) a Política de Comentários disponível em <a className="text-primary" href="https://ajuda.globo/globoplay/termos-e-politicas/faq/politica-de-comentarios.ghtml">https://ajuda.globo/globoplay/termos-e-politicas/faq/politica-de-comentarios.ghtml</a>; e (iii) este Termo; em conjunto denominadas <H>“Políticas”</H>.</p>

        <p>1.3.2. Ao utilizar o Valor One, o Assinante concorda que seus dados pessoais sejam utilizados e tratados de acordo com os termos e condições estabelecidos nas Políticas acima mencionadas. Por isso, é importante a leitura atenta. Em caso de discordância, é facultado ao Assinante cessar o uso do Valor One, não dando continuidade à assinatura. </p>

        <h2 className="font-bold text-lg">2. VIGÊNCIA E RESCISÃO</h2>

        <p>2.1. O presente Termo vigorará por período indeterminado a partir do dia de aceite deste Termo. </p>

        <p>2.2. Qualquer das Partes poderá rescindir o presente Termo, imotivadamente e sem ônus, mediante notificação por escrito, com antecedência mínima de 30 (trinta) dias da data do encerramento pretendido (<H>“Aviso Prévio”</H>). </p>

        <p>2.3. O Termo poderá ser rescindido imediatamente, e sem ônus, por qualquer uma das Partes, se: (a) a Parte infratora, depois de notificada, não sanar o inadimplemento em até 15 (quinze) dias do recebimento da notificação; (b) se qualquer das Partes tiver declarada a sua falência, insolvência ou recuperação judicial; (c) na ocorrência de casos fortuitos ou força maior que impossibilitem a sua execução.</p>

        <h2 className="font-bold text-lg">3. VALORES</h2>

        <p>3.1. Em razão da assinatura contratada, o Assinante pagará à Editora Globo os valores brutos discriminados no momento do aceite deste Termo, em moeda corrente nacional, na forma e prazos ali estabelecidos, contra apresentação da respectiva nota fiscal (NFS-e) e/ou boleto bancário. </p>

        <p>3.2. Na hipótese de atraso do pagamento, incidirão sobre o valor devido a correção pelo IPCA/IBGE e juros moratórios de 6,6% (seis virgula seis por cento) ao mês, calculados pro rata die, desde a data de vencimento até seu efetivo pagamento. Sobre o valor total, incidirá, ainda, multa moratória de 2% (dois por cento).</p>

        <p>3.2.1. Caso o atraso no pagamento seja superior a 30 (trinta) dias do vencimento, poderá a Editora Globo, a seu exclusivo critério, (i) suspender a assinatura, cortando o acesso ao Conteúdo; e/ou (ii) dar como rescindido o Termo, sem prejuízo do pagamento dos valores em aberto e da aplicação da multa prevista na Cláusula Nona, podendo levar à adoção das medidas pertinentes. Em caso de suspensão, o restabelecimento da assinatura ocorrerá até 3 (três) dias úteis após a comprovação da quitação integral do débito.</p>

        <p>3.3. A remuneração estabelecida será corrigida anualmente, sempre na mesma data, a contar do início da vigência deste Termo, ou no menor período permitido pela legislação em vigor, pela variação do IPCA/IBGE positiva ou, por outro índice que venha a substituí-lo caso este seja extinto ou negativo. </p>

        <p>3.4. Eventuais alterações nos preços dos serviços, conteúdos ou produtos, inclusive em razão do disposto no item 3.3., serão comunicadas pela Editora Globo e ocorrerão a qualquer tempo. Neste caso, o Assinante optará pela manutenção ou cancelamento da assinatura contratada antes da alteração dos valores.</p>

        <p>3.5. Todo e qualquer tributo que incida ou venha a incidir em razão deste Termo, será de responsabilidade do respectivo contribuinte tributário, definido na legislação vigente à época.</p>

        <h2 className="font-bold text-lg">4. ASSINATURA E MONITORAMENTO DO ASSINANTE</h2>

        <p>4.1. A Editora Globo, a qualquer tempo, poderá, unilateralmente e a seu exclusivo critério, modificar quaisquer cláusulas desse Termo, bem como alterar as características, apresentação ou qualquer aspecto do Valor One, acrescentando ou suprimindo informações. Assim, o Assinante é responsável por acompanhar regularmente o presente Termo e suas modificações, uma vez disponibilizados pela Editora Globo. </p>

        <p>4.2. Para assinar o Valor One será obrigatório o cadastramento e criação de <H>“Conta”</H> pelo Assinante, podendo a Editora Globo, sempre que entender necessário, solicitar informações adicionais. Ainda, será facultado ao Assinante a inclusão de informações adicionais, de modo a tornar seu perfil ainda mais completo.</p>

        <p>4.2.1. O Assinante declara e garante que fornecerá informações verdadeiras no momento de seu cadastro e durante o uso do Valor One e se responsabiliza, para todos os fins de direito e sob as penas previstas neste Termo e na lei, de que tais informações são de fato verdadeiras e precisas.</p>

        <p>4.2.2. Caso o Assinante opte pela exclusão de sua conta, poderá ser feita via próprio site do Valor One, conforme passo a passo lá indicado, sendo condicionada à cessação do acesso à assinatura contratada.</p>

        <p>4.2.3. Os dados imputados pelo Assinante serão processados pela Editora Globo e/ou parceiros autorizados, para os fins de utilização do Valor One pelo Assinante, sendo reservado à Editora Globo o direito de transmiti-los aos fornecedores e parceiros, exclusivamente na medida do necessário para operalização dos serviços, incluindo, mas não se limitando, à utilização para personalização do uso do Valor One de acordo com os interesses e comportamentos do Assinante. </p>

        <p>4.3. Para acesso ao Valor One, cada login do Assinante exigirá uma senha exclusiva, individual e intransferível cadastrada pelo próprio Assinante. </p>

        <p>4.3.1. Para não ter cancelado seu acesso, o Assinante deverá manter a senha fornecida em absoluto sigilo, diligenciando-se em todas as cautelas necessárias, de forma a não permitir o acesso por terceiros. </p>

        <p>4.3.2. É certo que o Assinante é responsável pela manutenção do sigilo dos dados de sua Conta, responsabilizando-se por toda atividade realizada e por manter seus dados atualizados. É expressamente vedada qualquer utilização da Conta por terceiros, ou seja, por pessoas diversas ao titular dos dados imputados no cadastro. </p>

        <p>4.3.3. O Assinante deverá notificar a Editora Globo, imediatamente, caso verifique qualquer uso não autorizado ou suspeito de sua conta de acesso, ou qualquer suspeita de violação da segurança, bem como comunicar de imediato a perda, o roubo ou a divulgação não autorizada de sua senha, sob pena de aplicação das penalidades previstas neste instrumento, incluindo, mas não se limitando, à descrita na Cláusula Nona. </p>

        <p>4.4. O Assinante é responsável por operar o seu próprio equipamento e deverá ter familiaridade com as informações (por exemplo, cálculos e relatórios), integrantes do Conteúdo. </p>

        <h2 className="font-bold text-lg">5. DIREITOS E RESTRIÇÕES DE USO </h2>

        <p>5.1. O Assinante reconhece e autoriza que a Editora Globo, em conjunto com seus parceiros, se reserve ao direito de acompanhar toda e qualquer informação transmitida, recebida ou imputada pelo Assinante no Valor One. A Editora Globo, em conjunto com seus parceiros, terá direito de colher dados de utilização do Valor One de forma automática, sendo utilizados para fins de melhoria continua do Valor One e personalização do uso de acordo com os interesses e comportamentos do Assinante. </p>

        <p>5.1.1. Neste ato, por meio de opt-in, o Assinante autorizará, ou não, o Valor One a acessar seus dados financeiros. Caso autorize, o Valor One acessará os dados financeiros do Assinante, a partir do CPF por ele informado, através de API disponibilizada pela Bolsa de Valores Brasileira (<H>“B3”</H>), que mantém o histórico de movimentações e posições de ativos na(s) conta(s) das corretoras nas quais o Assinante opera. É certo que a referida autorização poderá ser revogada a qualquer tempo, por meio da própria plataforma Valor One.</p>

        <p>5.1.2. Ao seu exclusivo critério e sem aviso prévio ao Assinante, a Editora Globo poderá rever, averiguar ou suspender a transmissão ou recebimento de quaisquer informações que considerar inverídicas, inadequadas, ou que entenda violarem o presente Termo. </p>

        <p>5.2. É vedada qualquer espécie de procedimento pelo Assinante que incorra em: </p>

        <p>(a) aluguel, arrendamento, empréstimo, seja total ou parcial do Conteúdo;</p>

        <p>(b) distribuição ou redistribuição do Conteúdo, integral ou parcial, direta ou indiretamente, sob qualquer meio ou forma, incluindo, mas não se limitando, através de print screen e foto, a quaisquer pessoas, físicas ou jurídicas, internas e externas; </p>

        <p>(c) reprodução, compartilhamento, veiculação, ou divulgação do Conteúdo ou parte dele; </p>

        <p>(d) comercialização, cessão ou licenciamento do Conteúdo; </p>

        <p>(e) armazenamento do Conteúdo; </p>

        <p>(f) utilização do Conteúdo como fonte para elaboração de clipping, interno ou externo, de notícias ou meios informativos correlatos e materiais de divulgação das informações técnicas e especializas; </p>

        <p>(g) remoção, utilização ou modificação de quaisquer marcas de produtos ou serviços contidos no Conteúdo de propriedade da Editora Globo ou de seus parceiros comerciais ou provedores das informações;  </p>

        <p>(h) elaboração, criação, cálculo ou geração de qualquer modalidade de índice, bem como de instrumentos financeiros, valores mobiliários e indicativos similares, em benefício próprio ou de terceiros; </p>

        <p>(i) Burlar qualquer tecnologia utilizada pela Editoria Globo para proteger o conteúdo do Valor One; e</p>

        <p>(j) qualquer outra utilização não definida expressamente neste Termo. </p>

        <p>5.3. Qualquer infração por parte do Assinante ao definido neste Termo ensejará a possibilidade de a Editora Globo cancelar o acesso ao Valor One, conforme o caso, bem como solicitar o ressarcimento por eventuais perdas e danos que venham a ser apurados, tais como danos emergentes, lucros cessantes e demais prejuízos. </p>

        <h2 className="font-bold text-lg">6. RESPONSABILIDADES</h2>

        <p>6.1. O Assinante terá acesso ao Valor One tal como é oferecido, eximindo a Editora Globo e seus parceiros de quaisquer outras garantias não previstas neste Termo, inclusive eximindo de qualquer garantia implícita que possa se adequar a um determinado propósito, ou que decorra dos usos e costumes comerciais. </p>

        <p>6.2. A Editora Globo envidará seus melhores esforços para corrigir eventuais erros constantes no acesso ao Conteúdo, bem como garante que atuará sempre de maneira profissional e condizente com os mais elevados padrões de mercado.</p>

        <p>6.3. O Assinante está ciente, exonerando a Editora Globo e parceiros de qualquer responsabilidade, de que estes não garantem: (a) que o Conteúdo atenderá ou se adequará às necessidades exclusivas do Assinante; (b) que o acesso e o uso do Valor One serão totalmente livres de falhas e/ou atrasos; (c) a precisão ou veracidade de quaisquer dados ou informações contidas ou processadas, ou o resultado de qualquer processo. </p>

        <p>6.4. O Assinante desde já reconhece que o acesso poderá sofrer interrupção em razão de problemas de qualquer natureza, incluindo, mas não se limitando, de natureza operacional, infraestrutura tecnológica ou financeira, não sendo a Editora Globo e seus parceiros responsáveis, por quaisquer prejuízos e/ou danos decorrentes da interrupção do acesso que não sejam ocasionados por culpa exclusiva e/ou dolo da Editora Globo e/ou terceiros, desde que comprovados mediante decisão transitada em julgado.</p>

        <p>6.5. O Assinante é o único responsável: (i) por suas decisões e por conduzir a análise sobre investimentos; (ii) pelas decisões relativas à assunção de risco e à expectativa de retorno ao optar em realizar operações financeiras utilizando-se de dados, informações ou resultados constantes do Conteúdo; bem como (iii) por confirmar a exatidão de dados, informações e resultados de Conteúdos. Desta forma, sob nenhuma circunstância a Editora Globo e seus parceiros, poderão ser responsabilizados por qualquer decisão feita pelo Assinante com base no Conteúdo, principalmente dada sua função meramente informativa. </p>

        <p>6.5.1. O Conteúdo é fornecido pela Editora Globo para utilização pessoal do Assinante e <H>não constitui aconselhamento</H> (de investimento, financeiro, fiscal ou jurídico), tampouco provê recomendações relativas a instrumentos financeiros específicos, investimentos ou produtos. </p>

        <p>6.5.2. A Editora Globo não se responsabiliza pelo conteúdo proveniente de parceiros e/ou terceiros ou por qualquer link que redirecione a informação divulgada, bem como por quaisquer alterações, exclusões ou atualizações da informação contida em tais sites, não constituindo seu endosso à opinião de terceiros a mera disponibilização no Valor One. </p>

        <p>6.5.3. É certo que as análises, opiniões, estimativas e projeções feitas no ambiente do Valor One são baseadas em julgamentos de consultores e analistas responsáveis e estão, portanto, sujeitas às modificações em decorrência de alterações nas condições de mercado, mutáveis por natureza. Ainda, as opiniões contidas no espaço podem não ser aplicáveis para todos os Assinantes devido aos diferentes objetivos de investimento. Desta forma, a Editora Globo não se responsabiliza por prejuízos de qualquer natureza em decorrência do uso destas informações. Toda e qualquer decisão de investimento baseada nas opiniões expostas é de exclusiva responsabilidade do Assinante.</p>

        <p>6.6. O Assinante concorda que a Editora Globo e/ou seus fornecedores, e também seus respectivos representantes, diretores, gerentes, sócios, parceiros, empregados, agentes, representantes e subcontratados, em nenhuma hipótese, serão responsabilizados por eventuais perdas, danos morais, materiais, emergentes, fortuitos, diretos e/ou indiretos, lucros cessantes, custo ou despesas sofridas ou incorridas ao Assinante no âmbito do uso, ou impossibilidade de uso, do Valor One e de seu Conteúdo, para qualquer fim, mesmo que a Editora Globo e/ou seus parceiros tenham sido avisados da possibilidade de tais danos. </p>

        <p>6.7. O Assinante concorda que a Editora Globo envidará todos os esforços possíveis para garantir que nenhum vírus, malware, ransomware ou programas com funções similares, operem ou sejam transferidos por intermédio do Valor One, no entanto, neste ato o Assinante assume toda a responsabilidade de detectar e erradicar quaisquer vírus, malware, ransomware ou programas com funções similares, em seu equipamento de acesso, por qualquer meio que julgar mais adequado às suas necessidades, isentando a Editora Globo de tal incumbência. </p>

        <p>6.8. Qualquer atraso ou falha no desempenho do Valor One, bem como no desempenho de seus prestadores de serviços ou patrocinadores de serviços (incluindo os respectivos prepostos, diretores, funcionários, afiliados, agentes, representantes e subcontratados) não ensejará qualquer pedido de indenização, perda ou responsabilidade, se causada por interrupções devido às falhas de equipamentos eletrônicos ou mecânicos, problemas, defeitos ou ocorrências fora do controle da Editora Globo e terceiros, incluindo, mas não limitado a, caso fortuito, força maior, atos de autoridade governamental, perturbações no clima, incêndios, inundações, explosões, terremotos, pandemias, greves ou outros atos coletivos de trabalhadores, tumultos, conflitos armados, atos de guerra ou sabotagem. </p>

        <p>6.8.1. A Editora Globo não terá qualquer responsabilidade no fornecimento ao acesso ao Valor One enquanto a causa de interrupção não cessar. </p>

        <p>6.9. A isenção de responsabilidade da Editora Globo e de seus fornecedores parceiros constitui fator determinante para o acesso ao Conteúdo, bem como, para a fixação do valor cobrado mensalmente, devendo a alocação de risco aqui definida ser respeitada nos termos do art. 421-A, II, do Código Civil Brasileiro. </p>

        <p>6.10. As disposições contidas nesta seção do Termo permanecerão válidas mesmo após a sua rescisão. </p>

        <h2 className="font-bold text-lg">7. CONFIDENCIALIDADE</h2>

        <p>7.1. Todos os direitos de privacidade são protegidos pela Editora Globo dentro dos ditames da lei e na forma estabelecida por este Termo. </p>

        <p>7.2. O Assinante declara que, no curso do uso do Valor One, poderá receber informações confidenciais – entendendo-se por <H>“Informação Confidencial”</H> toda e qualquer informação ou material de propriedade da Editora Globo ou de terceiros parceiros – declarando expressa ciência de que a revelação acarretará prejuízos irreparáveis e, em decorrência disto, a Editora Globo poderá acionar o Poder Judiciário para garantir a execução do ora estabelecido, sendo que, neste caso, terá direito ao reembolso das despesas em que incorrer tais como, mas não limitadas, às custas processuais e honorários advocatícios. </p>

        <h2 className="font-bold text-lg">8. BLOGS</h2>

        <p>8.1. O Valor One poderá conter <H>“Blogs”</H> e demais ferramentas de interação com os Assinantes. O conteúdo dos comentários publicados nos Blogs é de única e exclusiva responsabilidade civil e penal do Assinante. A Editora Globo não se responsabiliza por qualquer dano supostamente decorrente das opiniões e comentários efetuados por terceiros. </p>

        <p>8.2. Os comentários publicados serão pós-moderados e a Editora Globo poderá, a qualquer tempo e a seu critério, excluir, parcial ou integralmente, qualquer texto que infrinja suas normas internas, legais ou as regras deste Termo, ou ainda em decorrência de denúncias, sem que isto gere qualquer responsabilidade para a Editora Globo ou direito ao Assinante. </p>

        <p>8.3. Os comentários eventualmente incluídos pelo Assinante não devem conter mensagens publicitárias, material pornográfico, grosseiro, racista ou ofensivo; informação sobre atividades ilegais e incitação ao crime; material calunioso; e/ou afirmações injuriosas ou difamatórias. </p>

        <p>8.4. É proibido publicar qualquer material protegido por direitos autorais sem a devida autorização do titular ou seu representante. </p>

        <p>8.5. Se por mais de uma vez forem descumpridas quaisquer das regras expostas ou for verificado o exercício de ilegalidades, o Assinante terá sua permissão de comentários cancelada. </p>

        <p>8.6. A qualquer tempo e de maneira unilateral, a Editora Globo poderá cancelar o sistema de comentários sem necessidade de aviso prévio.</p>

        <p>8.7. É possível que o Assinante denuncie o conteúdo dos comentários publicados nos Blogs caso identifique qualquer desacordo em relação a este termo. Para efetivação da denúncia, o Assinante deverá optar por um dos canais disponibilizados publicamente no site do Valor One.</p>

        <h2 className="font-bold text-lg">9. PENALIDADES</h2>

        <p>9.1. A infração de qualquer das cláusulas deste Termo pelo Assinante, a critério da Editora Globo, poderá ensejar a aplicação de multa penal, e não compensatória, no valor correspondente a 25% (vinte e cinco por cento) do valor anual da assinatura do Valor One, indenização pelas perdas, danos decorrentes e inadimplência, bem como ao pagamento dos honorários advocatícios, custas e demais despesas judiciais e extrajudiciais da Parte prejudicada. </p>

        <h2 className="font-bold text-lg">10. DISPOSIÇÕES GERAIS</h2>

        <p>10.1. A inaplicabilidade de quaisquer cláusulas e condições dispostas nos termos mencionados no item 1.3. não resultará na nulidade das demais cláusulas que continuarão em pleno vigor e eficácia. </p>

        <p>10.2. A não exigência pela Editora Globo no cumprimento imediato de quaisquer cláusulas ou condições deste Termo constitui-se mera liberalidade, podendo a qualquer tempo, fazer com que o Assinante inadimplente cumpra rigorosamente todas as condições estabelecidas. </p>

        <p>10.2.1. A tolerância por parte da Editora Globo quanto a qualquer infração em relação aos dispositivos deste Termo, não significará que tenha liberado o Assinante das obrigações assumidas e nem tampouco que o dispositivo infringido tenha sido revogado. </p>

        <p>10.3. Caso o Assinante deseje entrar em contato com a Editora Globo para quaisquer dúvidas, sugestões, reclamações, alteração de cadastro e outros, deverá optar por um dos canais disponibilizados publicamente no site do Valor One. </p>

        <p>10.3.1. Caso a Editora Globo necessite entrar em contato com o Assinante, o fará por meio dos dados de contato informados no momento do cadastro da Conta pelo Assinante.</p>

        <p>10.4. As Partes declaram conhecer as normas de prevenção à corrupção previstas na legislação brasileira, dentre elas o Código Penal Brasileiro, a Lei de Improbidade Administrativa (Lei nº 8.429/1992) e a Lei nº 12.846/2013 e seus regulamentos (em conjunto, “Leis Anticorrupção”) e se comprometem a cumpri-las fielmente, por si e por seus sócios, administradores e colaboradores, bem como exigir o seu cumprimento pelos terceiros por ela contratados. No exercício dos direitos e obrigações previstos neste Termo e no cumprimento de qualquer uma de suas disposições, o Assinante se obriga a cumprir as disposições do documento denominado “Regras para Terceiros na Relação com o Grupo Globo”, disponíveis no endereço eletrônico www.ouvidoriagrupoglobo.com.br (“Regras para Terceiros”), sendo a violação de qualquer das disposições das Regras para Terceiros causa da rescisão unilateral deste Termo, sem prejuízo da cobrança das perdas e danos causados à Editora Globo. O Assinante declara que nos últimos 5 (cinco) anos não sofreu nenhuma investigação, inquérito ou processo administrativo ou judicial relacionados ao descumprimento das Leis Anticorrupção ou de lavagem de dinheiro e que suas atividades estão em conformidade com as Leis Anticorrupção. </p>

        <p>10.5. Este Termo será regido e interpretado de acordo com a legislação brasileira e o Assinante concorda em submeter- se à jurisdição do Foro Central da Comarca da Capital do Estado do Rio de Janeiro com relação a eventuais processos judiciais que possam surgir por decorrência do uso do Valor One ou de disputa quanto à interpretação ou descumprimento deste Termo. </p>

        <p>10.6. Não é permitido que o conteúdo produzido pela Editora Globo ("Conteúdo"), inclusive textos e/ou qualquer acervo digital da Editora Globo, tal como imagens, áudio, vídeo clips, "look and feel" e metadata, seja utilizado para (i) desenvolvimento de qualquer tipo de software, inclusive sistema de inteligência artificial generativa; (ii) treinamento de aprendizado automático (machine learning) e/ou de sistema de inteligência artificial generativa; e (iii) reprodução do Conteúdo ou geração de conteúdo a partir do Conteúdo por sistema de inteligência artificial para disponibilização em qualquer meio.</p>
      </div>
    </section>
  )
}