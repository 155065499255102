import { BASE_URL, getApiHeaders, customFetch } from "./settings"

const URL_COURSE = 'Course'

const Api = {

  fetchAlpaclassIframe: async function (body) {
    return customFetch(`${BASE_URL}/${URL_COURSE}/loginsso`, { 
      method: 'GET',
      headers: getApiHeaders()
    })
    .then((response) => {
      if (response.status === 200) return response.json()
        return []
    })
  },
}

export default Api