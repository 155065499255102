import { useEffect, useState } from 'react'
import useWindowSize from './useWindowSize'
import { LAYOUT_BREAKPOINT_WIDTH_MOBILE } from 'const'

const useMobile = () => {
  const [isMobile, setIsMobile] = useState(null)
  const { width, height } = useWindowSize()
  const { orientation } = window.screen
  const widthMobile = width < LAYOUT_BREAKPOINT_WIDTH_MOBILE

  useEffect(() => {
    setIsMobile((widthMobile && orientation.angle === 0) || (orientation.angle === 90 && height < 450))
  }, [widthMobile, orientation, height])

  return {isMobile, widthMobile}
}

export default useMobile