import { NewsContext } from "context/NewsContext"
import { useContext, useEffect, useMemo, useState } from "react"
import Modal from "react-modal"
import { MdClose, MdSync } from "react-icons/md"
import Tabs from "shared/Tabs"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import { Checkbox } from "shared/Checkbox"
import TagsInput from "shared/TagsInput"
import { CompanySelectorAutocomplete } from "shared/CompanyAutocomplete"
import { ControlledToggle } from "shared/ToggleButton"
import ComDinheiroApi from 'api/ComDinheiroApi'
import Loading from "shared/Loading"
import NewsApi from "api/NewsApi"
import useWindowSize from "hooks/useWindowSize"
import { LAYOUT_BREAKPOINT_WIDTH } from "const"
import { toast } from "react-toastify"
import { uniq, uniqBy } from "lodash"

export function ModalFilterNews() {

  const filterTabs = useMemo(() => [
    { name: "Tema", Component: TabTopicFilter },
    { name: "Empresas", Component: TabCompanyFilter },
    { name: "Termos", Component: TabKeywordFilter }
  ], [])

  const methods = useForm()
  const { filterValue, updateFilters, matchTopicFilterWalletPosition, isFilterModalOpen, setIsFilterModalOpen } = useContext(NewsContext)
  const [walletPosition, setWalletPosition] = useState(undefined)
  const { width } = useWindowSize()
  const isMobile = width < LAYOUT_BREAKPOINT_WIDTH
  const isWalletSyncActive = methods.watch('isWalletSyncActive')
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const activeTab = useMemo(() => filterTabs[activeTabIndex] || null, [activeTabIndex, filterTabs])

  const closeModal = () => setIsFilterModalOpen(false)

  const onSubmit = (val) => {
    val.companies = [...val.companies, ...(val.walletPosition?.companies || [])]
    updateFilters(val)
    closeModal()
    localStorage.setItem('news_alert_dismissed', true)
    setActiveTabIndex(0)
  }

  useEffect(() => {
    if (isWalletSyncActive) {
      setWalletPosition(undefined)
      ComDinheiroApi.getWalletPosition().then(res => {
        const walletTopics = matchTopicFilterWalletPosition(res.assets)
        setWalletPosition({ walletTopics, ...res })
        methods.setValue('categories', uniq([...(methods.getValues().categories || []), ...filterValue?.categories, ...walletTopics]))
      }).catch(err => {
        console.error(err)
        toast.error('Não foi possível sincronizar a carteira.')
        setWalletPosition(null)
      })
    } else {
      // Remove sync from companies and keep them into the list
      const syncCompanies = (walletPosition?.companies || []).map(x => ({ ...x, sync: undefined }))
      methods.setValue('companies', uniq([...syncCompanies, ...(methods.getValues().companies || [])]))
      setWalletPosition(null)
    }
  }, [isWalletSyncActive])

  useEffect(() => {
    // All Checked
    const categories = filterValue?.categories
    const keyword = filterValue?.keyword || []
    const companies = filterValue?.companies || []
    let _isWalletSyncActive
    if (isWalletSyncActive === undefined) {
      _isWalletSyncActive = filterValue?.isWalletSyncActive
      methods.setValue('isWalletSyncActive', filterValue?.isWalletSyncActive)
    } else {
      _isWalletSyncActive = isWalletSyncActive
    }
    methods.reset({ categories, companies, keyword, isWalletSyncActive: _isWalletSyncActive })

  }, [methods, filterValue, isFilterModalOpen])

  return (
    <Modal isOpen={isFilterModalOpen} onRequestClose={closeModal} style={{
      content:
      {
        maxWidth: isMobile ? width : 648,
        padding: 0,
        margin: "auto",
        marginRight: isMobile ? -40 : "auto",
        marginLeft: isMobile ? -40 : "auto",
      }
    }}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="relative h-full flex flex-col space-y-2 p-4">
          <button className="absolute right-2 top-2 text-gray-500" title="Fechar" type="button" onClick={closeModal}>
            <MdClose />
          </button>

          <h2 className="font-bold text-xl text-primary uppercase">Personalize seu conteúdo</h2>
          <p className="text-gray-600">Acompanhe temas, empresas e produtos do seu interesse</p>

          <div className="font-bold text-primary flex items-center space-x-2">
            <MdSync />
            <span>Sincronizar com: </span>
            {/* <Toggle isActive={isWalletSyncActive} onChange={isActive => setIsWalletSyncActive(isActive)} /> */}
            <ControlledToggle name="isWalletSyncActive" />
            <span>Minha Carteira</span>
            {walletPosition === undefined && <Loading size={30} />}
          </div>
          <div>
            <Tabs tabs={filterTabs} activeTabIndex={activeTabIndex} onChange={({ index }) => setActiveTabIndex(index)} parentClass="justify-between px-4 uppercase my-2 px-0" />
          </div>
          {<activeTab.Component walletPosition={walletPosition} />}
          <div className="flex justify-center space-x-4">
            <button type="button" className="btn btn-secondary" onClick={() => setActiveTabIndex(x => --x)} disabled={activeTabIndex === 0}>Anterior</button>
            { activeTabIndex < (filterTabs.length - 1) && <button type="button" className="btn btn-primary" onClick={() => setActiveTabIndex(x => ++x)}>Próximo</button> }
            { activeTabIndex == (filterTabs.length - 1) && <button type="submit" className="btn btn-primary">Salvar</button> }
          </div>
        </form>
      </FormProvider>
    </Modal>
  )
}

function TabTopicFilter({ walletPosition }) {
  const [categoriesByGroup, setCategoriesByGroup] = useState([])

  useEffect(() => {
    NewsApi.getCategories().then(res => setCategoriesByGroup(res || []))
  }, [setCategoriesByGroup])

  return (
    <>
      <h2 className="text-primary uppercase text-lg font-semibold mb-2">Escolha os temas de seu interesse</h2>
      <div className="h-full flex flex-col overflow-auto mini-scrollbar">
        {
          categoriesByGroup.map(({ categories, group }, i) => (
            <div key={'categorygroup-' + group} className="mb-2">
              <h6 className="text-primary text-sm font-semibold my-1">{group}</h6>
              <div className="grid grid-cols-2 gap-1 gap-y-2">
                {
                  categories.map((category, j) => (
                    (walletPosition?.walletTopics || [])?.includes(category) ? (
                      <label className="checkbox-container block relative pl-6 cursor-pointer text-sm" key={`category-sync-${category}`}>
                        <MdSync className="absolute left-0 top-0 w-6 h-6 text-primary" />
                        <span className="mx-2 text-sm flex items-center">{category}</span>
                      </label>
                    ) : (
                      <Checkbox name="categories" value={category} key={`category-${category}`}>
                        <span className="mx-2 text-sm flex items-center">{category}</span>
                      </Checkbox>
                    )

                  ))
                }
              </div>
            </div>
          ))
        }
      </div>
    </>
  )
}

function TabCompanyFilter({ walletPosition }) {
  const { setValue, getValues } = useFormContext()
  const onChange = val => {
    setValue('companies', val.filter(({ sync }) => !sync))
  }
  return (
    <>
      <h4 className="text-primary font-semibold">Inclua as empresas com ações listadas na B3 que deseja seguir</h4>
      <CompanySelectorAutocomplete companiesList={uniqBy([...(walletPosition?.companies || []), ...getValues().companies], 'name')} placeholder="Digite o nome ou código do ativo" onChange={val => onChange(val)} />
    </>
  )
}

function TabKeywordFilter() {

  const { setValue, getValues } = useFormContext()

  return (
    <>
      <h4 className="text-primary font-semibold">Inclua as palavras-chave que deseja seguir</h4>
      <TagsInput tagList={getValues().keyword} onChange={val => setValue('keyword', val)} />
    </>
  )
}