import { Tooltip } from "react-tooltip"

export function AnalystsTooltip({ id, analyst, qty, ...props }) {
    id = 'tooltip_' + id

    return (
        <>
            <div className={`${analyst.color} cursor-default text-center font-semibold`} style={{ width: analyst.formattedQty }} id={id}>{analyst.qty}</div>
            <SimpleTooltip anchor={`#${id}`} className="bg-primary p-[5px] font-semibold text-sm text-center" {...props} />
        </>
    )
}

export function SimpleTooltip({ anchor, value, children, ...props }) {
    return (
        <Tooltip anchorSelect={anchor} {...props} className={'bg-primary p-[5px] font-semibold text-xs  opacity-100 ' + (props.className || '')}>
            {value || children}
        </Tooltip>
    )
}