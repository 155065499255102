import { useEffect } from "react"

const baseTitle = 'Valor One'

function useTitle(title) {
  useEffect(() => {
    const newTitle = title ? `${title} | ${baseTitle}` : baseTitle
    document.title = newTitle
    const ogTitleTag = document.querySelector('meta[property="og:title"]');
    if (ogTitleTag) {
      ogTitleTag.content = newTitle
    }
  }, [title])
}

export default useTitle