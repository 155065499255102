import { createRef, forwardRef, useContext, useEffect, useMemo, useReducer, useState } from "react"
import WidgetContextualMenu from "shared/WidgetContextualMenu"
import { MdOutlineFilterList, MdFullscreen, MdClose, MdOutlineDiamond, MdOutlineFilterAlt } from "react-icons/md"
import { HiAdjustmentsHorizontal } from "react-icons/hi2"
import { LoadingBox } from "shared/Loading"
import { dateStringToLocaleString } from "helpers/dateHelper"
import Tabs from "shared/Tabs"
import { NewsContext, NewsContextProvider } from "context/NewsContext"
import { ModalFilterNews } from "shared/modal/News/ModalFilterNews"
import useClickout from "hooks/useClickout"
import { toast } from "react-toastify"
import useWindowSize from "hooks/useWindowSize"
import { ICONS_MAP, Keys, LAYOUT_BREAKPOINT_WIDTH, LAYOUT_BREAKPOINT_WIDTH_MD, NEWS_TABS_KEYS, ORIGINS } from "const"
import { useLocalParams } from "hooks/useLocalParams"
import NewsApi from "api/NewsApi"
import Modal from "react-modal"
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import useSymbol from "hooks/useSymbol"
import { BtnToggleMyList } from "shared/MyList"
import Delay from "shared/Delay"
import InfinityScrollContainer from "shared/InfinityScrollContainer"
import { WidgetTitle, WidgetLinkTitle } from "shared/WidgetTitle"
import useTitle from "hooks/useTitle"
import { format } from "helpers/numberHelper"
import DeniedPermissionWidget from "shared/DeniedPermissionWidget"
import { SimpleTooltip } from "shared/HelpTooltip"
import { useCustomId } from "hooks/useCustomId"

export function NewsWidget(props) {
  const id = props.id
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { width } = useWindowSize()
  const widgetSize = width >= LAYOUT_BREAKPOINT_WIDTH ? props.desktop?.w : 1
  const compactView = widgetSize === 1

  const options = useMemo(() => [
    { label: "Modo Compacto", onClick: () => props.resizeItem({ id, desktop: { w: 1 } }) },
    { label: "Modo Expandido", onClick: () => props.resizeItem({ id, desktop: { w: 2 } }) },
  ], [id, props])

  const [videosUrl, setVideosUrl] = useState(null)
  const [podcastsUrl, setPodcastsUrl] = useState(null)

  useEffect(() => {
    NewsApi.getUrl('video').then(({ link }) => setVideosUrl(link))
    NewsApi.getUrl('podcast').then(({ link }) => setPodcastsUrl(link))
  }, [])

  return (
    <NewsContextProvider onSelectNews={() => compactView && setIsModalOpen(true)} widgetProps={props}>
      <div className="bg-white h-full rounded flex flex-col">
        <div className="drag-handle pt-4 px-4">
          <WidgetContextualMenu options={options} removeItem={() => props.removeItem(id)} />
          <WidgetLinkTitle title="Notícias" route="/noticias" />
        </div>
        <DeniedPermissionWidget widgetSize={widgetSize} widgetType="News">
          {compactView ? (
            <>
              <NewsList />
              <ModalShowNews isModalOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} />
            </>
          ) : (
            <div className={`flex-grow overflow-hidden relative grid gap-4 ${widgetSize === 2 ? 'grid-cols-2' : 'grid-cols-3'}`}>
              <NewsList gridClass="col-[2/-1]" />
              <SelectedNews />
              {
                widgetSize === 3 && (
                  <div className="overflow-auto mini-scrollbar">
                    <RelatedNews />
                    {videosUrl && <iframe title="Videos" src={videosUrl} className="w-full" style={{ height: 1900 }} />}
                    {podcastsUrl && <iframe title="Podcasts" src={podcastsUrl} className="max-w-full w-full" style={{ height: 900 }} />}
                    <RelatedSymbols />
                  </div>
                )
              }
            </div>
          )}
        </DeniedPermissionWidget>
      </div>
      <ModalFilterNews />
    </NewsContextProvider>
  )
}

export function NewsPage() {

  useTitle('Notícias')

  const [isModalOpen, setIsModalOpen] = useState(false)
  const { width } = useWindowSize()
  const widgetSize = width >= LAYOUT_BREAKPOINT_WIDTH_MD ? (width >= LAYOUT_BREAKPOINT_WIDTH ? 3 : 2) : 1
  const compactView = widgetSize === 1

  const [videosUrl, setVideosUrl] = useState(null)
  const [podcastsUrl, setPodcastsUrl] = useState(null)

  useEffect(() => {
    NewsApi.getUrl('video').then(({ link }) => setVideosUrl(link))
    NewsApi.getUrl('podcast').then(({ link }) => setPodcastsUrl(link))
  }, [])

  const newsParams = useMemo(() => ({
    tabIndex: 0,
    filters: {
      origin: ORIGINS.reduce((ac, x) => ({ ...ac, [x.key]: true }), {}),
    },
  }), [])
  const props = useLocalParams('NEWS_PAGE', newsParams)

  return (
    <NewsContextProvider onSelectNews={() => compactView && setIsModalOpen(true)} widgetProps={props}>
      <div className="bg-white h-full rounded flex flex-col">
        <div className="pt-4 px-4">
          <WidgetTitle title="Notícias" />
        </div>
        {compactView ? (
          <>
            <NewsList />
            <ModalShowNews isModalOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} />
          </>
        ) : (
          <div className={`flex-grow overflow-hidden relative grid gap-4 ${widgetSize === 2 ? 'grid-cols-2' : 'grid-cols-3'}`}>
            <NewsList gridClass="col-[2/-1]" />
            <SelectedNews />
            {
              widgetSize === 3 && (
                <div className="overflow-auto mini-scrollbar">
                  <RelatedNews />
                  {videosUrl && <iframe title="Videos" src={videosUrl} className="w-full" style={{ height: 1900 }} />}
                  {podcastsUrl && <iframe title="Podcasts" src={podcastsUrl} className="max-w-full w-full" style={{ height: 900 }} />}
                  <RelatedSymbols />
                </div>
              )
            }
          </div>
        )}
      </div>
      <ModalFilterNews />
    </NewsContextProvider>
  )
}

function NewsList() {
  const { newsList, selectedNews, tabs, tabIndex, updateTabIndex, selectNews, loadMore, isFiltersValueClean } = useContext(NewsContext)
  const [search, setSearch] = useState('')
  const filteredNews = (newsList || []).filter(news => search ? news.title.toLowerCase().includes(search.toLowerCase()) : true)
  const [showAlert, setShowAlert] = useState()

  useEffect(() => {
    const newsAlertDismissed = localStorage.getItem('news_alert_dismissed')
    const isMyNewsTabActive = tabs[tabIndex].key === NEWS_TABS_KEYS.MyNews
    if (isFiltersValueClean && !newsAlertDismissed && isMyNewsTabActive) {
      setShowAlert(true)
    } else {
      setShowAlert(false)
    }
  }, [tabs, tabIndex, showAlert, setShowAlert, isFiltersValueClean])

  const dismissAlert = () => {
    localStorage.setItem('news_alert_dismissed', true)
    setShowAlert(false)
  }

  return (
    <>
      <div className="space-y-3 flex-col h-full flex pb-4">
        <Tabs defaultTab={tabs.findIndex(item => item.isActive)} tabs={tabs} onChange={({ tab, index }) => updateTabIndex(index)} activeTabIndex={tabIndex} parentClass={`space-x-4 px-3`} />
        <div className="flex items-center space-x-3 px-3">
          <OriginTriggerButton />
          {/* <div className="relative">
            <button className="text-lg text-primary rounded-full" onClick={() => setDropdownOpen(true)} ref={dropdownTriggerRef} title="Filtrar pela fonte da notícia">
              <MdOutlineFilterList />
            </button>
            {isDropdownOpen && <OriginDropdown ref={dropdownRef} close={() => setDropdownOpen(false)} />}
          </div> */}
          <div className="relative flex items-center flex-grow">
            <input value={search} onChange={e => setSearch(e.target.value)} placeholder="Filtrar no título" className="rounded-lg bg-gray-200 p-2 text-sm w-full text-gray-600" type="search" />
            {!search && <MdOutlineFilterAlt className="absolute right-2 text-gray-500" />}
          </div>
          {
            tabIndex === 1 && <CustomFiltersTriggerButton />
          }
        </div>

        {showAlert && (
          <div className="border bg-gray-200 rounded px-2 py-4 flex space-x-2 relative mx-4 mb-4">
            <button title="Fechar alerta" className="absolute right-2 top-2 opacity-50 hover:opacity-100 z-50 text-primary" onClick={() => dismissAlert()}>
              <MdClose />
            </button>
            <div className="flex space-x-2">
              <div className="text-xl">
                <HiAdjustmentsHorizontal className="text-primary" />
              </div>
              <p className="text-sm pr-4">Você pode personalizar seu conteúdo a qualquer momento clicando no ícone disponível acima.</p>
            </div>
          </div>
        )}

        <InfinityScrollContainer className="flex-grow overflow-auto h-1 mini-scrollbar" loadMore={loadMore}>
          {!newsList && <LoadingBox />}
          {newsList && !filteredNews?.length && <p className="p-4 text-gray-500 text-sm">Nenhuma notícia encontrada, tente revisar os filtros.</p>}
          {newsList && filteredNews.length > 0 && filteredNews.map((news, index) => (
            <div key={`news-${index}`} className={`cursor-pointer hover:bg-[#F6F6F6] flex space-x-3 p-2 items-center ${selectedNews?.link === news.link && "bg-[#EDEDED]"}`} onClick={() => selectNews(news)}>
              <img src={ICONS_MAP.get(news.origin)} className={`rounded-full w-5 h-5`} alt={news.origin} />
              <div className="space-y-1">
                <div className={`text-sm font-semibold leading-tight ${selectedNews?.link !== news.link && news.wasRead && 'text-[#AAAAAA]'}`}>
                  {news.title}
                </div>
                <p className="text-xs text-gray-500 font-semibold">
                  {dateStringToLocaleString(news.publicationDate)}
                </p>
              </div>
            </div>
          ))}
        </InfinityScrollContainer>
      </div>
      {/* <ModalFilterNews isModalOpen={isFilterModalOpen} closeModal={() => setIsFilterModalOpen(false)} /> */}
    </>
  )
}

const OriginTriggerButton = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const dropdownRef = createRef()
  const dropdownTriggerRef = createRef()

  useClickout([dropdownTriggerRef, dropdownRef], clickout => {
    if (clickout) setDropdownOpen(false)
  })
  const buttonId = useCustomId('OriginTriggerButton')

  return (
    <div className="relative z-10">
      <button className="text-lg text-primary w-8 h-8 flex items-center justify-center rounded-full hover:bg-light-gray" id={buttonId} onClick={() => setDropdownOpen(true)} ref={dropdownTriggerRef}>
        <MdOutlineFilterList />
      </button>

      {isDropdownOpen && <OriginDropdown ref={dropdownRef} place="right" close={() => setDropdownOpen(false)} />}
      <SimpleTooltip place="top-right" anchor={'#' + buttonId} value="Escolha a origem da notícia" />
    </div>
  )
}

const CustomFiltersTriggerButton = () => {
  const { setIsFilterModalOpen } = useContext(NewsContext)
  const buttonId = useCustomId('CustomFiltersTriggerButton')

  return (
    <div className="relative">
      <button className="text-lg text-primary w-8 h-8 flex items-center justify-center rounded-full hover:bg-light-gray" id={buttonId} onClick={() => setIsFilterModalOpen(true)}>
        <HiAdjustmentsHorizontal />
      </button>
      <SimpleTooltip place="top-left" anchor={'#' + buttonId} value="Personalize o seu conteúdo" />
    </div>
  )
}

const OriginDropdown = forwardRef(({ close }, ref) => {
  const { filterValue, updateFilters, origins } = useContext(NewsContext)
  const [filtersState, dispatchUpdate] = useReducer((state, action) => {
    const newState = { ...state, ...action.payload }
    if (JSON.stringify(filterValue.origin) === JSON.stringify(newState))
      return newState
    if (Object.values(newState).every(x => !x)) {
      toast.warning('É obrigatório passar ao menos uma origem!')
      return state
    }
    return newState
  }, filterValue.origin)

  return (
    <div ref={ref} className="fixed bg-white shadow mt-2 left-2 rounded-sm text-danger border w-30" style={{ zIndex: 10000 }}>
      {origins.map(orig => (
        <div className="p-2" key={'news_filter_' + orig.label}>
          <label className="flex items-center space-x-2 cursor-pointer">
            <input disabled={!orig.isActive} type="checkbox" className="accent-primary cursor-pointer" onChange={() => dispatchUpdate({ payload: { [orig.key]: !filtersState[orig.key] } })} checked={filtersState[orig.key]} />
            <span className={`font-semibold ${orig.isActive ? 'text-gray-600' : 'text-gray-300'} text-sm`}>{orig.label}</span>
            {!orig.isActive && <MdOutlineDiamond className="text-terciary inline" />}
          </label>
        </div>)
      )}
      <div className="p-2">
        <button className="btn btn-primary py-2 px-4 text-xs" onClick={() => updateFilters({ origin: filtersState }) && close()}>Aplicar</button>
      </div>
    </div>
  )
})

function SelectedNews({ gridClass }) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { selectedNews } = useContext(NewsContext)

  if (!selectedNews) {
    return (
      <div className={`flex justify-center items-center text-gray-500 ${gridClass}`}>
        Nenhuma notícia selecionada
      </div>
    )
  }

  return (
    <>
      <div className={`relative h-full w-full m-auto ${gridClass}`}>
        <RelatedSymbols />
        <button className="absolute right-5 text-lg bg-white" onClick={() => setIsModalOpen(true)} title="Fullscreen" >
          <MdFullscreen />
        </button>
        <iframe src={selectedNews?.link} className={`w-full margin-auto ${selectedNews?.symbols ? 'h-[calc(97%-124px)]' : 'h-[97%]'}`} title={selectedNews.title} />
      </div>
      <ModalShowNews isModalOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} />
    </>
  )
}

function RelatedNews() {

  const { selectedNews, selectNews } = useContext(NewsContext)

  return (
    <div className="b-1 rounded">
      <h3 className="text-lg text-primary font-bold uppercase my-2">Notícias Relacionadas</h3>
      {
        selectedNews?.related?.length ? (
          selectedNews.related.map((news, index) => (
            <div key={`news-${index}`} className={`cursor-pointer hover:bg-[#F6F6F6] flex space-x-3 p-2 items-center ${selectedNews?.link === news.link && "bg-[#EDEDED]"}`} onClick={() => selectNews(news)}>
              <img src={ICONS_MAP.get(news.origin)} className={`rounded-full w-5 h-5`} alt={news.origin} />
              <div className="space-y-1">
                <div className={`text-sm font-semibold leading-tight ${selectedNews?.link !== news.link && news.wasRead && 'text-[#AAAAAA]'}`}>
                  {news.title}
                </div>
                <p className="text-xs text-gray-500 font-semibold">
                  {dateStringToLocaleString(news.publicationDate)}
                </p>
              </div>
            </div>
          ))
        ) : (
          <p className="my-2 text-gray-500 text-sm">Nenhuma notícia relacionada.</p>
        )
      }
    </div>
  )
}

function ModalShowNews({ isModalOpen, closeModal = () => { } }) {

  const { selectedNews } = useContext(NewsContext)
  const { width } = useWindowSize()
  const isMobile = width < LAYOUT_BREAKPOINT_WIDTH

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={{ content: { padding: 0, maxWidth: 648, margin: "auto", borderRadius: '12px', inset: isMobile ? '5px' : '40px' } }}
    >
      <div className="relative h-full overflow-hidden">
        <button className="sticky float-right right-2 top-2 text-xl text-primary z-50" title="Fechar" onClick={closeModal}>
          <MdClose />
        </button>
        {selectedNews?.link ? (
          <>
            <RelatedSymbols />
            <iframe src={selectedNews.link} className={`w-full px-4 mini-scrollbar ${selectedNews?.symbols ? 'h-[calc(97%-124px)]' : 'h-[97%]'}`} title={selectedNews.title} />
          </>
        ) : (
          <div className="text-grey-400 text-sm p-2">Unavailable</div>
        )}
      </div>
    </Modal>
  )
}

function RelatedSymbols() {
  const { selectedNews } = useContext(NewsContext)
  const [isVisible, setIsVisible] = useState(true)

  if (!selectedNews?.symbols) return

  if (!isVisible) return (
    <div className="rounded-full border cursor-pointer inline-block p-3" title="Mostrar ações relacionadas" onClick={() => setIsVisible(true)}>
      <BsChevronRight className="text-primary font-bold" />
    </div>
  )

  return (
    <div className="border rounded p-3 relative mb-2">
      <div className="flex items-center space-x-2">
        <BsChevronLeft className="cursor-pointer text-font-bold" title="Esconder ações relacionadas" onClick={() => setIsVisible(false)} />
        <h1 className="uppercase text-primary text-lg font-bold">Ações relacionadas</h1>
      </div>
      <div className="overflow-auto mini-scrollbar-x">
        <div className="space-x-2 flex flex-nowrap my-2">
          {selectedNews.symbols.map((x, i) => <RelatedSymbolsDetail key={'related-symbol-' + i} symbol={x} origin={2} />)}
        </div>
      </div>
    </div>
  )

}

function RelatedSymbolsDetail({ symbol, origin }) {

  const data = useSymbol(symbol, origin)

  if (!data) return
  const textColor = data && (data[Keys.VAR] === 0 ? 'text-gray-500' : data[Keys.VAR] > 0 ? 'text-profit' : 'text-loss')
  const bgColor = data && (data[Keys.VAR] === 0 ? 'bg-gray-500' : data[Keys.VAR] > 0 ? 'bg-profit' : 'bg-loss')

  const lastDate = data[Keys.DULTCOT] && new Intl.DateTimeFormat('pt-BR', {
    month: '2-digit',
    day: '2-digit'
  }).format(Date.parse(data[Keys.DULTCOT]))

  return (
    <div className="flex rounded border p-2 flex-nowrap items-center space-x-2">
      <div className="whitespace-nowrap">
        <div className="font-bold text-sm opacity-80 flex items-center space-x-1">
          <span>{symbol}</span>
          <Delay item={data} className="flex-none w-3" />
        </div>
        <div className="font-semibold opacity-70 text-xs">
          R$ {format(data[Keys.ULTCOT], 'currency')}
        </div>
      </div>
      {
        typeof data[Keys.VAR] === 'number' ? (
          <div className={`whitespace-nowrap font-semibold self-stretch text-sm flex items-center p-1 rounded ${textColor} ${bgColor} bg-opacity-10`}>
            {format(data[Keys.VAR], 'percent')}
          </div>
        ) : (
          lastDate && (
            <div className={`whitespace-nowrap font-semibold self-stretch text-xs flex items-center `}>
              {lastDate}
            </div>
          )
        )
      }
      <div className="">
        <BtnToggleMyList symbol={symbol} origin={origin} />
      </div>
    </div>
  )

}