import { useEffect, useState } from "react"
import { Responsive, WidthProvider } from "react-grid-layout"
import { LAYOUT_BREAKPOINT_WIDTH_TABLET, LAYOUT_BREAKPOINT_WIDTH_LG } from "const"
import useTitle from "hooks/useTitle"
import useWidgets from "hooks/useWidgets"
import '/node_modules/react-grid-layout/css/styles.css'
import '/node_modules/react-resizable/css/styles.css'
import PreferencesApi from "api/PreferencesApi"
import { toast } from "react-toastify"
import useMaxWindowSize from "hooks/useMaxWindowSize"
import { eventAction } from "hooks/useGoogleAnalytics"
import { useLayoutEngine } from "hooks/useLayoutEngine"
import { AddWidgetButton } from "../shared/widgets/AddWidget"

const ResponsiveGridLayout = WidthProvider(Responsive)

function Workspace() {
  const [workspace, setWorkspace] = useState(null)

  useEffect(() => {
    PreferencesApi.getWorkspaces().then((workspaces) => {
      const mainWorkspace = workspaces.find(e => e.isMain) || workspaces[0]
      mainWorkspace.data = (mainWorkspace.data || []).filter((e) => e.id !== 'AddWidget')
      
      setWorkspace(mainWorkspace)
    }).catch(err => {
      console.error(err)
      toast.warning("Nenhum workspace encontrado. Verifique a conexão ou contate o administrador do sistema.")
    })
  }, [])

  useTitle(workspace?.name || '')

  if (!workspace) return <div>Loading...</div>

  return (
    <WorkspaceLayout workspace={workspace} />
  )
}

function WorkspaceLayout({ workspace }) {
  const [layout, setLayout] = useState(workspace.data || [])
  const layoutEngine = useLayoutEngine(workspace, layout, setLayout)
  const isMobile = useMaxWindowSize(LAYOUT_BREAKPOINT_WIDTH_TABLET)
  const { getWidgetComponent } = useWidgets()

  useEffect(() => {
    const widgetIds = layout.map((e) => e.id)
    eventAction('page_view', widgetIds)
  }, [layout])

  const widgetComponentMap = layout.reduce((ac, props) => ({
    ...ac,
    [props.id]: getWidgetComponent(props.id, {
      ...props,
      removeItem: layoutEngine.removeItem,
      addItems: layoutEngine.addItems,
      resizeItem: layoutEngine.resizeItem,
      updateWidgetParams: layoutEngine.updateWidgetParams
    })
  }), {})

  const props = {
    cols: { mobile: 1, tablet: 2, desktop: 3 },
    breakpoints: { mobile: 0, tablet: LAYOUT_BREAKPOINT_WIDTH_TABLET - 1, desktop: LAYOUT_BREAKPOINT_WIDTH_LG - 1 },
    rowHeight: 30,
    layouts: {
      desktop: layout.map(item => ({ ...item.desktop, i: item.id })),
      tablet: layout.map(item => ({ ...(item.tablet || item.mobile), i: item.id })),
      mobile: layout.map(item => ({ ...item.mobile, i: item.id })),
    },
    margin: [30, 30],
    containerPadding: [isMobile ? 3 : 30, 30],
    autoSize: true,
    compactType: 'vertical',
    onLayoutChange: (_, { desktop, tablet, mobile }) => layoutEngine.changeLayout({ desktop, tablet, mobile }),
  }

  return (
    <>
      <ResponsiveGridLayout className="relative" {...props} draggableHandle=".drag-handle">
        {layout.map((item) => (
          <div id={item.id} className="bg-white relative overflow-hidden rounded-xl" key={item.id}>
            {widgetComponentMap[item.id]}
          </div>
        ))}
      </ResponsiveGridLayout>
      <div className="border-2 border-gray-200 p-2 rounded-xl md:mx-[30px] mb-[30px] w-full flex justify-center">
        <AddWidgetButton onAddItems={layoutEngine.addItems}></AddWidgetButton>
      </div>
    </>
  )
}

export default Workspace
