import { memoize, uniqBy } from "lodash"
import { BASE_URL, getApiHeaders, memoizeResolver, customFetch } from "./settings"
import CompaniesApi from "./CompaniesApi"

const URL = 'ComDinheiro'

const getPosition = async () => {
  const response = await customFetch(`${BASE_URL}/${URL}/position`, { headers: getApiHeaders() })
  if (response.status === 200) {
    return  await response.json()
  } else {
    return null
  }
}

const getWalletPosition = async () => {
  const res = await getPosition()
  const assets = res?.ativos || []
  const stocks = (assets || []).filter(({ classe, saldo }) => classe === 'Ações' && saldo !== 0)
  const results = await Promise.all(stocks.map(({ ativo }) => CompaniesApi.search(ativo)))
  const companies = uniqBy(results.filter(([firstSearchResult]) => !!firstSearchResult).map(([firstSearchResult]) => ({ ...firstSearchResult, sync: 'WALLET' })), 'name')
  return {
    companies,
    assets
  }
}

const cleanWallet = async () => {
  const response = await customFetch(`${BASE_URL}/${URL}/cleanWallet`, { headers: getApiHeaders(), method: 'POST' })
  if (response.status === 200) return response.json()
}

const getWalletUrlIframe = memoize(async () => {
  const response = await customFetch(`${BASE_URL}/${URL}/urliframe`, { headers: getApiHeaders() })
  if (response.status === 200) return response.json()
}, memoizeResolver)

const addUserB3 = memoize(async () => {
  const response = await customFetch(`${BASE_URL}/${URL}/adduserb3`, { headers: getApiHeaders() })
  if (response.status === 200) return response.json()
}, memoizeResolver)

const removeUserB3 = memoize(async () => {
  const response = await customFetch(`${BASE_URL}/${URL}/removeuserb3`, { headers: getApiHeaders() })
  if (response.status === 200) return response.json()
}, memoizeResolver)

const Api = {
  getPosition,
  getWalletPosition,
  getWalletUrlIframe,
  addUserB3,
  removeUserB3,
  cleanWallet
}

export default Api