import { useEffect, useState } from "react"
import { MdClose } from "react-icons/md"

function TagsInput({ tagList = [], onChange = () => {} }) {
  const [terms, setTerms] = useState(tagList)
  const [input, setInput] = useState('')

  useEffect( () => {
    setTerms(tagList)
  }, [tagList])
  
  const addTerm = () => {
    const term = input.toUpperCase().trim()
    if (!term || terms.includes(term)) return
    const newTerms = [...terms, term]
    setTerms(newTerms)
    onChange(newTerms)
    setInput('')
  }

  const removeTerm = (term) => {
    if (terms.every(x => x !== term))
      return
    setTerms(t => t.filter(x => x !== term))
    onChange(terms.filter(x => x !== term))
  }

  const handleKeyDown = (e) => {
    if (e.keyCode !== 13) return
    e.preventDefault()
    addTerm()
  }

  return (
    <>
      <div className="flex items-center space-x-4">
        <input
          className="rounded-lg bg-gray-100 p-2 px-4 text-sm w-full text-gray-600 uppercase"
          value={input} 
          onChange={e => setInput(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Ex: Selic" />
        <button className="btn btn-secondary p-2 px-4" type="button" onClick={() => addTerm()}>Incluir</button>
      </div>

      <div className="rounded border flex-1 p-2 mt-3">
        {terms.map(item => (
          <div className="pl-3 pr-2 py-1 rounded-lg inline-flex items-center space-x-1 bg-gray-100 mr-2 mb-2" key={`symbol-selector-${item}`}>
            <span className="font-semibold text-gray-800">{item}</span>
            <button className="text-sm text-gray-400 hover:text-gray-500" title={`Remover ${item}`} onClick={() => removeTerm(item)}><MdClose /></button>
          </div>
        ))}
        {!terms.length && (<div className="text-sm text-gray-400 m-2">Inclua termos para aparecerem aqui...</div>)}
      </div>
    </>
  )
}

export default TagsInput