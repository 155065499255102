import { useFormContext } from "react-hook-form"
import { CurrencyInput, MaskedInput, NumberInput, PercentageInput } from "./MaskedInput"
import { MdClose } from "react-icons/md"

function ControlledInput({ divClass = "", inputClass = "", divProps = {}, label, name, mask, decimalPoints = 0, options = {}, children, type = "text", errors = "", ...props }) {

  const { register, setValue } = useFormContext()

  return (
    <div className={divClass} {...divProps}>
      {label ? <label htmlFor={name} className="label">{label}</label> : null}
      {
        children || (() => {
          if (type === 'number')
            return <NumberInput id={name} decimalPoints={decimalPoints} {...{ options, name, mask, className: `input ${errors && "border-2 border-red-500"}`, ...props }} />
          switch (mask) {
            case 'currency':
              return <CurrencyInput id={name} type="text" {...{ options, name, mask, className: `input ${errors && "border-2 border-red-500"}`, ...props }} />
            case 'percentage':
              return <PercentageInput id={name} type="text" decimalPoints={decimalPoints} {...{ options, name, mask, className: `input ${errors && "border-2 border-red-500"}`, ...props }} />
            default:
              return mask
                ? <MaskedInput id={name} type={type} {...{ options, name, mask, className: `input ${errors && "border-2 border-red-500"}`, ...props }} />
                : (
                  <div className="relative mb-2">
                    <div className="flex items-center">
                      <input id={name} name={name} {...register(name, options)} className={`input mb-0 ${errors && "border-2 border-red-500"}`} {...props} />
                      <div className="rounded-lg cursor-pointer hover:bg-gray-300 right-4 absolute" onClick={() => setValue(name, '')}>
                        <MdClose className="text-primary" />
                      </div>
                    </div>
                  </div>
                )
          }
        })()
      }
      {/* { validators && <ErrorMessage field={name} />} */}
    </div>
  )
}

export default ControlledInput