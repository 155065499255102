import { useContext } from 'react'
import { Link } from "react-router-dom"
import { FundsContext } from 'context/FundsContext'
import { OrderByColumnDisplay, OrderByColumnTrigger } from 'context/OrderByColumnContext'
import useWindowSize from "hooks/useWindowSize"
import { MdOutlineStar, MdOutlineMoreVert, MdOutlineDiamond } from 'react-icons/md'
import { DropDown } from './DropDown'
import { CheckboxBasic } from 'shared/Checkbox'
import { RatingCustom } from 'shared/RatingCustom'
import ContextualMenu from "shared/ContextualMenu"
import { format } from 'helpers/numberHelper'
import { HelpTooltip } from "shared/HelpTooltip"
import { BtnToggleFavoriteList } from 'shared/funds/FavoriteList'

export function Lists({ size, contextMenuOptions, scrollActive, dataToShow, listCategoriesExisting }) {
  const { listActive } = useContext(FundsContext)
  return (
    <>
      <Header widgetSize={size} menuOptions={contextMenuOptions} scrollActive={scrollActive} />
      {
        listActive && <>
          <Table widgetSize={size} scrollActive={scrollActive} dataToShow={dataToShow} listCategoriesExisting={listCategoriesExisting} />
          {listActive?.funds.length > 0 && <span className='text-dark-gray text-sm mt-[10px]'>{`${listActive.funds.length} fundos`}</span>}
          <div className='mt-[10px] flex flex-col text-dark-gray text-xs font-semibold'>
            <span>O Ranking Valor não é uma recomendação de investimentos. </span>
            <Link className='underline' to={'/metolodogia-ranking-fundos-valor'}>Veja metodologia completa e disclaimer.</Link>
          </div>
        </>
      }
    </>
  )
}

function Header({ widgetSize, menuOptions, scrollActive }) {

  const { listPanel, listActive, favoriteFundList, perCategory, setPerCategory } = useContext(FundsContext)
  const contextOptions = menuOptions.map((item) => {
    if (item?.type === 'restricted' && (listActive?.name || (favoriteFundList && favoriteFundList.id === listActive?.id))) return { ...item, disabled: true }
    return item
  })
  return (
    <div className='flex flex-col sticky left-0'>
      <div className='flex justify-between'>
        <DropDown listPanel={listPanel} size={widgetSize} automaticClose={scrollActive.y || scrollActive.x} />
        <ContextualMenu options={contextOptions} btnTriggerClass="text-primary text-xl px-2 relative top-0 bg-[#EDEDED] hover:bg-[#DBDBDB] rounded-lg">
          <MdOutlineMoreVert />
        </ContextualMenu>
      </div>
      <div className='my-3'>
        <CheckboxBasic style={{ background: 'white' }} styleChecked='color-primary' isChecked={perCategory} setIsChecked={(e) => setPerCategory(e)}>
          <span className='ml-2'>Separar por categoria</span>
        </CheckboxBasic>
      </div>
    </div>
  )
}

function Table({ widgetSize, scrollActive, dataToShow, listCategoriesExisting }) {
  const { tableStructure, listActive } = useContext(FundsContext)

  return (
    listActive && listActive?.funds.length 
      ? <TableStructure 
        data={tableStructure}
        widgetSize={widgetSize}
        scrollActive={scrollActive}
        dataToShow={dataToShow}
        listCategoriesExisting={listCategoriesExisting}
      />
      : <span className='text-dark-gray'>Nenhum fundo adicionado na lista.</span>
  )
}

function TableStructure({ data, widgetSize, scrollActive, dataToShow, listCategoriesExisting }) {
  const { mobile, dateFormat } = useContext(FundsContext)
  const generationId = JSON.stringify(Math.random()).replace('.', '_')

  return (
    <table className="border-x w-full flex-1">
      <thead className={`${widgetSize && scrollActive.y && 'relative'}`}>
        <tr style={{ zIndex: 5 }} className={`${scrollActive.y ? 'sticky -top-4 left-0 right-0' : ''} bg-white shadow-md`}>
          {data.map((col, i) => (
            <OrderByColumnTrigger key={`COL_${col.Header}_${i}`} columnName={col.accessor}>
              <th key={`COL_${col.Header}_${i}`}
                style={{ boxShadow: (col.accessor === 'foundName' && scrollActive.x) ? 'inset -1px 1px 0 #DBDBDB, inset -3px -1px 0 #E4E4E4' : 'inset -1px 1px 0 #DBDBDB, inset -1px -1px 0 #DBDBDB' }}
                className={`${col.accessor === 'foundName' && scrollActive.y && 'sticky z-20 -left-4 bg-white'} py-4 `}
              >
                <div className={`w-full px-2 flex items-center space-x-1 cursor-pointer ${col.type === 'name' ? 'justify-start' : 'justify-end'}`}>
                  <span className={`${col.type !== 'name' && 'text-right'} text-left text-[11px] font-semibold`}>
                    {col.Header.toUpperCase()}
                  </span>
                  {col.description && <HelpTooltip id={col.accessor + "_list_funds_" + generationId} classContainer='flex-none' className=" z-30 w-[232px] p-[5px] text-xs text-left font-bold leading-4 rounded" place='top'>
                    <div className='flex flex-col'>
                      <span>{col.description}</span>
                      {col.updateAt && <span>{`${col.updateAt.text} ${dateFormat(col.updateAt.date)}`}</span>}
                    </div>
                  </HelpTooltip>}
                  {!mobile && <OrderByColumnDisplay classNameTooltip={'z-30'} columnName={col.accessor} tooltip="Ordenar temporariamente" />}
                </div>
              </th>
            </OrderByColumnTrigger>
          ))}
          <th className="border-y" />
        </tr>
      </thead>
      <ListFundsTableBody data={dataToShow.sortedDataToShow} scrollActive={scrollActive} listCategoriesExisting={listCategoriesExisting} />
    </table>
  )
}

function ListFundsTableBody({ scrollActive, data, listCategoriesExisting }) {
  const {
    tableStructure,
    perCategory,
    listCategories,
    categoriesGroup,
    categoryParent,
    mobile
  } = useContext(FundsContext)

  const size = useWindowSize()
  const { orientation } = window.screen

  return (
    <tbody className={`${ scrollActive.y ? 'relative' : '' }`}>
      {data.map((row) => <TableRow
        key={`${row.foundName}_${row.cnpjNumberClean}`}
        {...{ row, tableStructure, perCategory, listCategories, categoriesGroup, size, scrollActive, categoryParent, mobile, orientation, listCategoriesExisting }}
      />)
      }
    </tbody>
  )
}

function TableRow({ row, tableStructure, perCategory, listCategories, categoriesGroup, size, scrollActive, categoryParent, mobile, orientation, listCategoriesExisting }) {

  return (
    <>
      {perCategory && listCategoriesExisting.has(row.cnpjNumberClean) && (
        <tr>
          <td className='max-h-[100px] p-2 bg-primary text-white uppercase' colSpan={15}>
            <span className={`${scrollActive.y && 'sticky left-1'} ${mobile && 'left-10'} `}>{`${categoryParent(row.category)} / ${row.category}`}</span>
          </td>
        </tr>
      )}
      {mobile && <tr>
        <td colSpan={15}>
          <div style={{ width: (size.width - 60) * 0.85 }} className={`inline-flex ${orientation.angle === 90 ? 'space-x-1' : 'justify-between'} ${(scrollActive.y || scrollActive.x) ? 'sticky left-10' : 'ml-10'}`}>
            <div className={`flex flex-col`}>
              <span className={`text-sm text-dark-gray font-semibold`}>{row.foundName}</span>
              <span className='text-xs text-gray-70 font-semibold whitespace-nowrap'>{row.cnpjNumber}</span>
            </div>
            <BtnToggleFavoriteList fund={row} />
          </div>
        </td>
      </tr>
      }
      <tr className="border-b">
        {tableStructure.map(cell =>
          <td
            key={row.foundName + '_' + cell.Header}
            style={{ minWidth: '100px', boxShadow: (cell.accessor === 'foundName' && scrollActive.x) && 'inset -3px 0 0 #E4E4E4' }}
            className={`p-2 ${cell.accessor === 'foundName' && scrollActive.y && 'sticky -left-4 bg-white'}`}
          >
            <TableCell value={row[cell.accessor]} row={row} info={cell} listCategories={listCategories} categoriesGroup={categoriesGroup} />
          </td>)}
      </tr>
    </>
  )
}

function TableCell({ value, row, info: { Header, type, accessor }, listCategories, categoriesGroup }) {

  let valueFormatted
  valueFormatted = value
  if (type === 'barGraphic') {
    if (value || value === 0) return <RatingCustom
      id={Header + 'barGraphic'}
      position='top'
      classContainer='mx-2 min-w-[120px]'
      classContent='justify-center'
      value={value}
    />
    if (!value) return <span className='line-clamp-2 text-sm text-dark-gray text-center'>-</span>
  }
  if (type === 'starGraphic') {
    if (value) return <RatingCustom
      id={Header + 'starGraphic'}
      position='top'
      classContainer='flex justify-center px-2 w-full'
      value={value}
      icon={<MdOutlineStar />}
    />
    if (value === 0) return <div className='text-center text-dark-gray text-sm'>Menos de 5 anos</div>
    if (!value) return <span className='line-clamp-2 text-sm text-dark-gray text-center'>-</span>
  }
  if (type === 'integer') {
    if(value) valueFormatted = `${format(value, type)}`
  }
  if (type === 'float') {
    if (value || value === 0) valueFormatted = `${value > 0 ? '+' : '-'}${Number(Math.abs(value)).toFixed(2)}`.replace('.', ',')
    return <span className={`${type === 'float' ? value === 0 || !value ? 'text-dark-gray' : value > 0 ? 'text-profit' : 'text-loss' : ''} line-clamp-2 text-sm text-right`}>{value ? valueFormatted : value === 0 ? '0,00' : '-'}</span>
  }
  if (type === 'percent') valueFormatted = value ? `${Number(value).toFixed(2)}`.replace('.', ',') : value === 0 ? '0,00' : null
  if (type === 'numberToString') return <span className='line-clamp-2 text-sm text-right text-dark-gray'>{value || value === 0 ? `D+${value}` : '-'}</span>
  if (type === 'name' && accessor === 'category' && value) {
    const category = listCategories.find(({ name }) => name === value)
    const { name } = categoriesGroup.find((group) => group?.subCatId.some(({id}) => id === category.id))
    return <div className='text-sm text-dark-gray'>
      <span className='flex whitespace-nowrap'>{`${name} /`}</span>
      <span>{value}</span>
    </div>
  }

  if (type === 'name' && accessor === 'foundName') {
    return <div className='flex flex-col'>
      <div className='flex justify-between space-x-1'>
        <span className={`flex line-clamp-2 text-sm text-dark-gray font-semibold`}>{valueFormatted}</span>
        <BtnToggleFavoriteList fund={row} />
      </div>
      <span className='text-xs text-gray-70 font-semibold whitespace-nowrap'>{row.cnpjNumber}</span>
    </div>
  }

  if (row.cnpjNumberClean) return <div className='flex flex-col'>
    <span className={`text-sm text-dark-gray ${type !== 'name' && 'text-right'}`}>
      {valueFormatted
        ? valueFormatted
        : value === 0 ? 0 : '-'}
    </span>
  </div>
}
