import { LAYOUT_BREAKPOINT_WIDTH, RANKING_CATEGORY, RANKING_STARS } from "const"
import useTitle from "hooks/useTitle"
import { useState } from "react"
import useWindowSize from "hooks/useWindowSize"


export function FundsRankingMethodology() {
  useTitle('Metodologia de ranking de fundos')

  const { width } = useWindowSize()
  const isMobile = width < LAYOUT_BREAKPOINT_WIDTH

  const [isFirstQuestionVisible, setIsFirstQuestionVisible] = useState(false);
  const [isSecondQuestionVisible, setIsSecondQuestionVisible] = useState(false);
  const [isThirdQuestionVisible, setIsThirdQuestionVisible] = useState(false);
  const [isFourthQuestionVisible, setIsFourthQuestionVisible] = useState(false);
  const [isFifthQuestionVisible, setIsFifthQuestionVisible] = useState(false);
  const [isSixthQuestionVisible, setIsSixthQuestionVisible] = useState(false);

  return (
    <div className={`${isMobile ? 'px-[30px]' : 'px-[349px]'} py-10 flex flex-col space-y-4 bg-white leading-[26px] text-[#282828] items-center`}>
      <div className={`${!isMobile && 'w-[649px]'} text-[#282828]`}>
        <h1 className="text-primary text-xl/[26px] font-bold text-left uppercase mb-4">Metodologia - Ranking Valor</h1>

        <span>
          O <strong>Ranking Valor de Fundos de Investimento</strong>, de metodologia totalmente quantitativa e pública, foi elaborado para medir
          a consistência de longo prazo no desempenho dos fundos de investimento disponíveis para os investidores em geral no Brasil. O ranking procura mostrar os fundos dentro de cada
          categoria do Guia de Fundos do Valor que, com maior frequência, estiveram entre os de melhor desempenho em suas categorias em janelas sucessivas trimestrais de 36 meses.
          Para poderem participar do Ranking Valor, os fundos precisam obedecer a dois critérios: estarem no
          <span onClick={() => document.getElementById('section2')?.scrollIntoView({ behavior: 'smooth' })} className={`font-bold text-primary cursor-pointer`}> Guia de Fundos do Valor</span>, o que implica
          estarem disponíveis para aplicação em bancos e plataformas para investidores em geral até a <strong>“data de corte*”</strong>, e existirem há pelo menos <strong>cinco anos**</strong>, contados até o fim do
          trimestre anterior ao da publicação. Uma analogia para entender a lógica para atribuição das estrelas <strong>no Ranking Valor - Consistência</strong> é a de um campeonato de pontos corridos em que se mede os pontos obtidos
          pelos times ao longo de vários anos e aqueles com a maior pontuação acumulada (de forma proporcional ao máximo que poderiam ter obtido ao se pensar nos períodos em que disputaram o
          campeonato) são os mais bem avaliados. Um time que está sempre entre os primeiros lugares, mesmo que nunca tenha sido campeão, estará entre os que tem desempenho positivo de forma
          mais consistente. No caso dos fundos, aqueles que costumam estar entre os melhores dentro de cada categoria do <strong>Guia de Fundos do Valor</strong> em janelas de 36 meses recebem mais estrelas.
        </span>

        <br /><br />

        <span>
          Na base de dados do Guia de Fundos do Valor, usado como referência para atribuição das estrelas do Ranking Valor - Consistência, existem informações sobre a rentabilidade e o índice de Sharpe desde
          julho de 2016, o que permite que, nesta primeira edição, divulgada em maio de 2024, sejam analisados 20 períodos sucessivos de 36 meses até março  de 2024.
          <br /> <br />

          Os períodos serão atualizados a cada três meses, o que significa que a primeira atualização ocorrerá em agosto  de 2024, com a incorporação dos fundos que entrarem na edição de março
          de 2024 do Guia e com os dados de desempenho do período de julho de 2021 a junho de 2024, e assim sucessivamente.
          <br /> <br />

          Para se medir o desempenho em cada um dos períodos são considerados o índice de Sharpe, que mede o retorno ajustado ao risco do fundo, e a rentabilidade.
          <br />
        </span>

        <br /><br />

        <strong className="mb-20">
          *Data de corte: entraram no Ranking Valor os fundos que estavam no Guia de Fundos em dezembro/2023.<br /> <br />
          **Prazo de cinco anos: Estão divulgadas as estrelas dos fundos integrantes do Guia de Fundos e que operaram entre 01/04/2019 e 31/03/2023, pelo menos.
        </strong>

        <div className="">

          <h1 id="section2" className="text-primary text-xl/[26px] font-bold text-left uppercase mt-28 mb-4">O cálculo das estrelas do Ranking Valor - Consistência e das barras do Ranking Valor - Últimos 36 meses</h1>

          <span>
            Em bases separadas, os fundos são ordenados pelo Índices de Sharpe e Rentabilidade em 36 meses dentro de uma mesma categoria. O fundo com maior rentabilidade e o fundo com
            maior índice de Sharpe, sempre em 36 meses, recebem o maior valor (100) proporcional da amostra (que depende do número de fundos em cada categoria) e os fundos com pior
            rentabilidade e pior índice de Sharpe recebem o pior valor (mais próximo de zero). Índices de Sharpe negativos não são considerados, dado que a natureza do indicador é medir
            o retorno superior à taxa livre de risco.

            <br /> <br />

            Esses resultados são então <strong>ponderados por pesos</strong>, conforme a categoria.<br /> <br />

            <ul className="list-disc ml-[50px]">
              <li>Para os fundos das categorias Renda Fixa DI, Prefixado Renda Fixa Ativo, Juro Real, Debênture Incentivada, Crédito Privado Até 15 dias, Crédito Privado Acima de 16
                dias, Multimercado baixa Volatilidade, Ações Índice, Ações no Exterior e Investimento no Exterior, a rentabilidade tem peso 70% e o Índice de Sharpe, de 30%.
              </li>
              <br />
              <li>Para os fundos das categorias Multimercado, Long Biased, Long & Short, Ações, Alocação Multimercado e Alocação Ações, a rentabilidade tem peso 30% e o Índice de
                Sharpe, de 70%.
              </li>
            </ul>

            <br /> <br />

          </span>

          <span>
            Essas notas são então somadas e os fundos novamente ordenados do maior para o menor. O resultado da janela mais recente vai determinar o <strong>"Ranking Valor - Últimos 36 meses"
              que aparece na ferramenta de fundos do Valor One. Os fundos que estiverem entre os 10% melhores por esse critério ficam com todas as barras roxas e a indicação
              "10 de 10", e assim sucessivamente.</strong> <br /> <br />

            Para atribuição das estrelas do Ranking Valor - Consistência, esse processo é repetido para todos os períodos trimestrais desde a primeira medição, entre julho de 2016 e junho
            de 2019, e o resultado é somado e avaliado de forma proporcional aos períodos em cada fundo tinha dados disponíveis. <br /> <br />

            Dentro de cada amostra, <strong>os fundos que estiverem entre os 25% com desempenho mais consistente da categoria recebem 4 estrelas.</strong> <br /> <br />

            Fundos que constam do Guia de Fundos do Valor, mas que <strong>possuem menos de cinco anos de existência não têm as estrelas reveladas publicamente e aparecem com a indicação de
              “Menos de 5 anos”.</strong> <br /> <br />

          </span>
        </div>

        <div className="space-y-4">

          <h1 className="text-primary text-xl/[26px] font-bold text-left uppercase mt-36 mb-4">Legenda - Ranking Valor - Consistência</h1>

          {RANKING_STARS.map((star, index) => (
            <div className="flex" key={star.description}>
              <img src={star.img} alt={`Estrelas: ${index + 1}`} />
              <span className="ml-2 font-semibold">{star.description}</span>
            </div>
          ))} <br />

          <strong>Menos de 5 anos*</strong> <span className="font-semibold"> - Estrelas não reveladas publicamente pelo fato de o fundo ainda não possuir o mínimo de cinco anos de avaliação. </span><br /> <br />

          <strong>ND</strong><span className="font-semibold"> – Fundo não foi avaliado pelo Ranking Valor - Consistência</span>  <span onClick={() => document.getElementById('frequent-questions')?.scrollIntoView({ behavior: 'smooth' })} className={`font-bold text-primary cursor-pointer`}>(ver nas perguntas frequentes porque o fundo não foi avaliado)</span>

        </div>

        <div className="space-y-4">
          <h1 className="text-primary text-xl/[26px] font-bold text-left uppercase mt-36 mb-5">Legenda – Ranking Valor - Últimos 36 meses</h1>

          {RANKING_CATEGORY.map((star, index) => (
            <div className="flex space-y-4" key={star.description}>
              <img src={star.img} alt={`Estrelas: ${index + 1}`} />
              <span className="ml-2 mt-1 font-semibold">{star.description}</span>
            </div>
          ))} <br />

          <strong>ND</strong><span className="font-semibold"> – Fundo não foi avaliado.</span><span onClick={() => document.getElementById('frequent-questions')?.scrollIntoView({ behavior: 'smooth' })} className={`font-bold text-primary cursor-pointer`}>(ver nas perguntas frequentes porque o fundo não foi avaliado)</span>

          <br /><br />

          <strong>*encerrada em março, junho, setembro ou dezembro</strong>

        </div>

        <div className="space-y-4">
          <h1 id="frequent-questions" className="text-primary text-xl/[26px] font-bold text-left uppercase mt-36 mb-7">Perguntas Frequentes</h1>
          <>
            <div className="flex">
              <strong onClick={() => {
                setIsFirstQuestionVisible(!isFirstQuestionVisible)
              }} className="mb-3 cursor-pointer">Qual a diferença entre as estrelas do "Ranking Valor - Consistência" e "Ranking Valor – Últimos 36 meses"?</strong>
              <span onClick={() => {
                setIsFirstQuestionVisible(!isFirstQuestionVisible)
              }} class="material-icons text-primary cursor-pointer">
                {`${!isFirstQuestionVisible ? 'expand_more' : 'expand_less'}`}
              </span>
            </div>

            {isFirstQuestionVisible &&
              <span>Como procura medir a consistência de longo prazo, o <strong>Ranking Valor - Consistência</strong> seria uma espécie de "filme" que mede o desempenho dos
                fundos ao longo dos anos, e o <strong>"Ranking Valor – Últimos 36 meses"</strong> é a "foto" mais recente, com relação aos últimos 36 meses, pelo mesmo critério.<br /> <br />

                Um fundo pode estar com desempenho ruim na última "foto", mas ter consistência de longo prazo (no filme) e, assim, ter várias estrelas. E também pode ocorrer o inverso, com um
                fundo que sequer tem estrelas (por ser mais jovem) ou ter poucas estrelas pelo desempenho histórico (filme) e ter demonstrado um bom resultado na última janela de observação (foto).
              </span>}
          </>

          <>
            <div className="flex">
              <strong onClick={() => {
                setIsSecondQuestionVisible(!isSecondQuestionVisible)
              }} className="mb-3 cursor-pointer">Por que o fundo que eu pesquisei não tem estrelas no Ranking Valor - Consistência?</strong>
              <span onClick={() => {
                setIsSecondQuestionVisible(!isSecondQuestionVisible)
              }} class="material-icons text-primary cursor-pointer text-right">
                {`${!isSecondQuestionVisible ? 'expand_more' : 'expand_less'}`}
              </span>
            </div>

            {isSecondQuestionVisible &&
              <span className="mb-3">Para receberem estrelas do Ranking Valor - Consistência, os fundos precisam obedecer a dois critérios: (i) estarem no Guia de Fundos do Valor (o que implica
                estarem disponíveis para aplicação em bancos e plataformas para investidores em geral) e (ii) existirem há pelo menos cinco anos (contados até o fim do trimestre anterior ao da
                publicação). Na primeira edição do Ranking, isso significa ter entrado no Guia de Fundos do Valor até pelo menos dezembro de 2023 e ter cotas públicas pelo menos desde 01/04/2019
                até 31/03/2024.
                <br /> <br />

                Para Fundos mais novos que são "espelhos" (que replicam a carteira) de fundos mais antigos, vale observar as estrelas do fundo original. Isso é comum em fundos famosos distribuídos
                em bancos e plataformas.
              </span>}
          </>

          <>
            <div className="flex">
              <strong onClick={() => {
                setIsThirdQuestionVisible(!isThirdQuestionVisible)
              }} className="mb-3 cursor-pointer">Por que um fundo com mais de 36 meses não está no Guia de Fundos do Valor?</strong>
              <span onClick={() => {
                setIsThirdQuestionVisible(!isThirdQuestionVisible)
              }} class="material-icons text-primary cursor-pointer">
                {`${!isThirdQuestionVisible ? 'expand_more' : 'expand_less'}`}
              </span>
            </div>

            {isThirdQuestionVisible &&
              <span className="mb-3">O Guia de Fundos do Valor reúne os fundos que estão disponíveis para investimento em bancos e plataformas para
                investidores em geral. A metodologia completa está <span onClick={() => document.getElementById('methodology1')?.scrollIntoView({ behavior: 'smooth' })} href="" target="_blank" rel="noreferrer" className={`font-bold text-primary cursor-pointer`}>aqui</span>. Nos casos que uma gestora tem vários fundos que replicam a mesma carteira, apenas
                fundos mais populares (com mais cotistas e patrimônio líquido) de cada gestora entram no ranking. <br /> <br />

                Para Fundos mais novos que são "espelhos" (que replicam a carteira) de fundos mais antigos, vale observar as estrelas do fundo original. Isso é comum em fundos famosos distribuídos
                em bancos e plataformas.

              </span>}
          </>

          <>
            <div className="flex">
              <strong onClick={() => {
                setIsFourthQuestionVisible(!isFourthQuestionVisible)
              }} className="mb-3 cursor-pointer">Com que frequência o "Ranking Valor - Consistência" e a "Ranking Valor - Últimos 36 meses" são atualizados?</strong>
              <span onClick={() => {
                setIsFourthQuestionVisible(!isFourthQuestionVisible)
              }} class="material-icons text-primary cursor-pointer">
                {`${!isFourthQuestionVisible ? 'expand_more' : 'expand_less'}`}
              </span>
            </div>

            {isFourthQuestionVisible &&
              <span className="mb-3">Tanto a base de fundos, que partem do Guia de Fundos do Valor, como as estrelas são atualizados em até 30 dias de cada trimestre fechado.</span>}
          </>

          <>
            <div className="flex">
              <strong onClick={() => {
                setIsFifthQuestionVisible(!isFifthQuestionVisible)
              }} className="mb-3 cursor-pointer">Por que o Valor One usa a rentabilidade média anualizada dos retornos de 36 meses e 60 meses?</strong>
              <span onClick={() => {
                setIsFifthQuestionVisible(!isFifthQuestionVisible)
              }} class="material-icons text-primary cursor-pointer">
                {`${!isFifthQuestionVisible ? 'expand_more' : 'expand_less'}`}
              </span>
            </div>

            {isFifthQuestionVisible && <span className="mb-3">Por trazer o retorno numa base "ao ano", ela se torna mais fácil para ser comparada com taxas como a Selic e o CDI.</span>}
          </>

          <>
            <div className="flex">
              <strong onClick={() => {
                setIsSixthQuestionVisible(!isSixthQuestionVisible)
              }} className="mb-3 cursor-pointer">Por que alguns fundos não têm dados de aplicação mínima inicial, prazo de resgate ou taxa de administração informados no Valor One?</strong>
              <span onClick={() => {
                setIsSixthQuestionVisible(!isSixthQuestionVisible)
              }} class="material-icons text-primary cursor-pointer">
                {`${!isSixthQuestionVisible ? 'expand_more' : 'expand_less'}`}
              </span>
            </div>

            {isSixthQuestionVisible &&
              <span className="mb-3">O Valor One capta esses dados de diferentes bases da Comissão de Valores Mobiliários, que recebe as informações das
                próprias gestoras. Algumas informações podem estar disponíveis apenas outras páginas, como na página do administrador do fundo.</span>}
          </>

        </div>

        <div className="space-y-4">
          <h1 id="methodology1" className="text-primary text-xl/[26px] font-bold text-left uppercase mt-36 mb-7">Metodologia - Guia Valor de Fundos de Investimento</h1>
          <span>
            O Guia Valor de Fundos de Investimento lista 1.800 carteiras com histórico de pelo menos três anos e que estão potencialmente disponíveis para os investidores em geral.
            O objetivo foi analisar o universo de fundos abertos para captação. Entretanto, alguns podem estar momentaneamente fechados ou apenas aceitarem investimentos indiretamente
            por meio de outros fundos, chamados de fundos espelho, no jargão de mercado.
            <br /> <br />

            A principal característica do Guia é agrupar as carteiras em categorias que possuam algum significado prático para demonstrar a possibilidade de diversificação de aplicações
            por um investidor. E o período de comparação de três anos é suficientemente longo para testar a consistência do desempenho das carteiras.
            <br /> <br />

            Atualmente mais de 90% dos fundos de investimentos domiciliados no Brasil são exclusivos ou restritos a determinados grupos de investidores. Colocar todas essas carteiras
            numa mesma listagem acaba tendo pouco significado prático para as decisões de investimento.
            <br /> <br />

            Optamos por eliminar os fundos classificados pela Associação Brasileira das Entidades dos Mercados Financeiro e de Capitais (Anbima) como cambial, mono ações, capital
            protegido, previdência e dívida externa.
            <br /> <br />

            Cortamos os fundos cujos públicos-alvo são restritos, como aqueles destinados para entidades do poder público, funcionários de determinadas empresas, investidores
            institucionais e pessoas jurídicas. E eliminamos, também, tanto os fundos que possuem tratamento fiscal de curto prazo quanto as carteiras vinculadas aos fundos 157,
            um antigo benefício fiscal criado na década de 1960.
            <br /> <br />

            A partir desse universo mais restrito, buscamos refinar e rever nossos critérios de corte para evitar que carteiras relevantes ficassem de fora do Guia. Para tanto,
            pesquisamos e acrescentamos, conforme o caso, os fundos de gestores elencados no Anuário da Indústria de Fundos de Investimento da Fundação Getulio Vargas e na listagem
            de gestores da Anbima. Consideramos, também, os fundos distribuídos nas principais corretoras e plataformas de investimento.
            <br /> <br />

            Após definido o conjunto que seria avaliado, passamos ao agrupamento por categorias. Para isso, tomamos por base as classificações do fundo estabelecidas pela Comissão
            de Valores Mobiliários (CVM), com base na exposição a determinados fatores de risco. A regulamentação da CVM orienta que o nome das carteiras deve conter “sufixos”
            relacionados aos tipos de estratégias que perseguem, tais como “multimercado”, “renda fixa”, e “ações”.
            <br /> <br />

            Levamos em consideração, também, estratégias que compõem o nome do fundo, a política de investimento e a composição dos ativos da carteira.
            <br /> <br />

            Para elaborar o guia usamos as informações da Morningstar, uma empresa independente líder no fornecimento de dados e análises de investimentos com atuação global.
            <br /> <br />

            Organizamos os fundos em 16 categorias, conforme descrição abaixo:<br /> <br />

          </span>

          <div className="mb-3">
            <h2 className="font-bold mb-3">Renda Fixa DI</h2>

            <span>Inclui fundos de "renda fixa" mais conservadores, que acompanham de perto o CDI e a taxa Selic ou a variação dos títulos prefixados de até um ano.
              A categoria exclui os fundos que tem o sufixo “crédito privado” no nome.
            </span>
          </div>

          <div className="mb-3">
            <h2 className="font-bold mb-3">Prefixados Renda Fixa Ativo</h2>

            <span>Inclui fundos de “renda fixa” que costumam ter correlação menor com o CDI e variam mais próximos dos títulos do Tesouro Prefixado e do índice de renda
              fixa prefixada calculado pela Anbima, IRF-M, ou que adotam declaradamente estratégias de gestão ativa.
            </span>
          </div>

          <div className="mb-3">
            <h2 className="font-bold mb-3">Juro Real</h2>

            <span>Inclui fundos de “renda fixa” que costumam ter correlação menor com o CDI e variam mais próximos dos títulos do Tesouro IPCA+ e dos índices da Anbima
              para títulos indexados ao IPCA, como IMAB, IMAB-5 ou IMAB-5+.
            </span>
          </div>

          <div className="mb-3">
            <h2 className="font-bold mb-3">Crédito Privado com até 15 dias para o resgate</h2>
            <span>
              Inclui fundos de “renda fixa” que emprestam mais dinheiro a bancos e empresas do que ao Tesouro Nacional, em busca de um retorno maior. Entram no grupo os
              fundos com o sufixo “crédito privado”, e os fundos “multimercado” com as seguintes características: baixa variação das cotas diárias, com o sufixo “crédito privado”
              e carteira composta majoritariamente por títulos privados, mas com parcela inferior a 40% em debêntures e Fundos de Investimento em Direitos Creditórios (FIDCs).<br /><br />
              O resgate solicitado pelo cotista é pago no prazo de até 15 dias.
            </span>
          </div>

          <div className="mb-3">
            <h2 className="font-bold mb-3">Crédito Privado a partir de 16 dias para resgate</h2>
            <span>
              Inclui fundos de “renda fixa” que emprestam mais dinheiro a bancos e empresas do que ao Tesouro Nacional, em busca de um retorno maior. Entram no grupo os fundos com o sufixo
              “crédito privado” e os fundos “multimercado” com baixa variação das cotas diárias, com o sufixo “crédito privado” e carteira composta majoritariamente por títulos privados.<br /><br />
              O resgate solicitado pelo cotista é pago em 16 dias ou mais.
            </span>
          </div>

          <div className="mb-3">
            <h2 className="font-bold mb-3">Debênture Incentivada</h2>
            <span>
              Inclui fundos de "renda fixa" de um tipo específico entre aqueles de crédito privado, se identificam como sendo de debêntures incentivadas (que emprestam dinheiro a
              empresas que emitem esses papéis) e, consequentemente, não estão sujeitos ao Imposto de Renda sobre os rendimentos.
            </span>
          </div>

          <div className="mb-3">
            <h2 className="font-bold mb-3">Multimercado baixa volatilidade</h2>

            <span>Inclui fundos “multimercados” (que podem operar em mercados de renda fixa, renda variável e moedas) de menor risco entre os multimercados. Entram no grupo os fundos sem o sufixo "crédito privado" e que tiveram perdas mensais muito pequenas ou inexistentes no período analisado e volatilidade relativamente baixa, inferiores à do índice IRF-M.
            </span>
          </div>

          <div className="mb-3">
            <h2 className="font-bold mb-3">Multimercado</h2>

            <span>Inclui fundos “multimercados” que podem adotar estratégias de investimento variadas nos mercados e renda fixa, renda variável e moedas, e não foram enquadrados em outras subcategorias.
            </span>
          </div>

          <div className="mb-3">
            <h2 className="font-bold mb-3">Long Short</h2>

            <span>Inclui fundos que afirmam seguir uma estratégia “long and short”. A estratégia envolve a manutenção de posições compradas — que apostam na alta (long, em inglês) — e vendidas — que apostam na baixa (short, em inglês) — em ativos semelhantes.
            </span>
          </div>

          <div className="mb-3">
            <h2 className="font-bold mb-3">Long Biased</h2>

            <span>Inclui fundos “multimercado” ou “ações” que se apresentam como seguindo uma estratégia “long biased”. Geralmente são carteiras que podem ficar integralmente compradas em ações (apostando na alta), mas que consideram fazer operações de proteção contra perdas (posições vendidas, apostando na baixa), em determinados momentos. O viés (bias, em inglês) é mais comprado.
            </span>
          </div>


          <div className="mb-3">
            <h2 className="font-bold mb-3">Ações Índices </h2>

            <span>Inclui fundos de “ações” chamados de passivos, que seguem o Índice Bovespa de perto. No período analisado, registraram as estatísticas “tracking error” de até 4% ao ano, “correlação” com o Ibovespa acima de 98% e “beta” entre 0,95 e 1,03.
            </span>
          </div>


          <div className="mb-3">
            <h2 className="font-bold mb-3">Ações</h2>

            <span>Inclui fundos de “ações” que tentam superar o retorno do Ibovespa. Inclui fundos com “tracking error” acima de 4% ao ano, o que os distância dos retornos do Ibovespa. Na prática, possuem uma ampla variedade de estratégias no mercado de renda variável.
            </span>
          </div>


          <div className="mb-3">
            <h2 className="font-bold mb-3">Investimento no Exterior</h2>

            <span>Inclui fundos que possuem no nome o sufixo “IE”, de investimento no exterior, ou que aplicam em ativos ou fundos de investimentos no exterior. Podem ser de renda fixa ou renda variável, indexados ao real ou a moedas estrangeiras.
            </span>
          </div>

          <div className="mb-3">
            <h2 className="font-bold mb-3">Ações no Exterior</h2>

            <span>Inclui “multimercados” ou fundos de “ações” que investem no exterior por meio de “Brazilian Depositary Receipts” (BDRs), ETFs ou operações de derivativos.
            </span>
          </div>

          <div className="mb-3">
            <h2 className="font-bold mb-3">Alocação Multimercado</h2>

            <span>Inclui fundos que investem em outros fundos “multimercados” para montar uma carteira balanceada.
            </span>
          </div>

          <div className="mb-3">
            <h2 className="font-bold mb-3">Alocação Ações</h2>

            <span>Inclui fundos que investem em outros fundos de “ações” para montar uma carteira balanceada
            </span>
          </div>

        </div>

        <div className="space-y-4">
          <h1 className="text-primary text-xl/[26px] font-bold text-left uppercase mt-36 mb-5">Disclaimer</h1>

          <span>
            O "Ranking Valor de Fundos de Investimento", desenvolvido pelo Valor Econômico, tem metodologia totalmente quantitativa
            e pública e procura medir e comparar o desempenho passado de longo prazo dos fundos de investimento que constam do Guia de Fundos do Valor.
          </span> <br /> <br />

          <strong>Porém, o desempenho e a performance passados dos fundos não garantem seu desempenho ou performance futuros. Assim, a utilização das tabelas para qualquer
            fim constitui risco exclusivo do usuário.
          </strong> <br /> <br />

          <strong>O Valor One, o Valor Econômico e a Editora Globo não se responsabilizam pela decisão de investimento tomada por qualquer pessoa com base nessas informações
            ou por alterações no desempenho dos fundos.
          </strong> <br /> <br />

          <span>
            O Guia de Fundos do Valor é elaborado pelo economista Marcelo d´Agosto, a partir de informações fornecidas pela Morningstar em seu terminal Morningstar Direct.
            Portanto, o Valor One, o Valor Econômico e a Editora Globo não se responsabilizam pelas as informações de terceiros que serviram de base para as tabelas e dados
            de rentabilidade passada, índice de Sharpe, riscos etc. Ademais, os dados cadastrais dos fundos são alimentados a partir das bases da Comissão de Valores
            Mobiliários (CVM) e informados pelas próprias gestoras. O Valor One, o Valor Econômico e a Editora Globo não se responsabilizam por eventuais divergências constantes
            nesses dados e aqueles que constam do regulamento e prospecto dos fundos de investimento.
          </span> <br /> <br />

          <span>
            O Ranking Valor de Fundos de Investimento e o Guia de Fundos do Valor (i) não constituem análise de valor mobiliário, solicitação de compra ou venda, oferta ou
            recomendação de qualquer ativo financeiro ou investimento; e (ii) são meramente informativos, não consideram objetivos de investimento, situação financeira ou necessidades
            individuais e particulares e não contêm todas as informações que um investidor em potencial deve considerar ou analisar antes de tomar qualquer decisão de investimento.
          </span> <br /> <br />

        </div>
      </div>
    </div>
  )
}