import { Tooltip } from "react-tooltip"

export function HelpTooltip({ id, ...props }) {
  id = 'tooltip_' + id

  return (
    <>
      <button className={`${props.classContainer} w-4 h-4 leading-none text-xs bg-secondary text-primary rounded-full text-center font-bold`} id={id}>?</button>
      <SimpleTooltip anchor={'#' + id} className="bg-primary font-semibold rounded-[4px] text-xs text-center opacity-100" {...props} />
    </>
  )
}

export function SimpleTooltip({ anchor, value, children, ...props }) {
  return (
    <Tooltip anchorSelect={anchor} {...props} className={' bg-primary p-[5px] font-semibold text-xs opacity-100' + (props.className || '')}>
      {value || children}
    </Tooltip>
  )
}