import { useRef, useCallback } from "react"
import PreferencesApi from "api/PreferencesApi"
import { isEqual, pick } from "lodash"
import { toast } from "react-toastify"

export function useLayoutEngine(workspace, layout, setLayout) {
  const changingLayoutRef = useRef(layout)

  const saveWorkspace = useCallback(async ({ dispatchChanges }) => {
    try {
      await PreferencesApi.updateWorkspace({ ...workspace, data: changingLayoutRef.current })
      if (dispatchChanges) {
        setLayout([...changingLayoutRef.current])
      }
    } catch (error) {
      toast.error("Erro ao salvar workspace. Tente novamente mais tarde.")
    }
  }, [workspace, layout, setLayout])

  const changeLayout = useCallback((payload) => {
    const newLayout = changingLayoutRef.current.map(item => ({
      ...item,
      desktop: payload.desktop.find(x => x.i === item.id) || item.desktop,
      tablet: payload.tablet.find(x => x.i === item.id) || item.tablet,
      mobile: payload.mobile.find(x => x.i === item.id) || item.mobile
    }))
    if (isEqual(changingLayoutRef.current, newLayout)) return
    changingLayoutRef.current = newLayout

    const hasChangesInWidgetWidth = changingLayoutRef.current.some((item, i) => {
      const { desktop, tablet, mobile } = layout[i]
      return desktop.w !== item.desktop.w || tablet.w !== item.tablet.w || mobile.w !== item.mobile.w
    })

    saveWorkspace({ dispatchChanges: hasChangesInWidgetWidth })
  }, [saveWorkspace])

  const removeItem = useCallback((id) => {
    const foundIndex = changingLayoutRef.current.findIndex(x => x.id === id)
    if (foundIndex === -1) return
    changingLayoutRef.current = changingLayoutRef.current.filter((_, i) => i !== foundIndex)
    saveWorkspace({ dispatchChanges: true })
  }, [saveWorkspace])

  const addItems = useCallback((items) => {
    if (!items?.length) return

    const calculateXandY = (columns, breakpointName) => {
       const columnHeight = Array(columns).fill(0) // Inicializar alturas de cada coluna com 0
 
       // Atualizar as alturas das colunas com base no layout atual
       changingLayoutRef.current.forEach(item => {
         if (item[breakpointName].x < columns) { // Garantir que o item esteja dentro do limite de colunas
           const topOfItem = item[breakpointName].y + item[breakpointName].h
           if (columnHeight[item[breakpointName].x] < topOfItem) {
             columnHeight[item[breakpointName].x] = topOfItem
           }
         }
       })
 
       // Encontrar a coluna com a menor altura
       const columnIndex = columnHeight.indexOf(Math.min(...columnHeight))
 
       // Posição x é o índice da coluna, y é a altura mínima daquela coluna
       return {
         x: columnIndex || 0,
         y: columnHeight[columnIndex] || 0
       }
    }

    const calculateNextWidgetPosition = () => {
      // Mobile
      const maxY = Math.max(...changingLayoutRef.current.map(x => x.mobile.y))
      const lastMobileItem = changingLayoutRef.current.find(x => x.mobile.y === maxY)
      const mobile = pick(lastMobileItem?.mobile, ['x', 'y'])
      if (!mobile.x) mobile.x = 0
      if (!mobile.y) mobile.y = 0
      mobile.y++

      const desktop = calculateXandY(3, 'desktop')
      const tablet = calculateXandY(2, 'tablet')

      return { mobile, tablet, desktop }
    }

    for (const item of items.filter(({ id }) => id)) {
      const { id, defaults: { params = {}, mobile = {}, tablet = {}, desktop = {} } } = item
      const position = calculateNextWidgetPosition()
      const widget = {
        id,
        params,
        mobile: { w: 1, h: 6, isResizable: true, ...mobile, i: id, ...position.mobile },
        tablet: { w: 1, h: 6, isResizable: true, ...tablet, i: id, ...position.tablet },
        desktop: { w: 1, h: 6, isResizable: true, ...desktop, i: id, ...position.desktop }
      }
      changingLayoutRef.current.push(widget)
    }

    setTimeout(() => saveWorkspace({ dispatchChanges: true }), 10)
  }, [saveWorkspace])

  const updateWidgetParams = useCallback(({ id, params }) => {
    const indexToReplace = changingLayoutRef.current.findIndex(item => item.id === id)
    if (indexToReplace === -1 || isEqual(changingLayoutRef.current[indexToReplace]?.params, params))
      return
    changingLayoutRef.current[indexToReplace].params = params
    saveWorkspace({ dispatchChanges: false })
  }, [saveWorkspace])

  const resizeItem = useCallback(({ id, mobile = {}, tablet = {}, desktop = {} }) => {
    const indexToResize = changingLayoutRef.current.findIndex(item => item.id === id)
    if (indexToResize === -1)
      return
    changingLayoutRef.current = changingLayoutRef.current.map((x, i) => i === indexToResize ? ({
      ...x,
      desktop: { ...x.desktop, ...desktop },
      tablet: { ...x.tablet, ...tablet },
      mobile: { ...x.mobile, ...mobile },
    }) : x)
    saveWorkspace({ dispatchChanges: true })
  }, [saveWorkspace])

  return {
    changeLayout,
    removeItem,
    addItems,
    updateWidgetParams,
    resizeItem
  }
}
