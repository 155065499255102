import WidgetContextualMenu from "shared/WidgetContextualMenu"
import { Fragment, createRef, useContext, useEffect, useMemo, useState } from "react"
import { LAYOUT_BREAKPOINT_WIDTH } from "const"
import { MdOutlineTableChart, MdViewList, MdOutlineArrowDropDown, MdOutlineArrowDropUp, MdAddCircle, MdOutlineDiamond } from "react-icons/md"
import IconListUser from 'assets/icons/panel/user_lists.svg'
import IconLists from 'assets/icons/panel/lists.svg'
import QuotationPanelContext, { QuotationPanelContextProvider } from "context/QuotationPanelContext"
import AccessInterceptorContext from "context/AccessInterceptorContext"
import ContextualMenu from "shared/ContextualMenu"
import { TbTool } from "react-icons/tb"
import useTitle from "hooks/useTitle"
import useClickout from "hooks/useClickout"

// Modals
import ModalAddItems from "shared/modal/QuotationPanel/ModalAddItems"
import ModalCreatePanelList from "shared/modal/QuotationPanel/ModalCreatePanelList"
import ModalManagePanels from "shared/modal/QuotationPanel/ModalManagePanels"
import { SymbolTable } from "shared/websocketData/QuotationPanelTable"
import { SimpleTooltip } from "shared/HelpTooltip"
import { LoadingBox } from "shared/Loading"
import useWindowSize from "hooks/useWindowSize"
import Tabs from "shared/Tabs"
import { useLocalParams } from "hooks/useLocalParams"
import { usePermission } from "hooks/usePermission"
import SymbolCardList from "shared/websocketData/QuotationPanelList"
import { WidgetTitle, WidgetLinkTitle } from "shared/WidgetTitle"
import { useCustomId } from "hooks/useCustomId"
import DeniedPermissionWidget from "shared/DeniedPermissionWidget"

function useQuotationPanel() {
  const [addItemsModalIsOpen, setAddItemsModalIsOpen] = useState(false)
  const [createPanelListModalIsOpen, setCreatePanelListModalIsOpen] = useState(false)
  const [updatePanelListModalListId, setUpdatePanelListModalListId] = useState(null)
  const [managePanelsModalIsOpen, setManagePanelsModalIsOpen] = useState(false)
  const permission = usePermission('QuotationPanel')
  const contextMenuOptions = [
    { label: "Adicionar ativos", type: 'restricted', onClick: () => setAddItemsModalIsOpen(true) },
    { label: "Editar ativos", type: 'restricted', onClick: () => setUpdatePanelListModalListId("ACTIVE") },
    { label: "d1", type: "divider" },
    { label: "Criar um novo painel", onClick: () => setCreatePanelListModalIsOpen(true), blocked: !permission?.childrens?.some(({ key }) => key === "CreatePanel") },
    { label: "Gerenciar painéis", onClick: () => setManagePanelsModalIsOpen(true), blocked: !permission?.childrens?.some(({ key }) => key === "CreatePanel") },
  ]
  const createNewPanel = () => {
    setCreatePanelListModalIsOpen(true)
    setManagePanelsModalIsOpen(false)
  }

  const modals = <>
    {addItemsModalIsOpen && (<ModalAddItems closeModal={() => setAddItemsModalIsOpen(false)} />)}
    {createPanelListModalIsOpen && (<ModalCreatePanelList closeModal={() => setCreatePanelListModalIsOpen(false)} />)}
    {updatePanelListModalListId && (<ModalCreatePanelList closeModal={() => setUpdatePanelListModalListId(null)} listIdToEdit={updatePanelListModalListId} />)}
    {managePanelsModalIsOpen && (<ModalManagePanels closeModal={() => setManagePanelsModalIsOpen(false)} createNewPanel={createNewPanel} />)}
  </>

  return {
    addItemsModalIsOpen,
    createPanelListModalIsOpen,
    updatePanelListModalListId,
    managePanelsModalIsOpen,
    permission,
    contextMenuOptions,
    modals,
    createNewPanel,
    setAddItemsModalIsOpen,
    setCreatePanelListModalIsOpen,
    setUpdatePanelListModalListId,
    setManagePanelsModalIsOpen,
  }
}

export function QuotationPanelWidget(props) {
  const id = props.id
  const { width } = useWindowSize()
  const qp = useQuotationPanel()
  const widgetSize = width >= LAYOUT_BREAKPOINT_WIDTH ? props.desktop.w : 1

  return (
    <QuotationPanelContextProvider widgetProps={props} widgetSize={widgetSize}>
      <div className="bg-white h-full rounded flex flex-col p-4">
        <div className="drag-handle">
          <WidgetContextualMenu removeItem={() => props.removeItem(id)} />
          <WidgetLinkTitle title="Painel de Cotações" route="/painel-de-cotacoes" />
        </div>
        <DeniedPermissionWidget widgetSize={widgetSize} widgetType="QuotationPanel">
          <SelectList contextMenuOptions={qp.contextMenuOptions} onSelectCreateList={() => qp.setCreatePanelListModalIsOpen(true)} />
          <SymbolList createList={() => qp.setCreatePanelListModalIsOpen(true)} />
        </DeniedPermissionWidget>
      </div>
      {qp.modals}
    </QuotationPanelContextProvider>
  )
}

export function QuotationPanelPage() {
  useTitle('Painel de Cotações')

  const params = useMemo(() => ({ display: 'table' }), [])
  const props = useLocalParams('QuotationPanelPage', params)
  const { width } = useWindowSize()
  const qp = useQuotationPanel()
  const widgetSize = width >= LAYOUT_BREAKPOINT_WIDTH ? 3 : (width >= 668 ? 2 : 1)

  return (
    <QuotationPanelContextProvider widgetProps={props} widgetSize={widgetSize}>
      <div className="bg-white h-full rounded flex flex-col p-4">
        <WidgetTitle title="Painel de Cotações" />
        <SelectList contextMenuOptions={qp.contextMenuOptions} onSelectCreateList={() => qp.setCreatePanelListModalIsOpen(true)} />
        <SymbolList createList={() => qp.setCreatePanelListModalIsOpen(true)} />
      </div>
      {qp.modals}
    </QuotationPanelContextProvider>
  )
}

export function SelectList({ contextMenuOptions, onSelectCreateList }) {

  const changeViewBtnId = useCustomId('changeView')
  const managePanelsId = useCustomId('managePanels')
  const { lists, activeList, toggleDisplay, display, setTableView, viewTypes, widgetSize } = useContext(QuotationPanelContext)
  const defaultTab = useMemo(() => [{ index: 0, name: "PRINCIPAL" }], [])
  const walletPermission = usePermission('Wallet')

  const menuOptions = contextMenuOptions.map((item) => {
    if (activeList?.type !== 'QUOTATION_LIST' && item?.type === 'restricted') return { ...item, disabled: true }
    return item
  })

  useEffect(() => {
    setTableView({ index: 0, tab: defaultTab[0] })
  }, [display, setTableView, defaultTab])

  if (!lists?.length) return null

  const walletList = { ...lists.find(x => x.type === 'WALLET_LIST'), permissioned: !!walletPermission }

  const customLists = [...lists.filter(x => x.type === 'QUOTATION_LIST'), lists.find(x => x.type === 'INTEREST_LIST'), walletList]

  const defaultLists = lists.filter(x => x.type === 'DEFAULT_LIST').map(item => ({ ...item, order: item.order || 0 })).sort((x, y) => x.order > y.order ? 1 : -1)

  return (
    <div className="mb-1">
      {activeList && <div className="flex justify-between items-center relative">
        <DropDown lists={[customLists, defaultLists]} size={widgetSize} createList={onSelectCreateList} />
        {display === 'table' && widgetSize > 1 && activeList?.tabs && <TableTabs tabs={activeList?.tabs} setTableView={setTableView} />}
        <div className="flex space-x-2">
          {viewTypes.map(view => (
            <button key={`view_${view.type}`} id={changeViewBtnId} className={`btn text-xl px-3 relative flex capitalize ${view.isActive ? 'text-primary hover:bg-gray-200' : 'text-grayDisabled cursor-not-allowed'} ${display === view.type && 'hidden'}`} onClick={() => toggleDisplay(view)}>
              {view.type === 'list' ? <MdOutlineTableChart /> : <MdViewList className={!view.isActive && 'opacity-50'} />}
              <SimpleTooltip anchor={`#${changeViewBtnId}`} value={`Ver em ${display === 'table' ? 'card' : 'tabela'}`} />
            </button>
          ))}

          <ContextualMenu options={menuOptions} id={managePanelsId} btnTriggerClass="text-primary text-xl px-4 relative top-0">
            <TbTool />
          </ContextualMenu>
          <SimpleTooltip anchor={`#${managePanelsId}`} value={`Gerenciar painéis`} />

        </div>
      </div>}
      {display === 'table' && widgetSize === 1 && activeList?.tabs && <TableTabs tabs={activeList?.tabs || []} setTableView={setTableView} />}
    </div>
  )
}

function TableTabs({ tabs, setTableView }) {
  const { tableView, widgetSize } = useContext(QuotationPanelContext)

  return (
    <div className={`flex whitespace-nowrap overflow-hidden items-center ${widgetSize === 1 && 'w-full'}`}>
      <Tabs
        tabs={tabs}
        activeTabIndex={tableView?.index}
        onChange={(args) => setTableView(args)}
        parentClass={`flex justify-between ${widgetSize === 1 ? 'w-full' : 'space-x-4'}`}
      />
    </div>
  )
}

export function SymbolList({ createList }) {
  const { activeList, lists, display, loadingList } = useContext(QuotationPanelContext)
  if (lists === null || loadingList)
    return <LoadingBox />
  if (!lists.length)
    return (
      <div className="flex justify-center items-center flex-col space-y-2 h-full">
        <p>Você ainda não tem nenhum painel criado.</p>
        <button className="link" onClick={createList}>Criar o primeiro painel</button>
      </div>
    )
  if (!activeList)
    return <div className="text-gray-500">Selecione um painel</div>
  if (!activeList?.symbols?.length)
    return <span className="text-gray-500">Nenhum ativo adicionado no painel.</span>

  if (display === 'table')
    return <SymbolTable />
  else if (display === 'list')
    return <SymbolCardList />
}


function DropDown({ lists, ...props }) {
  const { selectListById, activeList, quotationPermission } = useContext(QuotationPanelContext)
  const { setIsAccessBlocked } = useContext(AccessInterceptorContext)
  const dropdownRef = createRef()
  const dropdownTriggerRef = createRef()
  const [isOpen, setIsOpen] = useState(false)
  const hasCreatePanelPermission = quotationPermission && quotationPermission.childrens?.some(({ key }) => key === "CreatePanel")
  useClickout([dropdownTriggerRef, dropdownRef], clickout => {
    if (clickout) setIsOpen(false)
  })

  const menus = {
    0: 'MEUS PAINÉIS',
    1: 'PAINÉIS PADRÃO'
  }

  function selectList(list) {
    if (list === 'CREATE_LIST') {
      props.createList()
    } else {
      selectListById(list.id)
    }
    setIsOpen(false)
  }

  return (
    activeList && <div className={`relative`}>
      <button className="flex items-center space-x-2" ref={dropdownTriggerRef} onClick={() => setIsOpen((e) => !e)}>
        <div className="flex justify-center items-center text-primary">
          <img src={activeList.type === 'DEFAULT_LIST' ? IconLists : IconListUser} alt="Ícone de painéis" />
        </div>
        <span className="w-40 text-primary text-left font-bold text-base truncate">{activeList.name}</span>
        <span className={`w-10 h-10 flex justify-center items-center bg-[#EDEDED] hover:bg-[#DBDBDB] rounded-lg pointer-events-none ${isOpen && 'border-2 border-gray-300'}`}>
          {!isOpen ? <MdOutlineArrowDropDown className="text-primary text-2xl" /> : <MdOutlineArrowDropUp className="text-primary text-2xl" />}
        </span>
      </button>
      {isOpen && (
        <div ref={dropdownRef} className={`${props.size === 1 ? 'top-24 left-2 right-2' : 'min-[300px]'} z-50 max-h-[440px] min-w-[280px] mini-scrollbar overflow-auto fixed bg-white rounded-xl border border-gray-200 drop-shadow-md`}>
          {lists.map((group, i) => {
            return (
              <Fragment key={`group_${menus[i]}`}>
                <div className="ml-4 mt-1 text-xs text-primary">{menus[i]}</div>
                <div>
                  {group.map((item) => {
                    return (
                      <div onClick={() => {
                        if (item.permissioned === false) {
                          setIsAccessBlocked(true)
                          return
                        }
                        selectList(item)
                      }} className={`${activeList.id === item.id && 'bg-[#EDEDED]'} flex m-1 space-x-2 py-2 px-4 hover:bg-[#EDEDED] hover:cursor-pointer rounded-xl`} key={`dropdown_item_list_${item.id}`}>
                        <div className="flex justify-center items-center text-primary">
                          <img src={item.type === 'DEFAULT_LIST' ? IconLists : IconListUser} alt="Ícone de painéis" />
                        </div>
                        <span className="w-full text-primary font-semibold normal-case text-ellipsis overflow-hidden">{item.name}</span>
                        {item.permissioned === false && <MdOutlineDiamond className="text-base text-terciary ml-2 inline mt-1" />}
                      </div>
                    )
                  })}
                </div>
                {
                  // Create panel button only in the first group
                  i === 0 && (
                    <div
                      onClick={() => hasCreatePanelPermission ? selectList('CREATE_LIST') : setIsAccessBlocked(true)}
                      className={`flex items-center m-1 space-x-2 py-2 px-4 text-primary ${hasCreatePanelPermission ? 'hover:bg-[#EDEDED]' : 'opacity-60'}  hover:cursor-pointer rounded-xl`}
                    >
                      <div className="flex justify-center items-center text-primary">
                        <MdAddCircle style={{ width: '16px', height: '16px' }} />
                      </div>
                      <span className="w-full text-primary font-semibold normal-case">Criar novo painel</span>
                      {!hasCreatePanelPermission && <MdOutlineDiamond className="text-base text-terciary ml-2 inline" />}
                    </div>
                  )
                }
                {lists.length - 1 !== i && <div className="w-full border border-gray-200 mb-4"></div>}
              </Fragment>
            )
          })}
        </div>
      )}
    </div>
  )
}
