import { useState } from "react"
import Loading from "shared/Loading"

function InfinityScrollContainer({ children, onScroll, loadMore, tolerance, ...props }) {

  const [showLoading, setShowLoading] = useState(false)

  const infinityScrollHandler = (e) => {
    if (typeof onScroll === 'function') onScroll(e)
    if (typeof loadMore !== 'function') return
    const { target } = e, tolerance = 250 // distance to scroll end
    if (target.scrollHeight < tolerance) return
    const isScrollAtEnd = target.offsetHeight + target.scrollTop + tolerance >= target.scrollHeight
    if (!isScrollAtEnd) return
    loadMore()
    setShowLoading(true)
    setTimeout(() => setShowLoading(false), 5000)
  }

  return (
    <div {...props} onScroll={infinityScrollHandler}>
      {children}

      {showLoading && (
        <div className="flex justify-center text-2xl opacity-20 py-2">
          <Loading size="30" />
        </div>
      )}
    </div>
  )
}

export default InfinityScrollContainer