import { useEffect } from "react"
import { useState } from "react"

export function useTimer(miliseconds) {

  const [lastUpdate, setLastUpdate] = useState(new Date())

  useEffect(() => {
    const interval = setInterval(() => {
      setLastUpdate(new Date())
    }, miliseconds)
    return () => clearInterval(interval)
  }, [miliseconds, setLastUpdate])


  return lastUpdate
}