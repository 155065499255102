import { useFormContext } from "react-hook-form"

function Checkbox({ name, label, validators = {}, children, value, disabled = false }) {
  const { register } = useFormContext()

  return (
    <label className={`checkbox-container block relative pl-6 cursor-pointer text-sm `}>
      {label}
      <input className="absolute opacity-0 cursor-pointer" type="checkbox" {...register(name, validators)} disabled={disabled} value={value}/>
      <span className={`checkbox-content left-0 top-0 w-6 h-6 absolute border-2 rounded ${disabled ? 'text-grayDisabled border-grayDisabled cursor-not-allowed' : 'border-primary cursor-pointer'}`}></span>
      <span className={`${disabled ? 'text-grayDisabled cursor-not-allowed' : 'text-dark-gray cursor-pointer'}`}>{children}</span>
    </label>
  )
}

function CheckboxBasic({ label, disabled = false, isChecked, children, value, setIsChecked, style, styleChecked }) {

  const handleChange = (event) => {
    setIsChecked(event.target.checked)
  }

  return (
    <label className={`checkbox-container block relative pl-6 cursor-pointer text-sm `}>{label}
      <input className="absolute opacity-0 cursor-pointer" type="checkbox" disabled={disabled} checked={isChecked} value={value} onChange={handleChange}/>
      <span style={style} className={`checkbox-content ${styleChecked} left-0 top-0 w-6 h-6 absolute border-2 rounded ${disabled ? 'text-grayDisabled border-grayDisabled cursor-not-allowed' : 'border-primary cursor-pointer'}`}></span>
      <span className={`${disabled ? 'text-grayDisabled cursor-not-allowed' : 'text-dark-gray cursor-pointer'}`}>{children}</span>
    </label>
  )
}

export { Checkbox, CheckboxBasic }