import FixedIncomeCalculatorContext, { FixedIncomeCalculatorContextProvider } from "context/FixedIncomeCalculatorContext"
import { useContext, useEffect, useRef, useState } from "react"
import ControlledInput from "shared/ControlledInput"
import { MdOutlineKeyboardArrowDown, MdOutlineArrowDropDown, MdOutlineArrowDropUp } from "react-icons/md"
import { FormProvider, useForm } from "react-hook-form"
import useSymbol from "hooks/useSymbol"
import { Keys, LAYOUT_BREAKPOINT_WIDTH, globalNaNRegExp } from "const"
import { formatterBRL } from "helpers/numberHelper"
import { FV } from "helpers/financialMathFn"
import useWindowSize from "hooks/useWindowSize"
import useTitle from "hooks/useTitle"
import WidgetContextualMenu from "shared/WidgetContextualMenu"
import { WidgetLinkTitle, WidgetTitle } from "shared/WidgetTitle"
import Modal from "react-modal"
import CloseButton from "shared/CloseButton"

export function FixedIncomeCalculatorWidget(props) {
    const id = props.id
    const { width } = useWindowSize()
    const widgetSize = width >= LAYOUT_BREAKPOINT_WIDTH ? props?.desktop?.w : 1

    return (
        <FixedIncomeCalculatorContextProvider>
            <div className="pt-4 px-4 drag-handle relative">
                <WidgetContextualMenu removeItem={() => props.removeItem(id)} />
                <span className="absolute top-10 text-sm">Qual investimento rende mais?</span>
                <WidgetLinkTitle title="Calculadora de Renda Fixa" route="/calculadora-renda-fixa" />
            </div>

            <div className="mt-3 h-full rounded overflow-y-auto mini-scrollbar flex flex-col ">
                {widgetSize === 1 ? (
                    <div className="p-4 ">
                        <FixedIncomeCalculator widgetSize={widgetSize} />
                    </div>
                ) : (
                    <div className={`grid gap-x-2 grid-cols-${widgetSize === 2 ? '2' : widgetSize === 3 && '3'} gap-2`}>
                        <div className={`pl-4 col-span-${widgetSize === 2 ? '1' : widgetSize === 3 && '2'}`}>
                            <FixedIncomeCalculator widgetSize={widgetSize} />
                        </div>

                        <div className={`col-span-1 p-4`}>
                            <CalculatorResult widgetSize={widgetSize} />
                        </div>
                    </div>
                )
                }
            </div>
        </FixedIncomeCalculatorContextProvider>
    )

}

export function FixedIncomeCalculatorPage() {
    useTitle('Recomendações e Análises')
    const { width } = useWindowSize()
    const isMobile = width < LAYOUT_BREAKPOINT_WIDTH

    return (
        <FixedIncomeCalculatorContextProvider>
            <div className="bg-white rounded flex flex-col h-full overflow-y-auto p-4 relative">
                <WidgetTitle title="Calculadora de Renda Fixa" />
                <span className="absolute top-10 text-sm">Qual investimento rende mais?</span>
                <div className={`mt-8 gap-2 ${!isMobile && 'grid grid-cols-3'}`}>
                    <div className={`${!isMobile && 'col-span-2'}`}>
                        <FixedIncomeCalculator />
                    </div>
                    {!isMobile && (
                        <div className="col-span-1">
                            <CalculatorResult />
                        </div>
                    )}
                </div>
            </div>
        </FixedIncomeCalculatorContextProvider>
    )

}

function FixedIncomeCalculator({ widgetSize }) {
    const methods = useForm()
    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false)
    // const [result, setResult] = useState()
    const [toggleSelect, setToggleSelect] = useState(false)
    const { errors } = methods.formState
    const { width } = useWindowSize()
    const { onSetResult, onSetResultLayout, resultPage } = useContext(FixedIncomeCalculatorContext)
    const isMobile = width < LAYOUT_BREAKPOINT_WIDTH
    const [modalParams, setModalParams] = useState({ open: null, item: null })

    const watch = methods.watch((value) => {
        for (const key of ['amount', 'monthlyApport', 'selic', 'cdi', 'ipca', 'tr', 'taxaCustTesouro', 'taxaCustB3Tesouro', 'juroIpca', 'taxaFundoDI', 'rentCDB', 'rentFundoDI', 'rentDI', 'rentLciLca', 'rentPoup']) {
            if (typeof value[key] !== 'string') continue

            if (key === 'rentPoup') value[key] = value[key]?.replace(globalNaNRegExp, '') / 10000
            else value[key] = value[key].replace(globalNaNRegExp, '') / 100
        }

        const selicRent = Math.pow(1 + (value.selic / 100), 1 / 12) - 1
        const cdiBrute = Math.pow(1 + (value.cdi / 100), 1 / 12) - 1
        const cdiRent = ((value.rentCDB / 100) * cdiBrute)
        const diRent = Math.pow(((value.cdi / 100) * (value.rentDI / 100) + 1), 1 / 12) - 1
        const lciRent = (Math.pow(1 + ((value.cdi / 100) * (value.rentLciLca / 100)), 1 / 12) - 1)
        const poupRent = (value.rentPoup / 100)
        const ipcaBrute = Math.pow(1 + (value.ipca / 100), 1 / 12) - 1
        const ipcaTreasureBruteRate = (Math.pow(1 + (value.juroIpca / 100), 1 / 12) - 1)
        const ipcaTreasureRent = (1 + ipcaTreasureBruteRate) * (1 + ipcaBrute) - 1
        const prefixedTreasureTaxe = Math.pow(1 + (value.taxaCustTesouro / 100), 1 / 12) - 1
        const months = value.period * (value.periodType === 'YEARS' ? 12 : 1)
        const totalInvested = value.amount + (months * value.monthlyApport)
        const b3CustodyBruteRate = Math.pow(1 + (value.taxaCustB3Tesouro / 100), 1 / 12) - 1
        const diFundBruteRate = Math.pow(1 + (value.taxaFundoDI / 100), 1 / 12) - 1
        const taxesAliquot = months <= 5 ? 0.225 : (months >= 6 && months <= 11 ? 0.2 : (months >= 12 && months <= 23 ? 0.175 : (months >= 24 ? 0.15 : 0)));

        const selicFinalValue = -FV(selicRent, months, value.monthlyApport, value.amount, 0)
        const cdbFinalValue = -FV(cdiRent, months, value.monthlyApport, value.amount, 0)
        const diFinalValue = -FV(diRent, months, value.monthlyApport, value.amount, 0)
        const ipcaTreasureFinalValue = -FV(ipcaTreasureRent, months, value.monthlyApport, value.amount, 0)
        const prefixedTreasureFinalValue = -FV(prefixedTreasureTaxe, months, value.monthlyApport, value.amount, 0)

        const selicOperationalCost = ((Math.pow(1 + b3CustodyBruteRate, value.period) - 1) * selicFinalValue);
        const diOperationalCost = ((Math.pow(1 + diFundBruteRate, value.period) - 1) * selicFinalValue);
        const ipcaOperationalCost = ((Math.pow(1 + b3CustodyBruteRate, value.period) - 1) * ipcaTreasureFinalValue);

        const values = [
            { label: 'Tesouro Selic', value: totalInvested + (selicFinalValue - totalInvested) + (-((selicFinalValue - totalInvested) * taxesAliquot)) - selicOperationalCost },
            { label: 'CDB', value: totalInvested + (cdbFinalValue - totalInvested) + (-((cdbFinalValue - totalInvested) * taxesAliquot)) },
            { label: 'Fundo DI', value: totalInvested + (diFinalValue - totalInvested) + (-((diFinalValue - totalInvested) * taxesAliquot)) - diOperationalCost },
            { label: 'LCI e LCA', value: -FV(lciRent, months, value.monthlyApport, value.amount, 0) },
            { label: 'Poupança', value: -FV(poupRent, months, value.monthlyApport, value.amount, 0) },
            { label: 'Inflação', value: -FV(ipcaBrute, months, value.monthlyApport, value.amount, 0) },
            { label: 'Tesouro IPCA', value: totalInvested + (ipcaTreasureFinalValue - totalInvested - ipcaOperationalCost) + (-((ipcaTreasureFinalValue - totalInvested - ipcaOperationalCost) * taxesAliquot)) },
            { label: 'Tesouro Prefixado', value: totalInvested + (prefixedTreasureFinalValue - totalInvested) + (-((prefixedTreasureFinalValue - totalInvested) * taxesAliquot)) },
        ]

        const greater = Math.max(...values.map(x => x.value))
        values.sort((a, b) => a.value < b.value ? 1 : -1)

        onSetResult({
            totalInvested,
            amount: value.amount,
            values: values.map((x, i) => {
                return { ...x, percent: x.value / greater * 100, id: ++i }
            })
        })
    })

    const onSubmit = (value) => {
        isMobile ? setModalParams({ open: true }) : onSetResultLayout(true)
    }

    function formatNum(num, points) {
        return num?.toFixed(points).toString().replace('.', ',').concat('%')
    }

    const selectRef = useRef(null)

    const SELIC = useSymbol('BRSOVER_BCBR', 1, Keys.ULTCOT)
    const BRNTN2029 = useSymbol('BRNTNBP15052029_STN', 3083, Keys.ULTCOT)
    const CDI = useSymbol('BRCDIOVER_CETI', 1, Keys.ULTCOT)
    const BRLTN2029 = useSymbol('BRLTN01012029_STN', 3083, Keys.ULTCOT)
    const IPCA = useSymbol('BRIPCA2024', 1, Keys.ULTCOT)
    const BRLTN2026 = useSymbol('BRLTN01012026_STN', 3083, Keys.ULTCOT)
    const TR = useSymbol('BRTR_BCBR', 1, Keys.ULTCOT)
    const BRNTN2035 = useSymbol('BRNTNBP15052035_STN', 3083, Keys.ULTCOT)
    const POUPN = useSymbol('BRPOUPN', 1, Keys.ULTCOT)
    const BRNTN2045 = useSymbol('BRNTNBP15052045_STN', 3083, Keys.ULTCOT)

    useEffect(() => {
        methods.reset({
            amount: '',
            monthlyApport: '',
            period: 0,
            periodType: 'MONTHS',
            taxaCustB3Tesouro: '0,20%',
            taxaFundoDI: '0,25%',
            rentCDB: '100,00%',
            rentFundoDI: '98,17%',
            rentDI: '100,00%',
            rentLciLca: '85,00%',
        })
    }, [methods])

    useEffect(() => methods.reset({ ...methods.getValues(), juroIpca: formatNum(((BRNTN2029 + BRNTN2035 + BRNTN2045) / 3), 2) }), [BRLTN2026, BRLTN2029, BRNTN2029, BRNTN2035, BRNTN2045, methods])
    useEffect(() => methods.reset({ ...methods.getValues(), taxaCustTesouro: formatNum(((BRLTN2026 + BRLTN2029) / 2), 2) }), [BRLTN2026, BRLTN2029, methods])
    useEffect(() => methods.reset({ ...methods.getValues(), selic: formatNum(SELIC, 2) }), [SELIC, methods])
    useEffect(() => methods.reset({ ...methods.getValues(), cdi: formatNum(CDI, 2) }), [CDI, methods])
    useEffect(() => methods.reset({ ...methods.getValues(), ipca: formatNum(IPCA, 2) }), [IPCA, methods])
    useEffect(() => methods.reset({ ...methods.getValues(), tr: formatNum(TR, 4) }), [TR, methods])
    useEffect(() => methods.reset({ ...methods.getValues(), rentPoup: formatNum(POUPN, 4) }), [POUPN, methods])

    const amountValidation = (val) => {
        return (
            val !== '' || "Valor não pode ser igual a zero"
        )
    }

    if (resultPage) return (
        <div className="h-full overflow-y-auto">
            <CalculatorResult goBack={true} />
        </div>
    )


    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)} className="space-y-2 flex flex-col h-auto pb-20">
                    <div className={'flex-1'}>
                        <div className="mb-2">
                            <h5 className="text-base font-semibold">Suas informações</h5>
                            <span className="text-sm">Informe o investimento inicial, os aportes mensais e o período de aplicação.</span>
                        </div>

                        <div className="grid grid-cols-2 gap-x-4 gap-y-2">
                            <div className={`col-span-${width < 1280 ? '2' : '1'}`}>
                                <ControlledInput name="amount" options={{ required: { value: true, message: 'Campo Obrigatório' }, validate: amountValidation }} label="Investimento inicial" mask="currency" placeholder="R$ 0,00" errors={errors.amount} />
                                <p className="text-red-500">{errors.amount?.message}</p>
                            </div>
                            <div className={`col-span-${width < 1280 ? '2' : '1'}`}>
                                <ControlledInput name="monthlyApport" options={{ required: { value: true, message: 'Campo Obrigatório' }, validate: amountValidation }} label="Aportes mensais" mask="currency" placeholder="R$ 0,00" errors={errors.monthlyApport} />
                                <p className="text-red-500">{errors.monthlyApport?.message}</p>
                            </div>
                        </div>
                        <div className={`grid my-1 grid-cols-${width < 1280 ? '3' : '2'} gap-${width < 1280 ? '0.5' : '4'} gap-y-2`}>
                            <label htmlFor="period" className={`label col-span-${width < 1280 ? '3' : '2'}`} >Período de aplicação</label>
                            <div className={`col-span-${width < 1280 ? '2' : '1'}`}>
                                <ControlledInput options={{ required: { value: true, message: 'Campo Obrigatório' } }} divProps={'half'} name="period" type="number" placeholder="Ex: 12" errors={errors.period} />
                                <p className="text-red-500">{errors.period?.message}</p>
                            </div>
                            <div className="relative">
                                <select {...methods.register('periodType')} onClick={() => { setToggleSelect(!toggleSelect) }} id="operator" className="text-primary font-bold cursor-pointer input">
                                    <option className="text-primary" value="MONTHS">Meses</option>
                                    <option className="text-primary" value="YEARS">Anos</option>
                                </select>
                                {
                                    toggleSelect
                                        ? <MdOutlineArrowDropUp className="cursor-pointer absolute top-3 right-3 text-xl text-primary pointer-events-none" />
                                        : <MdOutlineArrowDropDown className="cursor-pointer absolute top-3 right-3 text-xl text-primary pointer-events-none" />
                                }
                            </div>
                        </div>

                        <div className="flex items-center justify-center mt-3">
                            <button className="font-bold text-primary hover:opacity-80 flex items-center" type="button" onClick={() => setShowAdvancedOptions(s => !s)}>
                                Opções avançadas
                                <MdOutlineKeyboardArrowDown className={`text-xl ${showAdvancedOptions && 'rotate-180'}`} />
                            </button>
                        </div>
                        {
                            showAdvancedOptions && (
                                <>
                                    <div className={`${width > 1280 && 'grid gap-x-4 grid-cols-2'} gap-y-4 justify-center align-middle`}>
                                        <span className="p-1 col-span-2">Parâmetros usados na simulação. Altere-os, caso ache necessário.</span>
                                        <h3 className="font-semibold my-3 col-span-2">Valores dos índices</h3>
                                        <ControlledInput divClass={`col-span-${width < 1280 ? '2' : '1'} my-2`} name="selic" label="Selic efetiva (a.a.)" decimalPoints={2} mask="percentage" placeholder="Ex: 5%" />
                                        <ControlledInput divClass={`col-span-${width < 1280 ? '2' : '1'} my-2`} name="cdi" label="CDI (a.a.)" decimalPoints={2} mask="percentage" placeholder="Ex: 5%" />
                                        <ControlledInput divClass={`col-span-${width < 1280 ? '2' : '1'} my-2`} name="ipca" label="IPCA (a.a.)" decimalPoints={2} mask="percentage" placeholder="Ex: 5%" />
                                        <ControlledInput divClass={`col-span-${width < 1280 ? '2' : '1'} my-2`} name="tr" label="TR (a.m.)" decimalPoints={4} mask="percentage" placeholder="Ex: 5%" />
                                        <h3 className="font-semibold my-3 col-span-2">Taxa dos investimentos*</h3>
                                        <ControlledInput divClass={`col-span-${width < 1280 ? '2' : '1'} my-2`} name="taxaCustTesouro" label="Juro nominal do Tesouro Prefixado (a.a.)" decimalPoints={2} mask="percentage" />
                                        <ControlledInput divClass={`col-span-${width < 1280 ? '2' : '1'} my-2`} name="taxaCustB3Tesouro" mask="percentage" decimalPoints={2} label="Taxa de custódia da B3 no Tesouro Direto (a.a.)" />
                                        <ControlledInput divClass={`col-span-${width < 1280 ? '2' : '1'} my-2`} name="juroIpca" label="Juro real do Tesouro IPCA+ (a.a.)" decimalPoints={2} mask="percentage" />
                                        <ControlledInput divClass={`col-span-${width < 1280 ? '2' : '1'} my-2`} name="taxaFundoDI" label="Taxa de administração do Fundo DI (a.a.)" decimalPoints={2} mask="percentage" />

                                        <div className={`col-span-${(width < 1280 || widgetSize <= 2) ? '2' : '1'} grid grid-cols-${(width < 1280 || widgetSize <= 2) ? '3' : '2'} gap-0.5`}>
                                            <label className={`label col-span-${(width < 1280 || widgetSize <= 2) ? '2' : '3'}`}>Rentabilidade do CDB</label>
                                            <ControlledInput divClass={`col-span-${(width < 1280 || widgetSize <= 2) ? '2' : '1'}`} name="rentCDB" decimalPoints={2} mask="percentage" />
                                            <div className="relative">
                                                <select
                                                    ref={selectRef}
                                                    {...methods.register('operationCDB')}
                                                    className={`col-span-1 w-full px-1 rounded-md bg-gray-100 h-11 font-bold text-primary`}
                                                >
                                                    <option value="cdiPercentage">% do CDI</option>
                                                    <option value="prefixed">Prefixado</option>
                                                    <option value="ipcaPlus">IPCA+</option>
                                                </select>
                                            </div>
                                        </div>

                                        <ControlledInput divClass={`col-span-${(width < 1280 || widgetSize <= 2) ? '2' : '1'} my-2`} name="rentDI" label="Rentabilidade do Fundo DI (% do CDI)" decimalPoints={2} mask="percentage" />

                                        <div className={`col-span-${(width < 1280 || widgetSize <= 2) ? '2' : '1'} grid grid-cols-${(width < 1280 || widgetSize <= 2) ? '3' : '2'} gap-0.5`}>
                                            <label className={`label col-span-${(width < 1280 || widgetSize <= 2) ? '2' : '3'}`}>Rentabilidade da LCI/LCA</label>
                                            <ControlledInput divClass={`col-span-${(width < 1280 || widgetSize <= 2) ? '2' : '1'}`} name="rentLciLca" decimalPoints={2} mask="percentage" />
                                            <div className="relative">
                                                <select {...methods.register('operationLCI')} className={`col-span-1 w-full px-1 rounded-md bg-gray-100 h-11 font-bold text-primary`} >
                                                    <option value="cdiPercentage">% do CDI</option>
                                                    <option value="prefixed">Prefixado</option>
                                                    <option value="ipcaPlus">IPCA+</option>
                                                </select>
                                            </div>
                                        </div>

                                        <ControlledInput divClass={`col-span-${(width < 1280 || widgetSize <= 2) ? '2' : '1'} mt-2 mb-4`} name="rentPoup" label="Rentabilidade da Poupança (a.m.)" decimalPoints={4} mask="percentage" />
                                        <span onClick={() => { methods.reset() }} className="text-primary underline cursor-pointer">RESTAURAR PADRÃO</span>
                                    </div>
                                </>
                            )
                        }
                        <div className={`col-span-2 mt-6 text-center`}>
                            {(widgetSize === 1 || isMobile) && <button className="btn btn-primary text-secondary" type="submit">Calcular</button>}
                        </div>
                    </div>
                </form>
            </FormProvider>
            {modalParams.open && <ResultModal closeModal={() => setModalParams({ open: null, item: null })} />}
        </>
    )
}

function CalculatorResult({ goBack }) {
    const { result, onSetResultLayout } = useContext(FixedIncomeCalculatorContext)

    return (
        <div className="flex flex-col space-y-4 pb-4 mb-10">
            <p className="font-semibold">Resultado:</p>
            <h3 className="font-bold text-lg">Total investido: <span className="text-secondary text-2xl">{formatterBRL.format(result.totalInvested)}</span></h3>
            <div className="rounded-md flex shadow-md flex-col p-6 flex-grow bg-gray-200 space-y-4 ">
                <h4 className="text-base text-primary font-bold uppercase">Veja qual investimento rende mais*:</h4>
                <div className="grid grid-cols-3 items-center align-middle">
                    <span className="col-span-1 font-bold my-2 uppercase text-xs">investimento</span>
                    <span className="col-span-2 font-bold my-2 uppercase text-xs">valor liquido resgate</span>
                    {
                        result?.values?.map((item) => (
                            <>
                                <div key={`item-${item.label}-${item.percent}`} className="col-span-1 font-bold my-[2px] text-primary text-sm">{item.label}</div>
                                <div key={`item-${item.label}-${item.id}`} className="col-span-2 my-[2px]">
                                    <div className="bg-primary rounded p-2 flex justify-end text-white font-semibold text-sm" style={{ width: item.percent + '%' }}>{formatterBRL.format(item.value)}</div>
                                </div>
                            </>
                        ))
                    }
                </div>
                <p className="text-gray-600 text-justify text-sm">As simulações tem como base taxa de juros e inflação constante ao longo do tempo, a regra da "poupança nova" e consideram as regras atuais de tributação de renda fixa e a taxa de custódia cobrada pela B3 para o Tesouro Direto. A estimativa da Selic efetiva e CDI efetivo difere da meta Selic.</p>
            </div>
            <div className="flex justify-center">
                {goBack && <button className="btn btn-primary w-32 text-secondary" onClick={() => { onSetResultLayout(false) }}>Voltar</button>}
            </div>
        </div>
    )
}

function ResultModal({ isOpen = true, closeModal }) {

    const { width } = useWindowSize()
    const isMobile = width < LAYOUT_BREAKPOINT_WIDTH

    return (
        <Modal isOpen={isOpen} onRequestClose={closeModal} style={{
            content:
            {
                maxWidth: isMobile ? width : 648,
                margin: "auto",
                marginRight: isMobile ? -40 : "auto",
                marginLeft: isMobile ? -40 : "auto",
            }
        }} shouldCloseOnEsc={true}>
            <CloseButton onClick={closeModal} />
            <CalculatorResult />
        </Modal>
    )
}