import { useContext, useMemo, useState } from "react"
import { MdOutlinePriceChange, MdOutlineNewspaper, MdAdd, MdOutlineEdit, MdOutlineDelete } from "react-icons/md"
import Card from "shared/Card"
import Toggle from "shared/ToggleButton"
import ModalAlertQuoteForm from "shared/modal/Alerts/ModalAlertQuoteForm"
import ModalAlertNewsForm from "shared/modal/Alerts/ModalAlertNewsForm"
import AlertsContext, { AlertsContextProvider } from "context/AlertsContext"
import { warningAlert } from "shared/Swal"
import useTitle from "hooks/useTitle"
import { convertToInternationalCurrencySystem, format } from "helpers/numberHelper"
import { toast } from "react-toastify"

function Alerts() {

  useTitle('Alertas')
  const tabs = [
    { name: "Central de alertas", Component: AlertsCentral },
    // { name: "Feed de notificações", Component: NotificationFeed }
  ]
  const [activeTab, setActiveTab] = useState(tabs[0])

  return (
    <AlertsContextProvider>
      <section className="p-4 px-1">
        <Card >
          <h1 className="text-lg text-primary font-bold uppercase mb-4">Alertas</h1>
          {/* <Tabs tabs={tabs} parentClass="space-x-4 my-4" onChange={({ tab }) => setActiveTab(tab)} tabClass="text-uppercase font-semibold text-lg" /> */}
          {<activeTab.Component />}
        </Card>
      </ section>
    </AlertsContextProvider>
  )
}

function AlertsCentral() {
  const [modalParams, setModalParams] = useState({ open: null, item: null })

  return (
    <>
      <div className="flex items-center mb-2">
        <div className="bg-profit text-profit bg-opacity-30 p-2 rounded-lg mr-2">
          <MdOutlinePriceChange className="text-2xl" />
        </div>
        <h4 className="font-bold">Alertas de Cotação</h4>
        <button className="text-uppercase space-x-2 flex items-center ml-8" onClick={() => setModalParams({ open: 'QUOTE', item: null })}>
          <div className="bg-primary text-secondary rounded-full w-5 h-5 font-semibold flex items-center justify-center">
            <MdAdd />
          </div>
          <span className="font-semibold text-primary uppercase">Criar Alerta</span>
        </button>
      </div>
      <AlertTable type="QUOTE" openEditModal={(open, item) => setModalParams({ open, item })} />

      <div className="flex items-center mb-2">
        <div className="bg-primary text-primary bg-opacity-30 p-2 rounded-lg mr-2">
          <MdOutlineNewspaper className="text-2xl" />
        </div>
        <h4 className="font-bold">Alertas de Notícia</h4>
        <button className="text-uppercase space-x-2 flex items-center ml-8" onClick={() => setModalParams({ open: 'NEWS', item: null })}>
          <div className="bg-primary text-secondary rounded-full w-5 h-5 font-semibold flex items-center justify-center">
            <MdAdd />
          </div>
          <span className="font-semibold text-primary uppercase">Criar Alerta</span>
        </button>
      </div>
      <AlertTable type="NEWS" openEditModal={(open, item) => setModalParams({ open, item })} />
      {modalParams.open === 'QUOTE' && <ModalAlertQuoteForm closeModal={() => setModalParams({ open: null, item: null })} editItem={modalParams.item} />}
      {modalParams.open === 'NEWS' && <ModalAlertNewsForm closeModal={() => setModalParams({ open: null, item: null })} editItem={modalParams.item} />}
    </>
  )
}

function AlertTable({ type, openEditModal }) {
  const { alertsQuoteList, alertsNewsList, updateQuotationAlert, removeQuotationAlert, updateNewsAlert, removeNewsAlert } = useContext(AlertsContext)

  const criterias = useMemo(() => ({
    price: { label: 'preço', format: x => `R$ ${format(x, 'currency')}` },
    var_percent: { label: 'variação no dia em %', format: x => format(x, 'percent') },
    var_pts: { label: 'variação no dia em pontos', format: x => x },
    max: { label: 'máxima', format: x => `R$ ${format(x, 'currency')}` },
    min: { label: 'mínima', format: x => `R$ ${format(x, 'currency')}` },
    vol: { label: 'volume financeiro', format: x => convertToInternationalCurrencySystem(x) },
    num_neg: { label: 'número de negócios', format: x => convertToInternationalCurrencySystem(x) },
  }), [])

  const operators = useMemo(() => ({
    '=': 'igual a',
    '<>': 'diferente de',
    '>': 'maior que',
    '<': 'menor que',
    '>=': 'maior ou igual a',
    '<=': 'menor ou igual a'
  }), [])

  const getCategory = (category) => {
    switch (category) {
      case 'keyword':
        return 'Palavra(s) chave:'
      case 'company':
        return 'Empresa(s):'
      default:
        return ''
    }
  }

  async function toggleStatus(type, activated, item) {
    const param = { id: item.id, activated }
    try {
      if (type === 'NEWS') {
        await updateNewsAlert(param)
      } else if (type === 'QUOTE') {
        await updateQuotationAlert(param)
      }
      toast.success(`O alerta ${item.name} agora está ${!activated ? 'inativo' : 'ativo'}!`)
      return !activated
    } catch (error) {
      toast.error(`Não foi possível alterar o status do alerta ${item.name}.`)
    }

  }

  async function remove(type, alert) {
    const { isConfirmed } = await warningAlert.fire('Tem certeza?', 'Não é possível restaurar um alerta apagado!')
    if (!isConfirmed) return

    if (type === 'NEWS') {
      await removeNewsAlert([alert.id])
    } else if (type === 'QUOTE') {
      await removeQuotationAlert([alert.id])
    }
  }

  function getItemTag(value) {
    return <span key={'itemTag_' + value} className="px-2 py-1 rounded-lg bg-gray-100 mx-1 mb-2 text-sm font-semibold">{value}</span>
  }

  return (
    <div className="overflow-x-auto">
      <table className="border mb-12">
        <thead>
          <tr className="border-b text-sm">
            <th className="text-left p-4 border-r lg:w-80">Nome</th>
            <th className="text-left p-4 border-r w-[auto]">Critério</th>
            <th className="p-4 border-r md:w-40">Status</th>
            <th className="p-4 md:w-40">Ações</th>
          </tr>
        </thead>
        <tbody>
          {type === 'QUOTE' && alertsQuoteList?.map(item => (
            <tr key={`id_${item.id}`}>
              <td className="px-4 py-2 font-semibold">{item?.name}</td>
              <td className="px-4 py-2">
                {`
                ${item.symbol} - 
                ${criterias[item.criterion].label}
                ${operators[item.operator] || ''}
                ${criterias[item.criterion].format(item.price)} 
              `}
              </td>
              <td className="px-4 py-2">
                <div className="flex justify-center">
                  <Toggle isActive={item.activated} onChange={(value) => toggleStatus(type, value, item)} />
                </div>
              </td>
              <td className="px-4 py-2">
                <div className="flex justify-evenly">
                  <button onClick={() => openEditModal(type, item)}><MdOutlineEdit className="text-primary text-2xl" title="Editar" /></button>
                  <button onClick={() => remove(type, item)}><MdOutlineDelete className="text-loss text-2xl" title="Excluir" /></button>
                </div>
              </td>
            </tr>
          ))}
          {type === 'NEWS' && alertsNewsList && alertsNewsList.map(item => (
            <tr key={`id_${item.id}`}>
              <td className="px-4 py-2 font-semibold">{item.name}</td>
              <td className="px-4 py-2">{`${getCategory(item.category)}`}&nbsp;{item.terms.map(x => getItemTag(x))}</td>
              <td className="px-4 py-2">
                <div className="flex justify-center">
                  <Toggle isActive={item.activated} onChange={(value) => toggleStatus(type, value, item)} />
                </div>
              </td>
              <td className="px-4 py-2">
                <div className="flex justify-evenly">
                  <button onClick={() => openEditModal(type, item)} ><MdOutlineEdit className="text-primary text-2xl" title="Editar" /></button>
                  <button onClick={() => remove(type, item)} ><MdOutlineDelete className="text-loss text-2xl" title="Excluir" /></button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

// function NotificationFeed() {

//   const methods = useForm()
//   const typeFilter = [
//     { label: 'Todos', name: 'type_all' },
//     { label: 'Cotação', name: 'type_quote' },
//     { label: 'Notícia', name: 'type_news' },
//     { label: 'Avisos do Valor One', name: 'type_valorOne' }
//   ]

//   useEffect(() => {
//     methods.reset({
//       type_all: true
//     })
//   }, [methods])

//   return (
//     <FormProvider {...methods}>
//       <div className="grid grid-cols-3">
//         <div className="col-span-1">
//           <label htmlFor="" className="label"></label>
//           <div className="flex flex-col space-y-1">
//             {typeFilter.map(option => <Checkbox key={option.name} {...option} />)}
//           </div>
//         </div>
//         <div className="col-span-2"></div>
//       </div>
//     </FormProvider>
//   )
// }

export default Alerts