import SimulatorContext, { SimulatorContextProvider, SelectedOptionEnum } from "context/SimulatorContext"
import WidgetContextualMenu from "shared/WidgetContextualMenu"
import IcoGoal from "assets/icons/simulator/ico-goal.svg"
import IcoGoalTime from "assets/icons/simulator/ico-goal-time.svg"
import IcoRest from "assets/icons/simulator/ico-rest.svg"
import { createRef, useContext, useEffect, useState } from "react"
import ControlledInput from "shared/ControlledInput"
import { MdKeyboardArrowDown, MdOutlineArrowDropDown, MdOutlineHome, MdOutlineDirectionsCar, MdOutlineLuggage, MdOutlineSavings, MdMoney, MdMoreHoriz, MdOutlineArrowDropUp } from "react-icons/md"
import { FormProvider, useForm } from "react-hook-form"
import { LAYOUT_BREAKPOINT_WIDTH_MOBILE, globalNaNRegExp } from "const"
import { formatterBRL } from "helpers/numberHelper"
import Card from "shared/Card"
import useClickout from "hooks/useClickout"
import { NPER, PMT, PV } from "helpers/financialMathFn"
import { usePermission } from "hooks/usePermission"
import { WidgetTitle, WidgetLinkTitle } from "shared/WidgetTitle"
import useTitle from "hooks/useTitle"
import DeniedPermissionWidget from "shared/DeniedPermissionWidget"
import useMaxWindowSize from "hooks/useMaxWindowSize"

const options = [
  {
    id: SelectedOptionEnum.GOAL_VALUE_CALCULATOR,
    ico: IcoGoal,
    label: "Quanto devo investir por mês para chegar à minha meta?",
    Component: GoalValueCalculator,
    key: 'GoalValueCalculator',
    isActive: false
  },
  {
    id: SelectedOptionEnum.GOAL_TIME_CALCULATOR,
    ico: IcoGoalTime,
    label: "Quanto tempo vou levar para chegar à minha meta?",
    Component: GoalTimeCalculator,
    key: 'GoalTimeCalculator',
    isActive: false
  },
  {
    id: SelectedOptionEnum.INCOME_CALCULATOR,
    ico: IcoRest,
    label: "Quanto tenho que guardar para viver de renda?",
    Component: IncomeCalculator,
    key: 'IncomeCalculator',
    isActive: false
  },
]

const goalOptions = [
  { label: 'QUAL SUA META?', icon: null, disabled: true, valid: false },
  { label: 'Comprar um carro', icon: <MdOutlineDirectionsCar />, valid: true },
  { label: 'Comprar uma casa ou apartamento', icon: <MdOutlineHome />, valid: true },
  { label: 'Fazer uma viagem', icon: <MdOutlineLuggage />, valid: true },
  { label: 'Ter uma reserva de emergência', icon: <MdOutlineSavings />, valid: true },
  { label: 'Chegar ao meu primeiro milhão', icon: <MdMoney />, valid: true },
  { label: 'Outra meta', icon: <MdMoreHoriz />, valid: true }
]

export function SimulatorPage() {
  useTitle('Simulador')

  const permission = usePermission('Simulator')

  options.forEach((option) => {
    const foundPermission = permission?.childrens?.find((permission) => permission.key === option.key)
    if (foundPermission) {
      option.isActive = true
    }
  })

  return (
    <SimulatorContextProvider>
      <div className="bg-white rounded flex flex-col h-full pb-20">
        <div className="pt-4 px-4 mb-2">
          <WidgetTitle title="Simuladores" />
          <span className="mb-2">O que você quer calcular?</span>
        </div>
        <div className={`space-y-2 pb-4 px-4 flex flex-col h-full`}>
          <SelectOptionList />
        </div>
      </div>
    </SimulatorContextProvider>
  )
}


export function SimulatorWidget(props) {
  const { isFirstDisplay } = useContext(SimulatorContext)
  const id = props.id
  const permission = usePermission('Simulator')
  
  const isMobile = useMaxWindowSize(LAYOUT_BREAKPOINT_WIDTH_MOBILE)
  const widgetSize = props[isMobile ? 'mobile' : 'desktop'].w

  options.forEach((option) => {
    const foundPermission = permission?.childrens?.find((permission) => permission.key === option.key)
    if (foundPermission) {
      option.isActive = true
    }
  })

  return (
    <SimulatorContextProvider>
      <div className={`bg-white rounded flex flex-col h-[calc(100%-70px)]`}>
        <WidgetContextualMenu removeItem={() => props.removeItem(id)} />
        <div className="drag-handle pt-4 px-4">
          <WidgetLinkTitle title="Simuladores" route="/simulador" />
        </div>
        <DeniedPermissionWidget widgetSize={widgetSize} widgetType="Simulator">
          <span className="mb-2 px-4">O que você quer calcular?</span>
          <div className={`space-y-2 pb-4 px-4 flex flex-col ${isFirstDisplay && 'h-full'}`}>
            <SelectOptionList />
          </div>
        </DeniedPermissionWidget>
      </div>
    </SimulatorContextProvider>
  )
}

function SelectOptionList() {
  const { onSelectOption, selectedOption, onSetDisplay } = useContext(SimulatorContext)
  const found = options.find(x => x.id === selectedOption)
  if (found) return <found.Component />

  return (
    <>
      {options.map((option) => (
        <SelectOptionListItem
          option={option}
          onClick={() => {
            if (!option.isActive) return
            onSelectOption(option.id)
            onSetDisplay()
          }}
          key={option.label}
        />
      ))}
    </>
  )
}

function SelectOption() {
  const { onSelectOption, selectedOption } = useContext(SimulatorContext)
  const [isOpen, setIsOpen] = useState(false)
  const refParent = createRef()
  useClickout([refParent], (clickout) => clickout && setIsOpen(false))
  const found = options.find(x => x.id === selectedOption)

  return (
    <div className="w-full relative mb-4">
      <div
        className="flex items-center"
        ref={refParent}
        onClick={() => { setIsOpen(!isOpen) }}
      >
        <SelectOptionListItem className="flex-1" option={found} />
        <MdKeyboardArrowDown
          className={`z-10 text-secondary text-2xl absolute right-3 cursor-pointer ${isOpen && "rotate-180"}`}
        />
      </div>
      {isOpen && (
        <div className="absolute z-50 w-full">
          {options
            .filter((x) => x.label !== found.label)
            .map((option) => (
              <SelectOptionListItem
                option={option}
                onClick={() => {
                  if (!option.isActive) return
                  onSelectOption(option.id)
                }}
                key={option.label}
              />
            ))}
        </div>
      )}
    </div>
  )
}

function GoalValueCalculator() {
  const [result, setResult] = useState(null)
  const methods = useForm()
  const [toggleSelect, setToggleSelect] = useState(false)
  const { errors } = methods.formState
  const [selectedData, setSelectedData] = useState(goalOptions[0])
  const [isDropDownOpen, setIsDropDownOpen] = useState()
  const [isValid, setIsValid] = useState(true)

  const clear = () => {
    setResult(null)
  }

  useEffect(() => {
    methods.reset()
  }, [methods])

  const onSubmit = (val) => {
    if (!selectedData.valid) {
      setIsValid(false)
      return
    }
    for (const key of ['amount', 'amountSaved', 'yearProfitability']) {
      if (typeof val[key] !== 'string') continue
      val[key] = val[key].replace(globalNaNRegExp, '') / 100
    }

    const months = val.timeNominal * (val.timeType === 'YEARS' ? 12 : 1)
    const CONVERSION = Math.pow(((val.yearProfitability / 100) + 1), (1 / 12)) - 1
    const res = -PMT(CONVERSION, months, -val.amountSaved, val.amount)

    setResult(res)
    setIsValid(true)
  }

  if (result) {
    return (
      <div className="flex flex-col space-y-4">
        <p className="font-semibold">Resultado:</p>
        <Card className="bg-gray-200 space-y-4 py-8 shadow-none">
          <h4 className="text-xl text-primary font-bold uppercase">Você precisará investir</h4>
          <p className="space-x-2">
            <span className="text-4xl font-bold text-secondary">{formatterBRL.format(result)}</span>
            <span className="font-bold text-lg">por mês</span>
          </p>
          <p className="text-gray-600 text-justify">As simulações não consideram tributos ou custos envolvidos nas aplicações e têm como base taxa de juros e inflação ao longo do tempo.</p>
        </Card>
        <button className="font-semibold text-gray-500 hover:text-gray-600" onClick={clear}>Voltar</button>
      </div>)
  }

  return (
    <div className="overflow-y pb-14 overflow-y-scroll">
      <SelectOption />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>

          <div
            className={`text-primary relative cursor-pointer h-10 input ${!isValid && 'border-2 border-red-500'}`}
            onClick={() => {
              setIsDropDownOpen(!isDropDownOpen)
            }}
          >
            <GoalSelectorItem item={selectedData} />
            <MdOutlineArrowDropDown className="text-primary absolute right-2 text-2xl bottom-2" />
          </div>
          {
            isDropDownOpen && (
              <div className="w-full h-50 p-1 bg-gray-100 rounded-md absolute z-50">
                {goalOptions.map(option => {
                  if (option.disabled) return null
                  return (
                    <div
                      key={option.label}
                      className="text-primary h-10 p-2 text-center cursor-pointer hover:bg-gray-300"
                      onClick={() => {
                        if (option.disabled) return
                        setSelectedData(option)
                        setIsDropDownOpen(!isDropDownOpen)
                      }}
                    >
                      <GoalSelectorItem item={option} />
                    </div>
                  )
                })}
              </div>
            )
          }
          {!isValid && <p className="text-red-500">Campo Obrigatório</p>}

          <ControlledInput
            name="amount"
            label="De quanto você precisa para chegar a essa meta?*"
            options={{ required: { value: true, message: 'Campo Obrigatório' }, validate: amountValidation }}
            mask="currency"
            placeholder="R$ 0,00"
            errors={errors.amount}
          />
          <p className="text-red-500">{errors.amount?.message}</p>

          <ControlledInput name="amountSaved"
            label="Quanto você já conseguiu guardar?"
            mask="currency"
            placeholder="R$ 0,00"
            errors={errors.amountSaved}
          />
          <p className="text-red-500">{errors.amountSaved?.message}</p>

          <label className="label">Por quanto tempo pretende investir?*</label>
          <div className="grid grid-cols-2 gap-1">
            <ControlledInput
              options={{ required: { value: true, message: 'Campo Obrigatório' } }}
              name="timeNominal"
              type="number"
              placeholder="Ex: 12"
              errors={errors.timeNominal}
            />

            <div className="relative">
              <select {...methods.register('timeType')} onClick={() => setToggleSelect(!toggleSelect)} id="operator" className="text-primary font-bold cursor-pointer input">
                <option className="text-primary" value="MONTHS">Meses</option>
                <option className="text-primary" value="YEARS">Anos</option>
              </select>
              {
                toggleSelect
                  ? <MdOutlineArrowDropUp className="cursor-pointer absolute top-3 right-3 text-xl text-primary pointer-events-none" />
                  : <MdOutlineArrowDropDown className="cursor-pointer absolute top-3 right-3 text-xl text-primary pointer-events-none" />
              }
            </div>


          </div>
          <p className="text-red-500">{errors.timeNominal?.message}</p>
          <ControlledInput name="yearProfitability"
            options={{ required: { value: true, message: 'Campo Obrigatório' } }}
            label="Qual a rentabilidade ao ano esperada do investimento?*"
            decimalPoints={2}
            placeholder="Ex: 12%"
            mask="percentage"
            errors={errors.yearProfitability} />
          <p className="text-red-500">{errors.yearProfitability?.message}</p>

          <span>*Campos de preenchimento obrigatório.</span>

          <div className="text-center mt-2">
            <button onClick={() => {
              if (!selectedData.valid) {
                setIsValid(false)
              }
            }} className="btn btn-primary text-secondary" type="submit">Calcular</button>
          </div>
        </form>
      </FormProvider>
    </div>
  )

}

function GoalTimeCalculator() {
  const [result, setResult] = useState(null)
  const methods = useForm()
  const { errors } = methods.formState
  const [selectedData, setSelectedData] = useState(goalOptions[0])
  const [isDropDownOpen, setIsDropDownOpen] = useState()
  const [isValid, setIsValid] = useState(true)

  useEffect(() => {
    methods.reset()
  }, [methods])

  const onSubmit = (val) => {
    if (!selectedData.valid) {
      setIsValid(false)
      return
    }
    for (const key of ["amount", "amountSaved", "monthlyAmount", "yearProfitability"]) {
      if (typeof val[key] !== "string") continue
      val[key] = val[key].replace(globalNaNRegExp, "") / 100
    }

    const CONVERSION = Math.pow((val.yearProfitability / 100) + 1, 1 / 12) - 1
    const res = NPER(CONVERSION, val.monthlyAmount, val.amountSaved, -val.amount)
    setResult(Math.round(res))
    setIsValid(true)
  }

  const clear = () => {
    // methods.reset()
    setResult(null)
  }

  if (result) {
    return (
      <div className="flex flex-col space-y-4">
        <p className="font-semibold">Resultado:</p>
        <Card className="bg-gray-200 space-y-4 py-8 shadow-none">
          <h4 className="text-xl text-primary font-bold uppercase">
            Você vai levar
          </h4>
          <p className="space-x-2">
            <span className="text-4xl font-bold text-secondary">
              {result}
            </span>
            <span className="font-bold text-lg">meses</span>
          </p>
          <p className="text-gray-600 text-justify">
            As simulações não consideram tributos ou custos envolvidos nas
            aplicações e têm como base taxa de juros e inflação ao longo do
            tempo.
          </p>
        </Card>
        <button
          className="font-semibold text-gray-500 hover:text-gray-600"
          onClick={clear}
        >
          Voltar
        </button>
      </div>)
  }

  return (
    <>
      <SelectOption />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>

          <div
            className={`text-primary relative cursor-pointer h-10 input ${!isValid && 'border-2 border-red-500'}`}
            onClick={() => {
              setIsDropDownOpen(!isDropDownOpen)
            }}
          >
            <GoalSelectorItem item={selectedData} />
            <MdOutlineArrowDropDown className="text-primary absolute right-2 text-2xl bottom-2" />
          </div>
          {
            isDropDownOpen && (
              <div className="w-full h-50 p-1 bg-gray-100 rounded-md absolute z-50">
                {goalOptions.map(option => {
                  if (option.disabled) return null
                  return (
                    <div
                      key={option.label}
                      className="text-primary h-10 p-2 text-center cursor-pointer hover:bg-gray-300"
                      onClick={() => {
                        if (option.disabled) return
                        setSelectedData(option)
                        setIsDropDownOpen(!isDropDownOpen)
                      }}
                    >
                      <GoalSelectorItem item={option} />
                    </div>
                  )
                })}
              </div>
            )
          }
          {!isValid && <p className="text-red-500">Campo Obrigatório</p>}
          <ControlledInput
            options={{ required: { value: true, message: 'Campo Obrigatório' }, validate: amountValidation }}
            name="amount"
            label="De quanto você precisa para chegar a essa meta?*"
            mask="currency"
            placeholder="R$ 0,00"
            errors={errors.amount}
          />
          <p className="text-red-500">{errors?.amount?.message}</p>
          <ControlledInput
            name="amountSaved"
            label="Quanto você já conseguiu guardar?"
            mask="currency"
            placeholder="R$ 0,00"
          />
          <ControlledInput
            options={{ required: { value: true, message: 'Campo Obrigatório' }, validate: amountValidation }}
            name="monthlyAmount"
            label="Quanto você pode guardar por mês?*"
            mask="currency"
            placeholder="R$ 0,00"
            errors={errors.monthlyAmount}
          />
          <p className="text-red-500">{errors?.monthlyAmount?.message}</p>
          <ControlledInput
            options={{ required: { value: true, message: 'Campo Obrigatório' } }}
            mask="percentage"
            decimalPoints={2}
            name="yearProfitability"
            label="Qual a rentabilidade ao ano esperada do investimento?*"
            placeholder="Ex: 12%"
            errors={errors.yearProfitability}
          />
          <p className="text-red-500">{errors?.yearProfitability?.message}</p>
          <span>*Campos de preenchimento obrigatório.</span>

          <div className="text-center mt-2">
            <button onClick={() => {
              if (!selectedData.valid) {
                setIsValid(false)
              }
            }} className="btn btn-primary text-secondary" type="submit">
              Calcular
            </button>
          </div>
        </form>
      </FormProvider>
    </>
  )

}

function IncomeCalculator() {

  const [result, setResult] = useState(null)
  const methods = useForm()
  const [toggleSelect, setToggleSelect] = useState(false)
  const { errors } = methods.formState

  useEffect(() => {
    methods.reset({
      amount: '',
      amountSaved: '',
      yearToStart: '',
      monthlyApport: '',
      period: null,
      periodType: 'YEARS',
      yearProfitability: '',
      yearTaxes: ''
    })
  }, [methods])

  const onSubmit = (val) => {
    for (const key of ['amount', 'yearProfitability', 'yearTaxes', 'amountSaved']) {
      if (typeof val[key] !== 'string') continue
      val[key] = val[key].replace(globalNaNRegExp, '') / 100
    }

    const months = val.period * (val.periodType === 'YEARS' ? 12 : 1)
    const actualYear = (new Date()).getFullYear()
    const accumulationPeriod = (val.yearToStart - actualYear) * 12
    const ip = (4 / 100) //inflação anual de longo prazo projetada
    const rnbp = (((val.yearProfitability / 100) + 1) * (1 + ip)) - 1 //rendimento nominal bruto projetado
    const rnli = ((1 - (val.yearTaxes / 100)) * rnbp) //rentabilidade nominal liquida de IR
    const rrli = (((rnli) + 1) / (1 + ip) - 1)  // rentabilidade real liquida de IR
    const trlm = (Math.pow(rrli + 1, (1 / 12)) - 1) //taxa real liquida mensal
    const accumulatedAmount = -PV(trlm, months, val.amount, 0)
    const monthlyApplicationAmount = -PMT(trlm, accumulationPeriod, -val.amountSaved, accumulatedAmount)
    const finalReceivingYear = +val.yearToStart + (months / 12)

    const newResult = {
      finalReceivingYear,
      wishedAmount: val.amount,
      receivingYear: val.yearToStart,
      monthlyApplicationAmount: (Math.round(monthlyApplicationAmount * 100) / 100),
      accumulatedAmount: (Math.round(accumulatedAmount * 100) / 100),
    }

    setResult(newResult)
  }

  const clear = () => {
    setResult(null)
  }

  const CurrencyPipe = ({ value }) => {
    const formattedValue = value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    })
    return <span>{formattedValue}</span>
  }

  const yearValidation = (val) => {
    return (
      val >= ((new Date()).getFullYear() + 1) || "Insira um ano válido"
    )
  }

  const amountValidation = (val) => {
    return (
      val !== "R$ 0,00" || "Valor não pode ser igual a zero"
    )
  }

  if (result) {
    return (
      <div className="flex flex-col space-y-4">
        <p className="font-semibold">Resultado:</p>
        <div className="bg-gray-200 space-y-4 py-8 rounded-md flex shadow-none flex-col p-4 flex-grow">
          <h4 className="text-xl text-primary font-bold uppercase">
            {"PARA VIVER COM RENDA MENSAL DE "}
            <span className="text-xl font-bold text-result">
              <CurrencyPipe value={result.wishedAmount} />
            </span>
            {' A PARTIR DE '}
            <span className="text-xl font-bold text-result"> {result.receivingYear} </span>
            {" SERÁ NECESSÁRIO INVESTIR "}
            <span className="text-xl font-bold text-result">
              <CurrencyPipe value={result.monthlyApplicationAmount} />
            </span>
            {' POR MÊS, ACUMULANDO '}
            <span className="text-xl font-bold text-result">
              <CurrencyPipe value={result.accumulatedAmount} />
            </span>
            {' ATÉ '}
            <span className="text-xl font-bold text-result"> {result.receivingYear} </span>
            {' E CONSUMINDO ESSES RECURSOS ATÉ '}
            <span className="text-xl font-bold text-result"> {result.finalReceivingYear}</span>
            {'.'}
          </h4>
          <p className="text-gray-600 text-justify">
            As simulações não consideram custos envolvidos nas aplicações e os tributos incindindo
            apenas sobre o rendimento nominal, com inflação projetada de 4% ao ano, mas não sobre
            o principal. Foi usada a mesma taxa de juro real para o período de acumulação e usufruto.
          </p>
        </div>
        <button
          className="font-semibold text-gray-500 hover:text-gray-600"
          onClick={clear}
        >
          Voltar
        </button>
      </div>)
  }

  return (
    <>
      <SelectOption />
      <FormProvider {...methods}>
        <form className="h-full overflow-y-scroll mini-scrollbar" onSubmit={methods.handleSubmit(onSubmit)}>
          <ControlledInput
            options={{ required: { value: true, message: 'Campo Obrigatório' }, validate: amountValidation }}
            inputClass={"border-2 border-red-500"}
            name="amount"
            label="Qual a renda mensal desejada?*"
            mask="currency"
            placeholder="R$ 0,00"
            errors={errors.amount}
          />
          <p className="text-red-500">{errors.amount?.message}</p>

          <ControlledInput name="amountSaved"
            label="Quanto você já conseguiu guardar?"
            mask="currency"
            placeholder="R$ 0,00"
          />

          <ControlledInput
            options={{ required: { value: true, message: 'Campo Obrigatório' }, minLength: 4, validate: yearValidation }}
            name="yearToStart"
            label="Em que ano deseja começar a receber a renda?*"
            type="number"
            placeholder={`Ex: ${new Date().getFullYear() + 1}`}
            errors={errors.yearToStart}
          />

          <div className={`grid my-1 grid-cols-3 gap-x-2`}>
            <label htmlFor="period" className={`label col-span-3`} >Por quanto tempo pretende receber essa renda?*</label>
            <div className={`col-span-2`}>
              <ControlledInput
                options={{ required: { value: true, message: 'Campo Obrigatório' } }}
                name="period"
                type="number"
                placeholder="Ex: 12"
                errors={errors.period}
              />
              <p className="text-red-500">{errors.period?.message}</p>
            </div>
            <div className="relative">
              <select {...methods.register('periodType')} onClick={() => setToggleSelect(!toggleSelect)} className="text-primary font-bold cursor-pointer input">
                <option className="text-primary" value="MONTHS">Meses</option>
                <option className="text-primary" value="YEARS">Anos</option>
              </select>
              {
                toggleSelect
                  ? <MdOutlineArrowDropUp className="cursor-pointer absolute top-3 right-3 text-xl text-primary pointer-events-none" />
                  : <MdOutlineArrowDropDown className="cursor-pointer absolute top-3 right-3 text-xl text-primary pointer-events-none" />
              }
            </div>
          </div>

          <ControlledInput
            options={{ required: { value: true, message: 'Campo Obrigatório' } }}
            name="yearProfitability"
            label="Qual a rentabilidade ao ano esperada do investimento acima da inflação?*"
            mask="percentage"
            decimalPoints={2}
            placeholder="Ex: 4%"
            errors={errors.yearProfitability}
          />
          <p className="text-red-500">{errors.yearProfitability?.message}</p>
          <ControlledInput
            options={{ required: { value: true, message: 'Campo Obrigatório' } }}
            name="yearTaxes"
            label="Qual o imposto de renda sobre o rendimento do investimento?*"
            mask="percentage"
            decimalPoints={2}
            placeholder="Ex: 15%"
            errors={errors.yearTaxes}
          />
          <p className="text-red-500">{errors.yearTaxes?.message}</p>

          <span>*Campos de preenchimento obrigatório.</span>

          <div className="text-center mt-2">
            <button className="btn btn-primary text-secondary" type="submit">
              Calcular
            </button>
          </div>
        </form>
      </FormProvider>
    </>
  )
}

function SelectOptionListItem({ option, onClick }) {
  return (
    <div
      className={`rounded-lg text-white flex items-center px-[30px] py-[15px] space-x-4 h-18 flex-1 bg-primary ${!option.isActive ? 'brightness-50 cursor-default pointer-events-none' : 'cursor-pointer hover:opacity-95'}`}
      onClick={onClick}
    >
      <img src={option.ico} className="max-w-20" alt="Icon" />
      <div>
        {option.label}
        {option.isActive}
        {!option.isActive && <span className="material-icons text-secondary text-base ml-1">lock</span>}
      </div>
    </div>
  )
}

const amountValidation = (val) => {
  return (
    val !== "R$ 0,00" || "Valor não pode ser igual a zero"
  )
}
function GoalSelectorItem({ item }) {
  return <div className="flex space-x-2 align-middle">
    <div className="mt-[4px]">
      {item.icon}
    </div>
    <span className="font-semibold">{item.label}</span>
  </div>
}