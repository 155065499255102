import Modal from "react-modal"
import CloseButton from "shared/CloseButton"
import useWindowSize from "hooks/useWindowSize"
import CompanyAutocomplete from "shared/CompanyAutocomplete"
// import { SearchResultContext, SearchResultProvider } from "context/SearchResultContext"
import { useContext } from "react"
import { LAYOUT_BREAKPOINT_WIDTH } from "const"
import { useNavigate } from "react-router-dom"


function SearchModal({ isOpen = true, closeModal }) {

  const { width } = useWindowSize()
  const isMobile = width < LAYOUT_BREAKPOINT_WIDTH
  const navigate = useNavigate()
  // const { searchText } = useContext(SearchResultContext)


  const setManuallList = (company) => {
    navigate(`/pesquisa?texto=${company.name}`)
    closeModal()
  }

  const extraOption = {
    type: 'free_search',
    callback: ({ inputString }) => navigate(`/pesquisa?texto=${inputString}`),
    Component: ({ inputString }) => (<div className="my-2 font-semibold opacity-50 text-sm">Pesquisar resultados para "{inputString}"...</div>),
  }

  return (

    <Modal isOpen={isOpen} onRequestClose={closeModal} style={{
      content:
      {
        maxWidth: isMobile ? width : 648,
        margin: "auto",
        marginRight: isMobile ? -40 : "auto",
        marginLeft: isMobile ? -40 : "auto",
      }
    }} shouldCloseOnEsc={true}>
      <CloseButton onClick={closeModal} />
      <h2 className="font-bold text-xl text-primary uppercase mb-2">Busca</h2>

      <div className="relative mini-scrollbar mt-5">
        <CompanyAutocomplete extraOptions={[extraOption]} setCompany={value => setManuallList(value)} customParentClass="flex-grow" customClass="relative bg-gray-200 opacity-70 px-3 rounded-xl h-10" />
      </div>
    </Modal>

  )
}

export default SearchModal