import { uniqueId } from "lodash/util"
import { memo, useContext, useMemo } from "react"

// import widgets
import QuickQuote from "shared/widgets/QuickQuote"
import { MarketMapWidget as MarketMap } from "shared/widgets/MarketMap"
import { NewsWidget as News } from "shared/widgets/News"
import { QuotationPanelWidget as QuotationPanel } from "shared/widgets/QuotationPanel"
import { GraphicsWidget as Graphics } from "shared/widgets/Graphics"
import { SimulatorWidget as Simulator } from "shared/widgets/Simulator"
import { TradingSummaryWidget as TradingSummary } from "shared/widgets/TradingSummary"
import { RecommendationWidget as Recommendation } from "shared/widgets/Recommendation"
import { WalletWidget as Wallet } from "shared/widgets/Wallet"
import { ORIGINS } from "const"
import AuthContext from "context/AuthContext"
import { GenericWidget as Generic } from "shared/widgets/Generic"
import { FundsWidgets as Funds } from "shared/widgets/Funds"
import { FixedIncomeCalculatorWidget as FixedIncomeCalculator } from "shared/widgets/FixedIncomeCalculator"

function useWidgets() {
  const { genericModules, userInfo: { permissions } } = useContext(AuthContext)
  const widgetsMap = {
    QuickQuote: memo(QuickQuote),
    News: memo(News),
    MarketMap: memo(MarketMap),
    Graphics: memo(Graphics),
    QuotationPanel: memo(QuotationPanel),
    Simulator: memo(Simulator),
    TradingSummary: memo(TradingSummary),
    Recommendation: memo(Recommendation),
    Wallet: memo(Wallet),
    Funds: memo(Funds),
    Generic: memo(Generic),
    FixedIncomeCalculator: memo(FixedIncomeCalculator),
    // EconomicCalendar: memo(EconomicCalendar),
  }

  const widgets = useMemo(() => (
    [
      // { category: "Acompanhar", label: "Podcasts", type: "Podcasts", restricted: true },
      // { category: "Acompanhar", label: "Vídeos", type: "Videos", restricted: true },
      // { category: "Investir", label: "Boleta", type: "Billet", restricted: true, },
      // { category: "Aprender", label: "Cursos", type: "Course", restricted: true },
      // { category: "Analisar", label: "Fundos", type: "Funds", restricted: true },
      {
        category: "Acompanhar",
        label: "Notícias",
        type: "News",
        defaults: {
          desktop: { h: 15 },
          mobile: { h: 15 },
          params: {
            tabIndex: 0,
            filters: {
              origin: ORIGINS.reduce((ac, x) => ({ ...ac, [x.key]: true }), {}),
            },
          },
        },
      },
      {
        category: "Acompanhar",
        label: "Resumo da Bolsa",
        type: "TradingSummary",
        defaults: {
          desktop: { h: 14, minH: 8 },
          mobile: { h: 14, minH: 10 },
        },
      },
      {
        category: "Acompanhar",
        label: "Painel de Cotações",
        type: "QuotationPanel",
        defaults: {
          desktop: { h: 14 },
          mobile: { h: 14 },
          params: {
            display: 'table'
          }
        },
      },
      {
        category: "Acompanhar",
        label: "Mapa de Mercado",
        type: "MarketMap",
        defaults: {
          desktop: { h: 12, minH: 10 },
          mobile: { h: 12, minH: 11 },
        },
      },
      // {
      //   category: "Acompanhar",
      //   label: "Calendário Econômico",
      //   type: "EconomicCalendar",
      // },
      {
        category: "Analisar",
        label: "Recomendações e Análises",
        type: "Recommendation",
        defaults: {
          desktop: { h: 15 },
          mobile: { h: 15 },
          params: {
            filters: { company: null, customPeriod: null, period: 'W' },
          }
        },
      },
      {
        category: "Analisar",
        label: "Cotação Rápida",
        type: "QuickQuote",
        defaults: {
          desktop: { h: 15, maxW: 1 },
          mobile: { h: 15 },
          params: {
            symbol: {
              originId: 2,
              symbolCode: "PETR4",
            },
          },
        },
      },
      {
        category: "Analisar",
        label: "Análise Gráfica",
        type: "Graphics",
        defaults: {
          desktop: { h: 8 },
          mobile: { h: 8 },
        },
      },
      {
        category: "Analisar",
        label: "Simuladores",
        type: "Simulator",
        defaults: {
          desktop: { h: 11, minH: 11, maxW: 1 },
          mobile: { h: 11, minH: 11 },
        },
      },
      {
        category: "Analisar",
        label: "Calculadora de Renda Fixa",
        type: "FixedIncomeCalculator",
        defaults: {
          desktop: { h: 9, minH: 8 },
          mobile: { h: 9, minH: 8 },
        },
      },
      {
        category: "Investir",
        label: "Minha Carteira",
        type: "Wallet",
        defaults: {
          desktop: { h: 15 },
          mobile: { h: 15 },
        },
      },
      {
        category: "Analisar",
        label: "Fundos",
        type: "Funds",
        defaults: {
          desktop: { h: 16 },
          mobile: { h: 4 },
        },
      },
    ]
      .map(x => {
        const permission = permissions?.find(permission => permission.key === x.type)
        if (!x.defaults) x.defaults = {}
        x.defaults.permission = permission
        return { ...x, restricted: !permission }
      })
      .concat(...(genericModules || []).filter(item => item.exhibition.workspace).map(item => ({
        category: item.parentMenu,
        label: item.title,
        type: "Generic",
        genericType: `Generic:${item.id}`,
        restricted: !permissions?.some(permission => +permission.key === item.id),
        defaults: {
          params: item,
          desktop: { h: 14 },
          mobile: { h: 6 },
        }
      })))
  ), [genericModules, permissions])

  function generateId(type) {
    if (widgets.every((x) => x.type !== type && x.genericType !== type))
      throw new Error(`Invalid widget type "${type}"!`)
    const timestampBasedId = Date.now().toString().slice(6)
    return uniqueId(`${type}:${timestampBasedId}`)
  }

  function getWidgetsByCategory() {
    return widgets.reduce((ac, w) => {
      const found = ac.find((x) => x.category === w.category)
      if (!found) return [...ac, { category: w.category, items: [w] }]
      found.items.push(w)
      return ac
    }, [])
  }

  function getWidgetComponent(id, props = {}) {
    const sliceIndex = id.indexOf(":")
    const type = sliceIndex > -1 ? id.slice(0, sliceIndex) : id
    Object.assign(props, props[props.activeBreakpoint])
    if (!widgetsMap[type])
      // throw new Error(`Invalid widget type "${type}"!`)
      return null // TODO: dev only!
    const Component = widgetsMap[type]
    return <Component id={id} {...props} />
  }

  return {
    widgets,
    getWidgetsByCategory,
    generateId,
    getWidgetComponent
  }
}

export default useWidgets
