import { useEffect, useState } from "react"

export function useLocalParams(id, defaultParams = {}) {

  const [params, setParams] = useState(defaultParams)

  useEffect(() => {
    let newParams = localStorage.getItem(id)

    if (newParams) {
      newParams = JSON.parse(newParams)
    } else {
      newParams = defaultParams
    }

    setParams(newParams)
  }, [defaultParams, id, setParams])

  return {
    params,
    updateWidgetParams: ({params}) => {
      localStorage.setItem(id, JSON.stringify(params))
      setParams(params)
    }
  }

}