import { globalNaNRegExp, numberRegExp, stringRegExp, nonNumericRegExp } from "const"
import { format } from "helpers/numberHelper"
import { useCallback, useEffect } from "react"
import { useFormContext } from "react-hook-form"
import { MdClose, MdOutlineArrowDropDown, MdOutlineArrowDropUp } from "react-icons/md"

const KEY_Z = 90
const KEY_ZERO = 48
const KEY_NINE = 57
const KEY_NUMPAD_ZERO = 96
const KEY_NUMPAD_NINE = 105
const MASK_NUMBER = '9'
const MASK_LETTER = 'A'
const KEY_BACKSPACE = 8

function isKeyCodeAlphanumerical(key) {
  return (key >= KEY_ZERO && key <= KEY_Z) || isKeyCodeNumerical(key)
}

function isKeyCodeNumerical(key) {
  return (key >= KEY_NUMPAD_ZERO && key <= KEY_NUMPAD_NINE) || (key >= KEY_ZERO && key <= KEY_NINE)
}

export function MaskedInput({ mask, name, options = {}, ...props }) {

  const { register, setValue, resetField } = useFormContext()

  if ((typeof mask) !== 'string')
    throw new Error('Mask must to be a string!')

  const charList = mask.split('')

  const updateMask = (ev) => {
    ev.stopPropagation()
    updateValue(ev.keyCode, ev.target.value)
  }

  const updateValue = (keyCode, value) => {
    if (isKeyCodeAlphanumerical(keyCode)) {
      const nextIndex = value.length
      if (
        (charList[nextIndex] && charList[nextIndex] !== MASK_LETTER) &&
        (charList[nextIndex] !== MASK_NUMBER)
      ) {
        value += charList[nextIndex]
      }
    }
    let newValue = ''
    for (const [index, char] of Object.entries(value)) {
      if (
        (charList[index] === MASK_NUMBER && numberRegExp.test(char)) ||
        (charList[index] === MASK_LETTER && stringRegExp.test(char))
      ) {
        newValue += char
      } else if (charList[index] !== MASK_LETTER && charList[index] !== MASK_NUMBER) {
        newValue += charList[index]
      }
    }
    setValue(name, newValue)
  }

  return <div className="relative">
    <input {...props} {...register(name, options)} onKeyUp={updateMask} maxLength={mask.length} />
    <button
      className="rounded-lg p-1 cursor-pointer hover:bg-gray-300 absolute top-2.5 right-2"
      onClick={() => {
        resetField(name)
      }}
    >
      <MdClose className="text-primary " />
    </button>
  </div>
}

export function CurrencyInput({ name, options = {}, ...props }) {

  const { register, setValue, getValues } = useFormContext()

  const updateMask = useCallback((value) => {
    let num = typeof value === 'string' ? (value.replace(globalNaNRegExp, '') / 100) : value
    setValue(name, 'R$ ' + format(isNaN(num) ? 0 : num, 'currency'))
  }, [setValue])

  const onChange = useCallback((ev) => {
    ev.stopPropagation()
    updateMask(ev.target.value)
  }, [])

  useEffect(() => { updateMask(getValues()[name]) }, [updateMask, getValues])

  return <div className="relative">
    <input {...props} {...register(name, options)} onChange={onChange} type="text" />
    <div className="rounded-lg p-1 cursor-pointer hover:bg-gray-300 absolute top-2.5 right-2" onClick={() => setValue(name, '')}>
      <MdClose className="text-primary " />
    </div>
  </div>
}

export function PercentageInput({ name, decimalPoints, options = {}, ...props }) {

  const { register, setValue, getValues } = useFormContext()
  const divider = Math.pow(10, decimalPoints || 0)

  const updateMask = useCallback((value) => {
    let num = String(value).replace(nonNumericRegExp, '') / divider
    const v = format(isNaN(num) ? 0 : num, 'percent', decimalPoints)
    setValue(name, format(isNaN(num) ? 0 : num, 'percent', decimalPoints))
  }, [setValue])

  const onChange = useCallback((ev) => {
    ev.stopPropagation()
    updateMask(ev.target.value)
  }, [setValue])

  const checkBackspace = useCallback((ev) => {
    if (ev.keyCode === KEY_BACKSPACE) {
      const newValue = (ev.target.value.slice(0, -2) + ev.target.value.slice(-1)).slice(0, ev.target.value.length)
      let num = newValue.replace(nonNumericRegExp, '') / divider
      setValue(name, format(isNaN(num) ? 0 : num, 'percent', decimalPoints))
    }
  }, [setValue])

  useEffect(() => { updateMask(getValues()[name]) }, [updateMask])

  return (
    <div className="relative">
      <input {...props} {...register(name, options)} onKeyUp={checkBackspace} onChange={onChange} type="text" />
      <div className="rounded-lg p-1 cursor-pointer hover:bg-gray-300 absolute top-2.5 right-2" onClick={() => { setValue(name, '') }}>
        <MdClose className="text-primary " />
      </div>
    </div>
  )

}

export function NumberInput({ name, decimalPoints, options = {}, ...props }) {
  const { register, setValue, getValues, errors } = useFormContext()
  const val = getValues()[name] || 0

  const divider = Math.pow(10, decimalPoints)
  const decimalStep = 1 / divider

  useEffect(() => {
    let v = val
    if (typeof v === "string") {
      v = (String(v).replace(nonNumericRegExp, '') / divider)
    }
    setValue(name, isNaN(v) ? 0 : v)
  }, [setValue])

  function plusValue() {
    if (decimalPoints === 0) {
      setValue(name, +getValues()[name] + 1)
    } else setValue(Number(getValues()[name] + decimalStep).toFixed(decimalPoints))
  }

  function minusValue() {
    if (decimalPoints === 0) {
      setValue(name, +getValues()[name] - 1)
    } else setValue(Number(getValues()[name] - decimalStep).toFixed(decimalPoints))
  }

  // options.valueAsNumber = true
  return (
    <div className="relative">
      <input id={name} type='number' step={`${decimalStep}`} {...register(name, options)} {...{ options, name, className: `input ${errors && "border-2 border-red-500"}`, ...props }} />
      <div className="flex justify-center bg-gray-100 rounded-md absolute right-0 bottom-2 h-10 w-10"></div>
      <MdOutlineArrowDropUp onClick={() => { plusValue() }} className="cursor-pointer text-primary absolute right-3 text-xl bottom-6 z-50" />
      <MdOutlineArrowDropDown onClick={() => { minusValue() }} className="cursor-pointer text-primary absolute right-3 text-xl bottom-3 z-50" />
    </div>
  )
}