import React from "react"
import ReactDOM from "react-dom/client"
import AppRoutes from "routes"
import { BrowserRouter } from "react-router-dom"
import Modal from "react-modal"
import "react-tooltip/dist/react-tooltip.css"
import 'react-toastify/dist/ReactToastify.css'
import "index.css"
import { ToastContainer } from 'react-toastify'

const rootElement = document.getElementById("root")
const root = ReactDOM.createRoot(rootElement)

root.render(
  <>
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
    <ToastContainer position="top-right" autoClose={5000} pauseOnFocusLoss limit={1} />
  </>
)

Modal.setAppElement(rootElement)
Modal.defaultStyles.overlay.zIndex = 1000
Modal.defaultStyles.overlay.backgroundColor = "rgba(0,0,0,.5)"
// Modal.defaultStyles.content.inset = 10
// Modal.defaultStyles.content.padding = 0