import { Keys } from "const"
import { AiOutlineClockCircle } from "react-icons/ai"
import { SimpleTooltip } from "shared/HelpTooltip"
import { useCustomId } from "hooks/useCustomId"

function Delay({ item, className }) {

  const id = useCustomId(`${item?.symbol || ''}_delay_`)
  if (!item || !item[Keys.DELAY]) return

  return (
    <>
      <AiOutlineClockCircle className={`text-gray-500 text-base ${className}`} id={id} />
      <SimpleTooltip anchor={`#${id}`} value={`Delay de ${item[Keys.DELAY]} min`} />
    </>
  )

}

export default Delay