import { useFormContext } from "react-hook-form"
import SymbolAutocomplete from "./SymbolAutocomplete"
import { useEffect } from "react"

export function SymbolSelect({ name, label, divClass }) {

  const { setValue, watch } = useFormContext()
  const value = watch(name)

  useEffect(() => {
    if (!value)
      setValue(name, null)
  }, [name, setValue, value])

  return (
    <div className={divClass}>
      <label className="label">{label}</label>
      <SymbolAutocomplete
        initialSymbol={value && (value.symbolCode && !value.originId) ? value.symbolCode : ''}
        setSymbol={(symbol) => setValue(name, symbol)}
        activeSymbol={true}
        classInput='font-normal text-dark-gray text-base'
      />
    </div>
  )
}

export default SymbolSelect