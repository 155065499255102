import { memoize } from "lodash"
import { BASE_URL, memoizeResolver, getApiHeaders, customFetch } from "./settings"

const URL = "Statistic"

const Api = {
  fetchIBOVQuotationAsset: memoize(async function () {
    try {
      const response = await customFetch(`${BASE_URL}/${URL}/companyquotationasset`, {
        headers: getApiHeaders(),
      })
      if (response.status !== 200) {
        console.error(response)
        throw new Error(response.status)
      }
      return response.json()
    } catch (error) {
      console.error(error)
      return null
    }
  }, memoizeResolver),
}

export default Api
