import { useContext, useEffect, useState, useRef } from "react"
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { toast } from "react-toastify"
//import { MdDragIndicator } from "react-icons/md"
import { FundsContext } from 'context/FundsContext'
import iconPanelDelete from 'assets/icons/panel/botao-excluir.svg'
import Modal from "react-modal"
import CloseButton from "shared/CloseButton"
import { LoadingBox } from "shared/Loading"
import useWindowSize from "hooks/useWindowSize"
import { LAYOUT_BREAKPOINT_WIDTH_MOBILE } from 'const'


export function ModalEditFunds({ isOpen = true, closeModal }) {
  const { listActive, listEditing, formattedListEditing, updateListPanel, setSelectedCategory } = useContext(FundsContext)
  const [listEdit, setListEdit] = useState([])
  const [loading, setLoading] = useState(true)
  const { width } = useWindowSize()
  const isMobile = width < LAYOUT_BREAKPOINT_WIDTH_MOBILE

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 500)
    if (listEditing) setListEdit(listEditing)
    else setListEdit(listActive)
  }, [listEditing, listActive])

  const save = async () => {
    try {
      await updateListPanel({ Id: listEdit.id, Name: listEdit.panelName, CNPJNumbers: [...listEdit.funds.map(({ cnpjNumberClean }) => cnpjNumberClean)] })
      setSelectedCategory(listEdit)
      toast.success(`A lista ${listEdit.panelName} foi editada com sucesso!`)
      closeModalEdit()
    } catch (error) {
      toast.error('Não foi possível editar a lista')
      closeModalEdit()
    }
  }

  function closeModalEdit() {
    formattedListEditing(null)
    closeModal()
  }

  return (
    <DndProvider backend={HTML5Backend} >
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={
          {
            content:
            { 
              maxWidth: 602,
              borderRadius: 12,
              margin: "auto",
              marginRight: isMobile ? -40 : "auto",
              marginLeft: isMobile ? -40 : "auto"
            }
          }
        }
      >
        <CloseButton className='absolute right-5 top-5 z-50' size={20} color={'#472F92'} onClick={closeModalEdit} />
        <div className="relative flex flex-col h-full">
          <div className="mb-5">
            <h2 className="font-bold text-xl text-primary uppercase mb-2">Editar lista</h2>
          </div>
          {loading
            ? <div className='grow flex justify-center h-80'>
              <LoadingBox />
            </div>
            : <CardList listEdit={listEdit} setList={setListEdit} />}
          <div className="flex justify-center space-x-3 mt-3">
            <button className="btn btn-secondary" onClick={closeModalEdit}>Cancelar</button>
            <button className="btn btn-primary text-secondary" onClick={save}>Salvar</button>
          </div>
        </div>
      </Modal >
    </DndProvider>
  )
}

function CardList({ listEdit, setList }) {
  const [cardSelected, setCardSelected] = useState(null)
  const { width } = useWindowSize()

  function moveFundsEditList(from, to) {
    const dragged = listEdit.funds[from]
    setCardSelected(dragged)
    const newList = [...listEdit.funds]
    newList.splice(from, 1)
    newList.splice(to, 0, dragged)
    setList({ ...listEdit, funds: [...newList] })
  }

  function deleteFund(fund) {
    setList({ ...listEdit, funds: listEdit.funds.filter((el) => fund.cnpjNumberClean !== el.cnpjNumberClean) })
  }

  return (
    <ul className={` w-full overflow-auto mini-scrollbar grow flex flex-col`}>
      <div className="border border-b-0 border-[#DBDBDB]">
        {listEdit.funds.length
          ? listEdit.funds.map((fund, index) => (
            <Card
              key={fund?.cnpjNumberClean}
              data={fund}
              index={index}
              moveCard={moveFundsEditList}
              updatedCardSelected={setCardSelected}
              deleteFund={deleteFund}
              classContainer={`py-1 ${!cardSelected && 'hover:bg-[#EDEDED]'}`}
            />
          ))
          : <div className="grow flex items-baseline">
            <span className="text-dark-gray">Nenhum fundo adicionado na lista.</span>
        </div>}
      </div>
    </ul>
  )
}

function Card({ data, index, moveCard, updatedCardSelected, classContainer, deleteFund }) {
  const ref = useRef()
  const { width } = useWindowSize()
  // const [{ content }, dragRef] = useDrag({
  //   type: 'CARD',
  //   item: { index, data },
  //   collect: (monitor) => ({
  //     content: monitor.getItem()
  //   })
  // })

  // const [, dropRef] = useDrop({
  //   accept: 'CARD',
  //   hover: (item, monitor) => {
  //     const dragIndex = item.index
  //     const targetIndex = index
  //     if (dragIndex === targetIndex) return

  //     const targetSize = ref.current.getBoundingClientRect()
  //     const targetCenter = (targetSize.bottom - targetSize.top) / 2

  //     const draggedOffset = monitor.getClientOffset()
  //     const draggedTop = draggedOffset.y - targetSize.top

  //     if (dragIndex < targetIndex && draggedTop < targetCenter) return
  //     if (dragIndex > targetIndex && draggedTop > targetCenter) return

  //     moveCard(dragIndex, targetIndex)
  //     item.index = targetIndex
  //   },
  //   drop: (item, monitor) => {
  //     if (monitor.isOver()) updatedCardSelected(null)
  //   }
  // })

  // dragRef(dropRef(ref))

  return (
    <div ref={ref} className={`w-full border-b border-[#DBDBDB] ${width < 400 && 'w-11/12'} ${classContainer}`}>
      <div className={`flex justify-between items-center space-x-2 px-[10px] text-dark-gray`}>
        {/* <MdDragIndicator className='flex-none cursor-grabbing' /> */}
        <div className={`w-11/12 flex flex-col`}>
          <span className='w-full font-semibold line-clamp-2'>{data.foundName}</span>
          <span className='text-sm'>{data.cnpjNumber}</span>
        </div>
        <button
          onClick={() => deleteFund(data)}
          className={`flex-none text-loss text-xl opacity-90 cursor-pointer hover:opacity-100`}
          title="Remover fundo"
        >
          <img src={iconPanelDelete} alt="icone de excluir" />
        </button>
      </div>
    </div>
  )
}