import { Keys } from "const"
import { MdGavel } from "react-icons/md"
import { SimpleTooltip } from "shared/HelpTooltip"
import { useCustomId } from "hooks/useCustomId"

function AuctionIndicator({ item, className }) {
  
  const id = useCustomId(`${item?.symbol || ''}_gavel`)
  if (!item || item[Keys.ESTNEG] !== 5) return

  return (
    <>
      <MdGavel className={`text-gray-500 text-base ${className}`} id={id} />
      <SimpleTooltip anchor={`#${id}`} value={`Leilão`} />
    </>
  )

}

export default AuctionIndicator