import { memoize } from "lodash"
import { BASE_URL, SearchParams, getApiHeaders, memoizeResolver, customFetch } from "./settings"

const URL = 'BalanceSheets'

const Api = {
  financialIndicators: memoize(async (body) => {

    const Params = SearchParams({
      cnpj: body.cnpj,
      campos: body.fields,
      periodo: body.periodo || 'trimestral',
      acumulado: body.acumulado,
    })

    const response = await customFetch(`${BASE_URL}/${URL}/financialindicators?${Params}`, { method: 'GET', headers: getApiHeaders() })
    if (response.status === 200) return response.json()
  }, memoizeResolver)
}

export default Api