import React, { useState, useEffect, useContext } from "react"
import { useNavigate } from 'react-router-dom'
import BlockedDesk from "assets/img-bloqueio-desk.png"
import BlockedMobile from "assets/img-bloqueio-mobile.png"

const AccessInterceptorContext = React.createContext({
  isAccessBlocked: false,
  checkAccess: () => { },
  setIsAccessBlocked: () => { },
})

export function AccessInterceptorContextProvider(props) {
  const [isAccessBlocked, setIsAccessBlocked] = useState(false)
  const navigate = useNavigate()

  function checkAccess(isFeatureBlock, callback) {
    if (isFeatureBlock) return setIsAccessBlocked(true)
    if (typeof callback === 'function') callback()
  }

  function clickAction() {
    navigate('/planos')
    setIsAccessBlocked(false)
  }


  return (
    <AccessInterceptorContext.Provider value={{ isAccessBlocked, setIsAccessBlocked, checkAccess }}>
      {
        isAccessBlocked ? (
          <>
            <div onClick={() => setIsAccessBlocked(false)} className="h-screen w-screen fixed bg-black bg-opacity-25 z-[500] flex items-center justify-center">
              <img onClick={() => clickAction()} src={BlockedDesk} className="hidden md:block cursor-pointer" />
              <img onClick={() => clickAction()} src={BlockedMobile} className="block md:hidden cursor-pointer" />
            </div>
            <div className="blur-sm">
              {props.children}
            </div>
          </>
        ) : props.children
      }
    </AccessInterceptorContext.Provider>
  )
}

export default AccessInterceptorContext
