import { BASE_URL, getApiHeaders, customFetch } from "./settings"
import { FetchError, InternalFetchError } from "errors/FetchError"

const URL_ALERT = 'Alert'

const Api = {

  fetchAlertsQuote: async function () {
    try {
      const res = await customFetch(`${BASE_URL}/${URL_ALERT}/quotation`, { method: 'GET', headers: getApiHeaders() })
      const resBody = await res.json()
      if (!res.ok) throw new FetchError(res, resBody)
      return resBody
    } catch (error) {
      if (error instanceof FetchError) throw error
      throw new InternalFetchError(error)
    }
  },

  fetchNewAlertsQuote: async function (params) {
    try {
      const res = await customFetch(`${BASE_URL}/${URL_ALERT}/quotation`, { method: 'POST', headers: getApiHeaders(), body: JSON.stringify(params) })
      const resBody = await res.json()
      if (!res.ok) throw new FetchError(res, resBody)
      return resBody
    } catch (error) {
      if (error instanceof FetchError) throw error
      throw new InternalFetchError(error)
    }
  },

  fetchUpdateAlertsQuote: async function (params) {
    try {
      const res = await customFetch(`${BASE_URL}/${URL_ALERT}/quotation`, { method: 'PUT', headers: getApiHeaders(), body: JSON.stringify(params) })
      const resBody = await res.json()
      if (!res.ok) throw new FetchError(res, resBody)
      return resBody
    } catch (error) {
      if (error instanceof FetchError) throw error
      throw new InternalFetchError(error)
    }
  },

  fetchDeleteAlertsQuote: async function (params) {
    try {
      const res = await customFetch(`${BASE_URL}/${URL_ALERT}/quotation`, { method: 'DELETE', headers: getApiHeaders(), body: JSON.stringify(params) })
      const resBody = await res.json()
      if (!res.ok) throw new FetchError(res, resBody)
      return resBody
    } catch (error) {
      if (error instanceof FetchError) throw error
      throw new InternalFetchError(error)
    }
  },

  fetchUpdateAlertsMultiQuote: async function (params) {
    try {
      const response = await customFetch(`${BASE_URL}/${URL_ALERT}/MultiQuotationAlertInput`, {
        method: 'PUT',
        headers: getApiHeaders(),
        body: JSON.stringify(params)
      })
      if (response.status !== 200) {
        throw new Error(`Error: ${response.status}`)
      }
      return response.json()
    } catch (error) {
      console.error(error)
      return null
    }
  },

  fetchAlertsNews: async function () {
    try {
      const res = await customFetch(`${BASE_URL}/${URL_ALERT}/news`, { method: 'GET', headers: getApiHeaders() })
      const resBody = await res.json()
      if (!res.ok) throw new FetchError(res, resBody)
      return resBody
    } catch (error) {
      if (error instanceof FetchError) throw error
      throw new InternalFetchError(error)
    }
  },

  fetchNewAlertsNews: async function (params) {
    try {
      const res = await customFetch(`${BASE_URL}/${URL_ALERT}/news`, { method: 'POST', headers: getApiHeaders(), body: JSON.stringify(params) })
      const resBody = await res.json()
      if (!res.ok) throw new FetchError(res, resBody)
      return resBody
    } catch (error) {
      if (error instanceof FetchError) throw error
      throw new InternalFetchError(error)
    }
  },

  fetchUpdateAlertsNews: async function (params) {
    try {
      const res = await customFetch(`${BASE_URL}/${URL_ALERT}/news`, { method: 'PUT', headers: getApiHeaders(), body: JSON.stringify(params) })
      const resBody = await res.json()
      if (!res.ok) throw new FetchError(res, resBody)
      return resBody
    } catch (error) {
      if (error instanceof FetchError) throw error
      throw new InternalFetchError(error)
    }
  },

  fetchDeleteAlertsNews: async function (params) {
    try {
      const res = await customFetch(`${BASE_URL}/${URL_ALERT}/news`, { method: 'DELETE', headers: getApiHeaders(), body: JSON.stringify(params) })
      const resBody = await res.json()
      if (!res.ok) throw new FetchError(res, resBody)
      return resBody
    } catch (error) {
      if (error instanceof FetchError) throw error
      throw new InternalFetchError(error)
    }
  },
}

export default Api