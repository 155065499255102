import { memoize } from "lodash"
import { BASE_URL, getApiHeaders, memoizeResolver, customFetch } from "./settings"

const URL_NOTIFICATION = 'Notification';

const NotificationApi = {
  emailSales: memoize(async function (data) {
    return customFetch(`${BASE_URL}/${URL_NOTIFICATION}/emailsales`, {
      method: "POST",
      headers: getApiHeaders(),
      body: JSON.stringify(data),
    }).then((response) => response.json());
  }, memoizeResolver),
};

export default NotificationApi;