import { useState, useContext, useEffect } from 'react'
import Modal from 'react-modal'
import { USAGE_GUIDE, LAYOUT_BREAKPOINT_WIDTH_MD, LAYOUT_BREAKPOINT_WIDTH_MOBILE, LAYOUT_BREAKPOINT_WIDTH } from 'const'
import AuthorizationApi from 'api/AuthorizationApi'
import AuthContext from "context/AuthContext"
import useWindowSize from 'hooks/useWindowSize'
import { MdClose } from "react-icons/md"
import { CheckboxBasic } from 'shared/Checkbox'
import useTitle from "hooks/useTitle"
import imgPrincipal from 'assets/imgs/guide/modal-principal@2x.png'
import ModalShowGuide from 'shared/modal/UsageGuide/ModalShowGuide'

export function PageUsageGuide() {
  useTitle('Guia de uso')
  const { width } = useWindowSize()

  return (
    <div className={`flex flex-col ${width > LAYOUT_BREAKPOINT_WIDTH_MD ? 'gap-[60px] m-[30px]' : 'gap-[30px] p-[30px]' } bg-white rounded-xl`}>
      <h4 className={`uppercase text-primary text-xl font-bold ${width > LAYOUT_BREAKPOINT_WIDTH_MD && 'pt-[30px] pl-[30px]'}`}>Guia de uso</h4>
      <Content width={width} />
    </div>
  )
}

function primeNumber(num) {
  return num%2 === 1
}

function Content({width, scroll}) {

  const [cardActive, setCardActive] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  function updatedCard(card) {
    setCardActive(card)
    setIsModalOpen(true)
  }

  return (
    <div style={scroll && { maxHeight: '100%' }} className={`flex justify-center ${scroll && 'overflow-auto mini-scrollbar'}`}>
      <div className='flex flex-col gap-[60px] pb-[30px] max-w-6xl'>
        {
          USAGE_GUIDE.map(({title, description, img, link}, index) => {
            return width > LAYOUT_BREAKPOINT_WIDTH_MD 
              ? <div className='grid grid-cols-2 gap-[30px]' key={title}>
                  <div className={`${primeNumber(index) && 'order-last'} relative`}>
                    <picture onClick={() => link && updatedCard({ index, title, link }) } className={link && 'cursor-pointer'}>
                      <source media={`(max-width: ${LAYOUT_BREAKPOINT_WIDTH_MD}px)`} srcSet={img.mobile} />
                      <img src={img.desktop} alt="imagem app" />
                    </picture>
                  </div>
                  <div className='flex flex-col justify-center'>
                    <span className='font-bold text-[26px] text-[#4C3693] mb-[10px]'>{title}</span>
                    <span className='text-xl'>{description}</span>
                  </div>
                </div>
              : <div className='flex flex-col' key={title}>
                  <span className='font-bold text-[26px] text-left text-[#4C3693] mb-[10px]'>{title}</span>
                  <div className='relative'>
                    <picture onClick={() => updatedCard({ index, title, link }) } className='cursor-pointer'>
                      <source media={`(max-width: ${LAYOUT_BREAKPOINT_WIDTH_MD}px)`} srcSet={img.mobile} />
                      <img src={img.desktop} alt="imagem app" />
                    </picture>
                  </div>
                  <span className='text-xl mt-5'>{description}</span>
                </div>
          })
        }
      </div>
      <ModalShowGuide isModalOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} card={cardActive} width={width} />
    </div>
  )

}

export function ModalUsageGuide() {
  
  const { userInfo } = useContext(AuthContext)
  const { width, height } = useWindowSize()
  const [isOpen, setIsOpen] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [videoPrincipal, setVideoPrincipal] = useState(false)
  const [viewActive, setViewActive] = useState('principal')

  const mobile = width <= LAYOUT_BREAKPOINT_WIDTH_MD

  const closeModal = () => {
    isChecked && AuthorizationApi.entryScreen('disable')
    setIsOpen(false)
  }

  useEffect(() => {
    const wasViewed = sessionStorage.getItem('viewGuide')
    if(!wasViewed) {
      setIsOpen(userInfo.entryScreen)
      sessionStorage.setItem('viewGuide', JSON.stringify(true))
    }
  }, [])

  return (
    <Modal 
      isOpen={isOpen}
      onRequestClose={() => closeModal()}
      style={{content: 
        {
          borderRadius: `${mobile ? 0 : '10px'}`,
          margin: `${mobile ? '-2.5rem' : 'auto'}`,
          marginLeft: mobile ? -40 : 'auto',
          marginRight: mobile ? -40 : 'auto',
          overflow: 'hidden'
        }
      }}
    >
      <div className={`${(width > LAYOUT_BREAKPOINT_WIDTH_MD) || (width < LAYOUT_BREAKPOINT_WIDTH_MOBILE) ? 'h-full' : ''} flex flex-col gap-4`}>
        <div className='flex justify-between'>
          <h4 className={`uppercase text-primary text-xl font-bold`}>{viewActive === 'principal' ? 'Primeiros passos' : 'Guia de uso'}</h4>
          <button onClick={() => closeModal()} className="mt-2 self-start hover:opacity-100">
            <MdClose className="w-7 h-7 text-primary" />
          </button>
        </div>
        {
          { 'principal': <div className={`h-full flex flex-col justify-around`}>
            {videoPrincipal 
              ? <div className='w-[90%] 2xl:w-[60%] m-auto'>
                  <iframe
                      src='https://www.youtube-nocookie.com/embed/jKO0MpiiEwI?rel=0&autoplay=1&fs=0' 
                      className="w-full aspect-video"
                      allow='autoplay'
                      title='playlist principal'
                    />
                </div>
              : <div className={`flex ${(width >= LAYOUT_BREAKPOINT_WIDTH_MD) ? 'h-3/4 justify-center items-center' : 'h-1/2 items-end'}`}>
                  <img onClick={() => setVideoPrincipal(true) } src={imgPrincipal} style={{ width: width >= LAYOUT_BREAKPOINT_WIDTH_MD ? width < LAYOUT_BREAKPOINT_WIDTH ? '70%' : '' : '100%', height: width > LAYOUT_BREAKPOINT_WIDTH_MD ? '100%' : '' }} className='cursor-pointer mx-auto' alt="imagem do sistema em um notebook" />
                </div>}
              <div className={`${height < 1000 ? 'flex-1' : 'flex' } ${width > LAYOUT_BREAKPOINT_WIDTH_MD ? '' : 'flex justify-between'} flex-col items-center gap-3 mt-9 text-dark-gray`}>
                <div className={`${height < 1000 ? 'flex justify-center gap-[30px]' : ''} `}>
                  <button onClick={() => setViewActive('guide')} className="btn btn-primary text-secondary">Saiba mais</button>
                  <button onClick={() => closeModal()} className="btn btn-secondary text-primary">fechar</button>
                </div>
                <div className={`w-full flex ${width >= LAYOUT_BREAKPOINT_WIDTH_MD ? 'items-center justify-between mt-4' : 'flex-col gap-4'} `}>
                  <span className={`text-base font-semibold`}>Você poderá acessar esse conteúdo a qualquer momento clicando no menu Aprender / Guia de Uso</span>
                  <CheckboxBasic setIsChecked={setIsChecked}>
                    <span className="ml-2 text-sm">Não exibir essa mensagem novamente.</span>
                  </CheckboxBasic>
                </div>
              </div>
            </div>,
            'guide': <Content width={width} scroll={true} />
          }[viewActive]
        }
      </div>
    </Modal>
  )
}


