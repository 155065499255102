import { BUCKET_URL } from "const"
import { useEffect, useMemo } from "react"

export function AssetImg({ symbol, origin, size = 'md' }) {

  const urlBase = `${BUCKET_URL}/assets`
  const sizeClass = useMemo(() => ({ 'sm': 'w-6', 'md': 'w-8', 'lg': 'w-12' })[size], [size])
  const url = useMemo(() => {
    switch (origin) {
      case 1:
        return `${urlBase}/origins/origem-valor-data.svg`
      case 2:
        return `${urlBase}/symbols/${symbol.substring(0, 4)}.svg`
      case 3:
        return `${urlBase}/origins/origem-b3.svg`
      case 9:
        return `${urlBase}/origins/origem-ibge.svg`
      case 1058:
        return `${urlBase}/origins/origem-ambima.svg`
      case 13118:
        return `${urlBase}/origins/origem-fgv.svg`
      case 15157:
      case 15158:
        return `${urlBase}/origins/origem-cme-group.svg`
      case 15153:
      case 17195:
        return `${urlBase}/origins/origem-nasdaq.svg`
      case 15160:
      case 57201:
        return `${urlBase}/origins/origem-nyse.svg`
      default:
        return `${urlBase}/icone-generico.svg`

    }
  }, [symbol, origin])

  const addImageFallback = (event) => {
    event.currentTarget.src = origin === 2 || origin === 3 ? `${urlBase}/origins/origem-b3.svg` : `${urlBase}/icone-generico.svg`
  }


  return (
    <div className={`overflow-hidden rounded ${sizeClass}`}>
    {/* <div className={`overflow-hidden rounded-sm border p-1 ${sizeClass}`} style={{ borderColor: '#00000016' }}> */}
      <img className="block rounded-sm" src={url} onError={addImageFallback} />
    </div>
  )

} 

