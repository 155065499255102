import { usePrevious } from "hooks/usePrevious"
import { useEffect } from "react"
import { useCustomId } from "../hooks/useCustomId"

function Blink({ value, children, ...props }) {
  const id = useCustomId('blink')
  const prev = usePrevious(value)
  const className = prev > value ? 'text-loss' : (prev < value ? 'text-profit' : null)
  
  useEffect(() => {
    const el = document.getElementById(id)
    if (!el || prev === undefined) return
    el.style.opacity = .5
    const added = className && !el.classList.contains(className)
    if (added) el.classList.add(className)
    setTimeout(() => {
      el.style.opacity = 1
      if (added) el.classList.remove(className)
    }, 150)
  }, [value])

  return (
    <span className={`transition duration-1000 ${props?.className || ''}`} id={id} {...props}>
      {children || value} 
    </span>
  )
} 

export default Blink