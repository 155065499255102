import { createContext, useState } from "react"
import Modal from "react-modal"
import QuickQuote from "shared/widgets/QuickQuote"
import { useId } from "react"
import { MdClose } from "react-icons/md"
import { useMemo } from "react"

export const QuickQuotationModalContext = createContext({
  isOpen: false,
  open: (symbol) => { },
  close: () => { }
})

export function QuickQuotationModalContextProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false)
  const [symbol, setSymbol] = useState('PETR4')
  const params = useMemo(() => ({symbol}), [symbol])
  const id = useId()

  const open = (newSymbol) => {
    setIsOpen(true)
    if (newSymbol) setSymbol(newSymbol)
  }

  const close = () => {
    setIsOpen(false)
  }


  return (
    <QuickQuotationModalContext.Provider
      value={{
        isOpen,
        open,
        close
      }}
    >
      { children }
      <Modal
        isOpen={isOpen}
        onRequestClose={close}
        style={{ content: { padding: 0, maxWidth: 648, margin: "auto", borderRadius: '12px', inset: 10 } }}
      >
        <div className="relative h-full overflow-hidden">
          <button className="sticky float-right right-2 top-2 text-xl text-primary" title="Fechar" onClick={close}>
            <MdClose />
          </button>
          { isOpen && <QuickQuote id={id} params={params} /> }
        </div>
      </Modal>
    </QuickQuotationModalContext.Provider>
  )
}

export default QuickQuotationModalContext
