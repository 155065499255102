import AccessInterceptorContext from "context/AccessInterceptorContext"
import AuthContext from "context/AuthContext"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"

export function usePermission(widgetType) {
  const { userInfo: { permissions } } = useContext(AuthContext)
  return permissions.find(({key}) => String(widgetType).split(':')[0] === key)
}

export function usePermissionValidation(widgetType) {
  const permission = usePermission(widgetType)
  const { setIsAccessBlocked } = useContext(AccessInterceptorContext)
  const navigate = useNavigate()

  const validatePermissionAndRedirect = () => {
    if (!permission) {
      setTimeout(() => navigate('/'))
      setIsAccessBlocked(true)
      return true
    }
    return false
  }
  return { validatePermissionAndRedirect, permission }
}
