import IcoValorInveste from "assets/icons/news/ico-valor-investe.svg"
import IcoValorPro from "assets/icons/news/ico-valor-pro.svg"
import IcoValorEconomico from "assets/icons/news/ico-valor-economico.svg"
import IcoOrigemPipeline from "assets/icons/news/ico-origem-pipeline.svg"
import IcoOrigemOne from "assets/icons/news/ico-valor-one.svg"

import GuideHome from 'assets/imgs/guide/primeiros-passos.png'
import GuideHome2 from 'assets/imgs/guide/primeiros-passos@2x.png'
import GuideWallet from 'assets/imgs/guide/carteiras-exclusivas.png'
import GuideWallet2 from 'assets/imgs/guide/carteiras-exclusivas@2x.png'
import GuideConsensus from 'assets/imgs/guide/recomendacoes-invest.png'
import GuideConsensus2 from 'assets/imgs/guide/recomendacoes-invest@2x.png'
import GuideNew from 'assets/imgs/guide/superfeed-noticias.png'
import GuideNew2 from 'assets/imgs/guide/superfeed-noticias@2x.png'
import GuideIphone from 'assets/imgs/guide/aproveite-maximo.png'
import GuideIphone2 from 'assets/imgs/guide/aproveite-maximo@2x.png'
import GuideMarket from 'assets/imgs/guide/siga-movimentos-merc.png'
import GuideMarket2 from 'assets/imgs/guide/siga-movimentos-merc@2x.png'
import GuidePanel from 'assets/imgs/guide/acompanhe-carteira.png'
import GuidePanel2 from 'assets/imgs/guide/acompanhe-carteira@2x.png'
import GuideCourses from 'assets/imgs/guide/aprenda-especialistas.png'
import GuideCourses2 from 'assets/imgs/guide/aprenda-especialistas@2x.png'
import GuideInvestmentSimulator from 'assets/imgs/guide/faca-planos.png'
import GuideInvestmentSimulator2 from 'assets/imgs/guide/faca-planos@2x.png'
import GuideCompareFundos from 'assets/imgs/guide/compare-fundos.png'
import GuideCompareFundos2 from 'assets/imgs/guide/compare-fundos@2x.png'

import FourStars from 'assets/icons/funds/star-4.svg'
import ThreeStars from 'assets/icons/funds/star-3.svg'
import TwoStars from 'assets/icons/funds/star-2.svg'
import OneStar from 'assets/icons/funds/star-1.svg'

import CategoryTen from 'assets/icons/funds/graf-posicao-categ-36m-10.svg'
import CategoryNine from 'assets/icons/funds/graf-posicao-categ-36m-09.svg'
import CategoryEight from 'assets/icons/funds/graf-posicao-categ-36m-08.svg'
import CategorySeven from 'assets/icons/funds/graf-posicao-categ-36m-07.svg'
import CategorySix from 'assets/icons/funds/graf-posicao-categ-36m-06.svg'
import CategoryFive from 'assets/icons/funds/graf-posicao-categ-36m-05.svg'
import CategoryFour from 'assets/icons/funds/graf-posicao-categ-36m-04.svg'
import CategoryThree from 'assets/icons/funds/graf-posicao-categ-36m-03.svg'
import CategoryTwo from 'assets/icons/funds/graf-posicao-categ-36m-02.svg'
import CategoryOne from 'assets/icons/funds/graf-posicao-categ-36m-01.svg'

export const LAYOUT_BREAKPOINT_WIDTH_LG = 1366
export const LAYOUT_BREAKPOINT_WIDTH = 1280
export const LAYOUT_BREAKPOINT_WIDTH_TABLET = 1024
export const LAYOUT_BREAKPOINT_WIDTH_MD = 768
export const LAYOUT_BREAKPOINT_WIDTH_MOBILE = 528
export const HEADER_HEIGHT = '5rem'

export const QUOTE_COLOR_MAP = new Map()
QUOTE_COLOR_MAP.set('Compra', 'bg-profit')
QUOTE_COLOR_MAP.set('Neutro', 'bg-gray-300')
QUOTE_COLOR_MAP.set('Venda', 'bg-loss')
QUOTE_COLOR_MAP.set('Em Revisão', 'bg-amber-400') // TODO: Type -> Em Revisão não existe, conferir depois

export const COLOR_ORDER = new Map()
COLOR_ORDER.set('Compra', 1)
COLOR_ORDER.set('Neutro', 2)
COLOR_ORDER.set('Venda', 3)
COLOR_ORDER.set('Em Revisão', 4)

export const ORIGINS = [
  { key: "PipelineValor", label: "Pipeline Valor", isActive: false },
  { key: "ValorEconomico", label: "Valor Econômico", isActive: false },
  { key: "ValorInveste", label: "Valor Investe", isActive: false },
  { key: "ValorOne", label: "Valor One", isActive: false },
  { key: "ValorProPrata", label: "Valor PRO - Prata", isActive: false }
]

export const BUCKET_URL = 'https://storage.googleapis.com/images-vo'

export const NEWS_TABS_KEYS = {
  General: 'General',
  MyNews: 'MyNews',
}

export const NEWS_TABS = [
  { name: "Geral", key: NEWS_TABS_KEYS.General, isActive: true },
  { name: "Minhas Notícias", key: NEWS_TABS_KEYS.MyNews, isActive: false },
  // { name: "Minha Carteira", key: "WalletPosition", isActive: false },
  // { name: "Recomendadas" },
  // { name: "+ Lidas" }
]

// RegExp
export const numberRegExp = /[0-9]/
export const stringRegExp = /[A-Za-z]/
export const globalNaNRegExp = /\D+/g
export const nonNumericRegExp = /[^\d-]/g

export const Keys = {
  "MOEDA": 15,
  "IDORD": 37,
  "ATIVO": 55,
  "LIQORD": 63,
  "DAJU": 64,
  "DREF": 75,
  "NSINAL": 107,
  "TPMER": 167,
  "PEX": 202,
  "DBASE": 225,
  "FATOR": 231,
  "DULT": 272,
  "HULTGMT": 273,
  "TND": 274,
  "QCOND": 276,
  "TNEG": 277,
  "TPABE": 286,
  "DDELIVER": 287,
  "CCP": 288,
  "CVD": 289,
  "OFBOOK": 290,
  "CODP": 311,
  "ESTNEG": 326,
  "HFLEIGMT": 342,
  "NORD": 346,
  "DIFFEC": 451,
  "ISIN": 455,
  "CFI": 461,
  "CPAIS": 470,
  "DVENC": 541,
  "LOTE": 561,
  "QMIN": 562,
  "CODP2": 602,
  "FASE": 625,
  "TPAJU": 731,
  "SUBMER": 762,
  "DATIV": 873,
  "DINIC": 916,
  "DFIM": 917,
  "IDNEG": 1003,
  "CNEG": 1020,
  "LMIN": 1148,
  "LMAX": 1149,
  "REF": 1150,
  "ESTILO": 1194,
  "TLIMP": 1306,
  "CANEG": 1500,
  "CDEC": 5151,
  "AGRESS": 5767,
  "MERC": 6937,
  "UDN": 6938,
  "VAR": 7687,
  "QFLAT": 9343,
  "QOFF": 9344,
  "QMAXO": 9748,
  "QMINO": 9749,
  "QCOT": 9989,
  "FFLOAT": 9990,
  "PCTUA": 9993,
  "QDOWN": 9996,
  "QUP": 9997,
  "NTRADE": 37001,
  "CTRADE": 37002,
  "QNEG30": 37003,
  "DIVP": 37012,
  "ULT": 100001,
  "MAX": 100002,
  "MIN": 100003,
  "ABER": 100004,
  "OCP": 100005,
  "CUSIP": 100006,
  "CVM": 100007,
  "SELIC": 100008,
  "SEG": 100009,
  "SUBSEG": 100010,
  "SETOR": 100011,
  "PRAZO": 100012,
  "DESC": 100013,
  "PERFIL": 100014,
  "EMIS": 100015,
  "PART": 100017,
  "PERIOD": 100018,
  "BCALC": 100019,
  "DELAY": 100020,
  "FEC": 100021,
  "FECDIA": 100023,
  "NEG": 100024,
  "MED": 100025,
  "POND": 100026,
  "MDN": 100027,
  "HOCPGMT": 100029,
  "HOVDGMT": 100030,
  "QTT": 100031,
  "BBPC": 100032,
  "BAPC": 100033,
  "QMOCP": 100034,
  "QMOVD": 100035,
  "DAFTFEC": 100036,
  "YIELD": 100037,
  "VOLF": 100038,
  "PMEDAN": 100039,
  "VOLFA": 100040,
  "QTA": 100041,
  "CPMIN": 100042,
  "CPMAX": 100043,
  "VDMIN": 100044,
  "VDMAX": 100045,
  "SPRECP": 100048,
  "SPREVD": 100049,
  "SPREAD": 100050,
  "PREG": 100052,
  "DUVF": 100053,
  "DCVF": 100054,
  "DAJUA": 100055,
  "AJUA": 100058,
  "NSAQ": 100059,
  "DURATION": 100060,
  "RATING": 100061,
  "PROXDIV": 100063,
  "PU550": 100066,
  "REC": 100067,
  "VARAJU": 100069,
  "DIFAJU": 100070,
  "DIFMAX": 100071,
  "DIFMIN": 100072,
  "PCTMAX": 100073,
  "PCTMIN": 100074,
  "VARSEM": 100075,
  "VAR1SEM": 100076,
  "VARMES": 100077,
  "VAR1MES": 100078,
  "VARANO": 100079,
  "VAR1ANO": 100080,
  "FECS": 100081,
  "FEC1S": 100082,
  "FECM": 100083,
  "FEC1M": 100084,
  "FECAA": 100085,
  "FEC1A": 100086,
  "MAX52": 100087,
  "MIN52": 100088,
  "DMAX52": 100089,
  "DMIN52": 100090,
  "HMAX": 100091,
  "HMIN": 100092,
  "DMAX": 100093,
  "DMIN": 100094,
  "VLTH1M": 100095,
  "VLTH45D": 100096,
  "VLTH6M": 100097,
  "VLTH252D": 100098,
  "MKTCAP": 100099,
  "PL": 100100,
  "DIVYLD": 100101,
  "PVPA": 100103,
  "PEBITDA": 100105,
  "PAYOUT": 100106,
  "EV": 100107,
  "EVEBTD": 100108,
  "QTACAO": 100109,
  "LLIQ": 100110,
  "PLIQ": 100111,
  "EBITDA": 100112,
  "RECLIQ": 100113,
  "DIVA": 100114,
  "DIVBRU": 100116,
  "REFBAL": 100117,
  "COD": 100122,
  "ICOR": 100123,
  "CAREN": 100124,
  "AMORT": 100125,
  "CONVER": 100126,
  "RCONVER": 100127,
  "REPAC": 100128,
  "RREPAC": 100129,
  "PDN": 100130,
  "DDIST": 100132,
  "GARANT": 100133,
  "PCPM": 100134,
  "TCALC": 100135,
  "MONT": 100136,
  "QDEB": 100137,
  "PEMIS": 100138,
  "PUT": 100140,
  "DPUT": 100141,
  "PPUT": 100142,
  "CALL": 100143,
  "DCALL": 100144,
  "PCALL": 100145,
  "MAND": 100146,
  "FIDUC": 100147,
  "DEPOSIT": 100148,
  "LIDER": 100149,
  "OBS": 100150,
  "OVD": 100151,
  "QULT": 100152,
  "CABE": 100153,
  "AJU": 100154,
  "PMVP": 100155,
  "NIND": 100156,
  "DESEQ": 100157,
  "MGLIQ": 100158,
  "PRECO": 100159,
  "OSCMIN": 100161,
  "OSCMAX": 100162,
  "TCKID": 100164,
  "STAMPGMT": 100165,
  "TCOT": 100166,
  "GMT": 100167,
  "CONTRIB": 100171,
  "TIPO": 100172,
  "CARTM": 100173,
  "DFEC": 100174,
  "DIVLIQ": 100175,
  "AFTFEC": 100176,
  "NOPE": 100177,
  "PTR": 100180,
  "PUCP": 100181,
  "PUVD": 100182,
  "QTR": 100183,
  "SITUAÇÃO": 100184,
  "TXCP": 100185,
  "VNEG": 100186,
  "VOLCP": 100187,
  "VOLVD": 100188,
  "YLDCP": 100189,
  "YLDVD": 100190,
  "NFONTE": 100194,
  "FONTE": 100195,
  "ATNEG": 100196,
  "ATUAL": 100197,
  "BHIST": 100198,
  "CONT": 100199,
  "NCPM": 100200,
  "DCP": 100201,
  "DCPM": 100202,
  "DEMIS": 100203,
  "DERIV": 100206,
  "DESCFULL": 100207,
  "ENTR": 100208,
  "HAFTER": 100209,
  "HNEG": 100210,
  "HCALLGMT": 100211,
  "CUPOM": 100212,
  "DELIVER": 100213,
  "LNEG": 100214,
  "PAIS": 100215,
  "PESSOA": 100216,
  "PRDEN": 100217,
  "RAIZ": 100218,
  "SISTEMA": 100219,
  "SITE": 100220,
  "TEMA": 100221,
  "INFO": 100222,
  "ULDEN": 100223,
  "VNACP": 100225,
  "ACM1A": 100227,
  "ACM1M": 100228,
  "ACMANO": 100229,
  "ACMMES": 100230,
  "ACMTRI": 100231,
  "ACM4TRI": 100232,
  "DELTA": 100236,
  "DCFIX": 100239,
  "DUFIX": 100240,
  "DIVYLDJ": 100249,
  "GAMA": 100250,
  "MEDCP": 100251,
  "MEDVD": 100252,
  "PA": 100266,
  "PEBIT": 100267,
  "PSR": 100268,
  "PRORATA": 100269,
  "RHO": 100270,
  "THETA": 100275,
  "PCT2A": 100276,
  "PCTTRIAA": 100278,
  "VARTRIA": 100279,
  "VEGA": 100280,
  "VLTH1S": 100283,
  "VLTH1A": 100284,
  "VLTH3M": 100285,
  "VLTIMPL": 100286,
  "VOLPROJ": 100287,
  "DCDD": 100288,
  "DUDD": 100289,
  "TIND": 100290,
  "CIDADE": 100291,
  "ESTADO": 100292,
  "LINHA": 100293,
  "FPNC": 100295,
  "DPNC": 100297,
  "TVMAC": 100298,
  "VARPIB": 100299,
  "REOPE": 100300,
  "TPMOEDA": 100322,
  "NOME": 100324,
  "MARKET": 100325,
  "LTFVAL": 100326,
  "VOLFAG": 100331,
  "PLC": 100335,
  "PVPAC": 100336,
  "PSRC": 100337,
  "PEBITC": 100338,
  "PAC": 100339,
  "EVC": 100340,
  "EVEBTDC": 100341,
  "EBITDAC": 100343,
  "SUBSET": 100350,
  "TCONTR": 100352,
  "QOCP": 100353,
  "QOVD": 100354,
  "DIFAJUA": 100355,
  "VARAJUA": 100356,
  "DULTCOT": 100357,
  "ULTCOT": 100358,
  "EMPRESA": 100359,
  "DLIQ": 100360,
  "UNID": 100361,
  "IMIN": 100362,
  "CODIGO_ORIGINAL": 100366,
  "TPAJUI": 100367,
  "QTTPROJ": 100370,
  "FECYLD": 100371,
  "VARYLD": 100372,
  "YLDCHG": 100374,
  "REGFEC": 100375,
  "REGVAR": 100376,
  "REGVARSEM": 100377,
  "REGVAR1SEM": 100378,
  "REGVARMES": 100379,
  "REGVAR1MES": 100380,
  "REGVARANO": 100381,
  "REGVAR1ANO": 100382,
  "CompanyId": 100383,
  "PAYOUTC": 100384,
  "VALORES": 100385,
  "PPAR": 100387,
  "BETA180": 100388,
  "BETA360": 100389,
  "PEBITDAC": 101125,
  "FFLOATAQ": 101126,
  "FFLOATAP": 101127,
  "FFLOATEQ": 101128,
  "FFLOATEP": 101129,
  "HULT": 900001,
  "STAMP": 900002,
  "HFLEI": 900003,
  "HOCP": 900004,
  "HOVD": 900005,
  "HCALL": 900008
}

export const ICONS_MAP = new Map()
ICONS_MAP.set("valor-investe", IcoValorInveste)
ICONS_MAP.set("Api ValorPro Prata", IcoValorPro)
ICONS_MAP.set("valor", IcoValorEconomico)
ICONS_MAP.set("pipelinevalor", IcoOrigemPipeline)
ICONS_MAP.set("valoroneinterno", IcoOrigemOne)

export const KEYS_API = {
  "MARGEM_LIQUIDA": 22,
  "MARGEM_BRUTA": 25,
  "MARGEM_OPERACIONAL": 26,
  "MARGEM_EBITDA": 38,
  "ROE": 81,
  "ROI": 113
}

export const PLANS = [
  {
    title: 'Grátis',
    code: 'VONEFRE',
    types: { year: null, month: null, subscriber: null },
    description: 'Realize seu cadastro e conheça as funcionalidades da nossa plataforma',
    functionalities: [
      {
        title: 'Super feed de notícias',
        content: null
      },
      {
        title: 'Ferramentas essenciais',
        content: ['Painel de cotações', 'Simuladores de investimentos', 'Vídeos e Podcasts', 'Cotação rápida', 'Calculadora de renda fixa', 'Gráficos de análise técnica']
      },
      {
        title: 'Cursos',
        content: ['Cursos de educação financeira']
      },
      {
        title: 'Recomendações',
        content: ['Carteira Valor (compilado de recomendação de bancos e corretoras)']
      }
    ]
  },
  {
    title: 'Basic',
    code: 'VONEBAS',
    types: {
      year: {
        label: 'plano anual',
        value: 29,
        link: 'https://assinaturaglobo.globo.com/valor-one/oferta/valor-one-basic-anual-1692297494196 '
      },
      month: {
        label: 'plano mensal',
        value: 59,
        link: 'https://assinaturaglobo.globo.com/valor-one/oferta/valor-one-basic'
      },
      subscriber: null
    },
    description: 'O pacote mínimo necessário para sua nova experiência como investidor',
    functionalities: [
      {
        title: 'Tudo do Grátis +',
        content: null
      },
      {
        title: 'Feed de notícias personalizado e conectado à sua carteira',
        content: null
      },
      {
        title: 'Ferramentas básicas',
        content: ['Resumo da bolsa', 'Mapa de mercado', 'Painel de cotações personalizado e conectado à sua carteira', 'Consolidador de carteira conectado à B3', 'Ranking Valor de fundos de investimento']
      },
      {
        title: 'Cursos',
        content: ['Cursos para iniciar no mercado de ações, entender quem é quem no mercado e como a macroeconomia afeta seus investimentos']
      },
      {
        title: 'Recomendações',
        content: ['Carteiras recomendadas por analistas certificados para alocação em renda fixa e renda variável', 'Consenso de analistas de mercado']
      }
    ]
  },
  {
    title: 'Standard',
    code: 'VONEINT',
    types: {
      year: {
        label: 'plano anual',
        value: 79,
        link: 'https://assinaturaglobo.globo.com/valor-one/oferta/valor-one-standard-anual-1696613818736'
      },
      month: {
        label: 'plano mensal',
        value: 129,
        link: 'https://assinaturaglobo.globo.com/valor-one/oferta/valor-one-standard'
      },
      subscriber: {
        label: 'plano assinante Valor Econômico',
        value: 19,
        link: 'https://assinaturaglobo.globo.com/valor-one/oferta/valor-one-standard-anual-1715007066672 '
      }
    },
    description: 'Tudo que você precisa para assumir o controle da sua vida financeira',
    functionalities: [
      {
        title: 'Tudo do Basic +',
        content: null
      },
      {
        title: 'Assinatura do Valor Econômico Digital',
        content: null
      },
      {
        title: 'Ferramentas intermediárias',
        content: ['Painel de cotações com dados de valuation e indicadores financeiros', 'Alertas personalizados para seus investimentos', 'Ações dos EUA (listadas na Nyse e Nasdaq)', 'Cotações de derivativos e minicontratos de dólar e índice futuro (BM&F)']
      },
      {
        title: 'Cursos',
        content: ['Cursos sobre Tesouro Direto, fundos de investimento e estratégia de montagem de carteira diversificada']
      },
      {
        title: 'Recomendações',
        content: ['Carteiras recomendadas por analistas certificados para investimento em ações e fundos imobiliários']
      }
    ]
  },
  {
    title: 'Advanced',
    code: 'VONEAVA',
    types: {
      year: {
        label: 'plano anual',
        value: 149,
        link: 'https://assinaturaglobo.globo.com/valor-one/oferta/valor-one-advanced-anual-1692296925690'
      },
      month: {
        label: 'plano mensal',
        value: 249,
        link: 'https://assinaturaglobo.globo.com/valor-one/oferta/valor-one-advanced'
      },
      subscriber: {
        label: 'plano assinante Valor Econômico',
        value: 49,
        link: 'https://assinaturaglobo.globo.com/valor-one/oferta/valor-one-advanced-anual-1715006888425 '
      }
    },
    description: 'Para ir além e ter informações e dados em tempo real para bater o mercado',
    functionalities: [
      {
        title: 'Tudo do Standard +',
        content: null
      },
      {
        title: 'Noticias do Valor PRO Prata',
        content: null
      },
      {
        title: 'Ferramentas avançadas',
        content: ['Bovespa e BM&F em tempo real', 'Índices da bolsa americana', 'Petróleo e Ouro', 'Consolidador de carteira com Darf']
      },
      {
        title: 'Cursos',
        content: ['Cursos de investimentos sobre criptomoedas e estratégias com opções']
      },
      {
        title: 'Recomendações',
        content: ['Carteiras recomendadas por analistas certificados com estratégia long biased em ações', 'Relatórios de analistas certificados de bancos, corretoras e casas de análise']
      }
    ]
  }
]

export const COL_ORDER = {
  1: 'left-[0.899%]',
  2: 'left-[1.798%]',
  3: 'left-[2.697%]',
  4: 'left-[3.596%]',
  5: 'left-[4.495%]',
  6: 'left-[5.394%]',
  7: 'left-[6.293%]',
  8: 'left-[7.192%]',
  9: 'left-[8.091%]',
  10: 'left-[8.990%]',
  11: 'left-[9.889%]',
  12: 'left-[10.788%]',
  13: 'left-[11.687%]',
  14: 'left-[12.586%]',
  15: 'left-[13.485%]',
  16: 'left-[14.384%]',
  17: 'left-[15.283%]',
  18: 'left-[16.182%]',
  19: 'left-[17.081%]',
  20: 'left-[17.980%]',
  21: 'left-[18.879%]',
  22: 'left-[19.778%]',
  23: 'left-[20.677%]',
  24: 'left-[21.576%]',
  25: 'left-[22.475%]',
  26: 'left-[23.374%]',
  27: 'left-[24.273%]',
  28: 'left-[25.172%]',
  29: 'left-[26.071%]',
  30: 'left-[26.970%]',
  31: 'left-[27.869%]',
  32: 'left-[28.768%]',
  33: 'left-[29.667%]',
  34: 'left-[30.566%]',
  35: 'left-[31.465%]',
  36: 'left-[32.364%]',
  37: 'left-[33.263%]',
  38: 'left-[34.162%]',
  39: 'left-[35.061%]',
  40: 'left-[35.960%]',
  41: 'left-[36.859%]',
  42: 'left-[37.758%]',
  43: 'left-[38.657%]',
  44: 'left-[39.556%]',
  45: 'left-[40.455%]',
  46: 'left-[41.354%]',
  47: 'left-[42.253%]',
  48: 'left-[43.152%]',
  49: 'left-[44.051%]',
  50: 'left-[44.950%]',
  51: 'left-[45.849%]',
  52: 'left-[46.748%]',
  53: 'left-[47.647%]',
  54: 'left-[48.546%]',
  55: 'left-[49.445%]',
  56: 'left-[50.344%]',
  57: 'left-[51.243%]',
  58: 'left-[52.142%]',
  59: 'left-[53.041%]',
  60: 'left-[53.940%]',
  61: 'left-[54.839%]',
  62: 'left-[55.738%]',
  63: 'left-[56.637%]',
  64: 'left-[57.536%]',
  65: 'left-[58.435%]',
  66: 'left-[59.334%]',
  67: 'left-[60.233%]',
  68: 'left-[61.132%]',
  69: 'left-[62.031%]',
  70: 'left-[62.930%]',
  71: 'left-[63.829%]',
  72: 'left-[64.728%]',
  73: 'left-[65.627%]',
  74: 'left-[66.526%]',
  75: 'left-[67.425%]',
  76: 'left-[68.324%]',
  77: 'left-[69.223%]',
  78: 'left-[70.122%]',
  79: 'left-[71.021%]',
  80: 'left-[71.920%]',
  81: 'left-[72.819%]',
  82: 'left-[73.718%]',
  83: 'left-[74.617%]',
  84: 'left-[75.516%]',
  85: 'left-[76.415%]',
  86: 'left-[77.314%]',
  87: 'left-[78.213%]',
  88: 'left-[79.112%]',
  89: 'left-[80.011%]',
  90: 'left-[80.910%]',
  91: 'left-[81.809%]',
  92: 'left-[82.708%]',
  93: 'left-[83.607%]',
  94: 'left-[84.506%]',
  95: 'left-[85.405%]',
  96: 'left-[86.304%]',
  97: 'left-[87.203%]',
  98: 'left-[88.102%]',
  99: 'left-[89.001%]',
  100: 'left-[89.10%]',
}


export const RANKING_STARS = [
  {
    img: FourStars,
    description: ' – 25% melhores da categoria conforme os critérios do Ranking Valor - Consistência'
  },
  {
    img: ThreeStars,
    description: ' – 50% melhores da categoria conforme os critérios do Ranking Valor - Consistência'
  },
  {
    img: TwoStars,
    description: ' – 75% melhores da categoria conforme os critérios do Ranking Valor - Consistência'
  },
  {
    img: OneStar,
    description: ' – 100% melhores da categoria conforme os critérios do Ranking Valor - Consistência'
  },
]

export const RANKING_CATEGORY = [
  {
    img: CategoryTen,
    description: ' –  10% melhores da categoria na última janela de 36 meses* '
  },
  {
    img: CategoryNine,
    description: ' –  20% melhores da categoria na última janela de 36 meses* '
  },
  {
    img: CategoryEight,
    description: ' –  30% melhores da categoria na última janela de 36 meses* '
  },
  {
    img: CategorySeven,
    description: ' –  40% melhores da categoria na última janela de 36 meses* '
  },
  {
    img: CategorySix,
    description: ' –  50% melhores da categoria na última janela de 36 meses* '
  },
  {
    img: CategoryFive,
    description: ' –  60% melhores da categoria na última janela de 36 meses* '
  },
  {
    img: CategoryFour,
    description: ' –  70% melhores da categoria na última janela de 36 meses* '
  },
  {
    img: CategoryThree,
    description: ' –  80% melhores da categoria na última janela de 36 meses* '
  },
  {
    img: CategoryTwo,
    description: ' –  90% melhores da categoria na última janela de 36 meses* '
  },
  {
    img: CategoryOne,
    description: ' –  100% melhores da categoria na última janela de 36 meses* '
  },
]

export const USAGE_GUIDE = [
  {
    title: 'Primeiros passos',
    description: 'Clique em “adicionar módulo” e pronto. Basta escolher seus módulos favoritos, redimensioná-los e arrastá-los para a posição em que você quer vê-los na tela inicial, e ela ficará salva automaticamente quando você voltar, podendo ser redesenhada sempre que você quiser.',
    img: {
      mobile: GuideHome,
      desktop: GuideHome2
    },
    link: 'https://www.youtube-nocookie.com/embed/jKO0MpiiEwI?autoplay=1&rel=0&fs=0'
  },
  {
    title: 'Aproveite ao máximo',
    description: 'Para ter a melhor experiência possível dentro do Valor One, carregue seus investimentos no nosso consolidador de carteira — ações, BDRs, Flls e títulos públicos que estiverem na B3 aparecem automaticamente, mas é possível cadastrar fundos de investimento e CDBs manualmente — e receba, em troca, um serviço personalizado de informações na aba “Minhas notícias”, painel e alertas de acordo com seus investimentos.',
    img: {
      mobile: GuideIphone,
      desktop: GuideIphone2
    },
    link: 'https://www.youtube-nocookie.com/embed/XhHJTxRJatw?autoplay=1&rel=0&fs=0'
  },
  {
    title: 'Aprenda com especialistas',
    description: 'Nossa prateleira de cursos exclusivos traz conteúdos que ensinam desde organização financeira, para quem quer começar a investir, até estratégias com opções e criptomoedas, passando também por renda fixa, primeiros passos na bolsa e, claro, explicam também como montar uma carteira de investimentos balanceada.',
    img: {
      mobile: GuideCourses,
      desktop: GuideCourses2
    },
    link: 'https://www.youtube-nocookie.com/embed/TfUJUqXtlCM?autoplay=1&rel=0&fs=0'
  },
  {
    title: 'Acompanhe sua carteira',
    description: 'Além do próprio consolidador de carteira, nossa ferramenta de painel permite que você acompanhe de perto tanto os investimentos que você já possui no painel “Minha Carteira”, como aqueles em que você está de olho, por meio dos “Favoritos”.',
    img: {
      mobile: GuidePanel,
      desktop: GuidePanel2
    },
    link: 'https://www.youtube-nocookie.com/embed/VMQk-q1eFu0?autoplay=1&rel=0&fs=0'
  },
  {
    title: 'Compare seus fundos',
    description: 'Crie uma lista com os fundos de investimento em que você aplica ou que te ofereceram e veja como nossa ferramenta exclusiva avalia esses produtos com base na consistência de desempenho de longo prazo, atribuindo mais estrelas no Ranking Valor para aqueles com melhor resultado dentro da mesma categoria numa análise combinada de retorno e risco.',
    img: {
      mobile: GuideCompareFundos,
      desktop: GuideCompareFundos2
    },
    link: 'https://www.youtube-nocookie.com/embed/SC7rEf-I8ZA?autoplay=1&rel=0&fs=0'
  },
  {
    title: 'Siga os movimentos de mercado',
    description: 'Nossas ferramentas Resumo da Bolsa e Mapa de Mercado, com cores e gráficos interativos, são perfeitas para sentir o pulso da bolsa brasileira em qualquer horário do dia. Já com a aba Visão Geral, dentro do Painel, você acompanha Ibovespa, dólar, S&P 500, Dow Jones, Nasdaq, os juros futuros e outros indicadores essenciais para qualquer investidor.',
    img: {
      mobile: GuideMarket,
      desktop: GuideMarket2
    },
    link: 'https://www.youtube-nocookie.com/embed/T9dcCoHs6AA?autoplay=1&rel=0&fs=0'
  },
  {
    title: 'Superfeed de notícias',
    description: 'Não perca nenhuma novidade do dia com nosso superfeed de notícias que reúne os conteúdos produzidos por Valor Econômico, Valor Investe, Pipeline e Valor PRO e ainda traz conteúdos em vídeo e podcasts do Valor.',
    img: {
      mobile: GuideNew,
      desktop: GuideNew2
    },
    link: 'https://www.youtube-nocookie.com/embed/wBs2MdWvr3g?autoplay=1&rel=0&fs=0'
  },
  {
    title: 'Faça seus planos',
    description: 'Nossos simuladores permitem que você trace suas metas financeiras, saiba quanto tem que economizar por mês para alcançá-las, compare que investimento de renda fixa vai render mais e também planeje quanto precisa guardar para viver de renda.',
    img: {
      mobile: GuideInvestmentSimulator,
      desktop: GuideInvestmentSimulator2
    }
  },
  {
    title: 'Recomendações de investimento',
    description: 'Com a ferramenta “Cotação Rápida” você encontra as recomendações de investimentos dos analistas dos principais bancos e corretoras sobre quais ações você deve comprar, manter ou vender, bem como o preço-médio alvo projetado por esses especialistas certificados.',
    img: {
      mobile: GuideConsensus,
      desktop: GuideConsensus2
    },
    link: 'https://www.youtube-nocookie.com/embed/kSez4lExI9w?autoplay=1&rel=0&fs=0'
  },
  {
    title: 'Carteiras exclusivas',
    description: 'Além da Carteira Valor, que reúne as carteiras recomendadas de quase 20 corretoras de mercado, no Valor One você tem acesso a carteiras recomendadas exclusivas preparadas por analistas certificados com base em dados de tendência de mercado (fator Momentum).',
    img: {
      mobile: GuideWallet,
      desktop: GuideWallet2
    }
  }
]

export const FUNDS_TOOLTIPS = {
  'Ranking Valor - Últimos 36 meses': 'Desempenho mais recente - Ordena os fundos da mesma categoria por uma combinação de rentabilidade e retorno ajustado ao risco nos últimos 36 meses* (Ex. fundos com 10 barras roxas estão entre os 10% melhores da categoria no período). Ver metodologia completa.',
  'Risco': 'Nível de risco do fundo medido pelo desvio-padrão em 36 meses*. Mostra quanto a rentabilidade do fundo pode se distanciar da média obtida em um ano. Quanto maior mais arriscado.',
  'Rentabilidade no mês anterior': 'Rentabilidade no mês anterior* - em %. Já desconta as taxas, mas não o Imposto de Renda.',
  'Rentabilidade em 36 meses': 'Rentabilidade acumulada em 36 meses*, anualizada - em % ao ano. Já desconta as taxas, mas não o Imposto de Renda.',
  'Rent. anual med. 60M': 'Rentabilidade acumulada em 60 meses*, anualizada - em % ao ano. Já desconta as taxas, mas não o Imposto de Renda.',
  'Rent. no ano': 'Rentabilidade acumulada no ano* - em %. Já desconta as taxas, mas não o Imposto de Renda.',
  'Rentabilidade em 12 meses': 'Rentabilidade acumulada em 12 meses* - em %. Já desconta as taxas, mas não o Imposto de Renda.',
  'Ranking Valor - Consistência': 'Consistência de longo prazo - Ordena os fundos da mesma categoria por consistência de desempenho em várias janelas temporais*. (Ex. fundos com 4 estrelas estão entre os 25% melhores da categoria por esse critério). Ver metodologia completa.',
  'Prazo de resgate': 'Prazo para o dinheiro cair na conta, em dias (úteis ou corridos), após pedido de resgate.',
  'Taxa de adm a.a.': 'Taxa máxima cobrada do cotista pela gestão do fundo, ao ano, sobre o saldo médio aplicado no período - já está descontada da rentabilidade apresentada.',
  'Taxa de performance': 'Taxa cobrada sobre a diferença de rentabilidade do fundo e o indicador de referência (CDI, Ibovespa etc) definido em regulamento, como um prêmio que recompensa a performance superior obtida - já está descontada da rentabilidade apresentada.',
  'Categoria': 'Categoria de fundo definida pelo Guia de Fundos do Valor. Ver metodologia completa.',
  'Aplicação inicial mínima': 'Valor mínimo a ser aplicado pela primeira vez para entrar no fundo.',
  'Patrimônio': 'Valor total aplicado pelos cotistas no fundo de investimento.',
  'Cotistas': 'Número de cotistas que aplicam no fundo.',
  'Renda Fixa DI': 'Inclui fundos de "renda fixa" mais conservadores, que acompanham de perto o CDI e a taxa Selic ou a variação dos títulos prefixados de até um ano. A categoria exclui os fundos que tem o sufixo “crédito privado” no nome.',
  'Prefixado Renda Fixa Ativo': 'Inclui fundos de “renda fixa” que costumam ter correlação menor com o CDI e variam mais próximos dos títulos do Tesouro Prefixado e do índice de renda fixa prefixada calculado pela Anbima, IRF-M, ou que adotam declaradamente estratégias de gestão ativa.',
  'Juro Real': 'Inclui fundos de “renda fixa” que costumam ter correlação menor com o CDI e variam mais próximos dos títulos do Tesouro IPCA+ e dos índices da Anbima para títulos indexados ao IPCA, como IMAB, IMAB-5 ou IMAB-5+.',
  'Crédito Privado Até 15 Dias': 'Inclui fundos de “renda fixa” que emprestam mais dinheiro a bancos e empresas do que ao Tesouro Nacional, em busca de um retorno maior. Entram no grupo os fundos com o sufixo “crédito privado”, e os fundos “multimercado” com as seguintes características: baixa variação das cotas diárias, com o sufixo “crédito privado” e carteira composta majoritariamente por títulos privados, mas com parcela inferior a 40% em debêntures e Fundos de Investimento em Direitos Creditórios (FIDCs). O resgate solicitado pelo cotista é pago no prazo de até 15 dias.',
  'Crédito Privado Acima de 16 Dias': 'Inclui fundos de “renda fixa” que emprestam mais dinheiro a bancos e empresas do que ao Tesouro Nacional, em busca de um retorno maior. Entram no grupo os fundos com o sufixo “crédito privado” e os fundos “multimercado” com baixa variação das cotas diárias, com o sufixo “crédito privado” e carteira composta majoritariamente por títulos privados. O resgate solicitado pelo cotista é pago em 16 dias ou mais.',
  'Debênture Incentivada': 'Inclui fundos de "renda fixa" de um tipo específico entre aqueles de crédito privado, se identificam como sendo de debêntures incentivadas (que emprestam dinheiro a empresas que emitem esses papéis) e, consequentemente, não estão sujeitos ao Imposto de Renda sobre os rendimentos.',
  'Multimercado Baixa Volatilidade': 'Inclui fundos “multimercados” (que podem operar em mercados de renda fixa, renda variável e moedas) de menor risco entre os multimercados. Entram no grupo os fundos sem o sufixo "crédito privado" e que tiveram perdas mensais muito pequenas ou inexistentes no período analisado e volatilidade relativamente baixa, inferiores à do índice IRF-M.',
  'Multimercado': 'Inclui fundos “multimercados” que podem adotar estratégias de investimento variadas nos mercados e renda fixa, renda variável e moedas, e não foram enquadrados em outras subcategorias.',
  'Long & Short': 'Inclui fundos que afirmam seguir uma estratégia “long and short”. A estratégia envolve a manutenção de posições compradas — que apostam na alta (long, em inglês) — e vendidas — que apostam na baixa (short, em inglês) — em ativos semelhantes.',
  'Long Biased': 'Inclui fundos “multimercado” ou “ações” que se apresentam como seguindo uma estratégia “long biased”. Geralmente são carteiras que podem ficar integralmente compradas em ações (apostando na alta), mas que consideram fazer operações de proteção contra perdas (posições vendidas, apostando na baixa), em determinados momentos. O viés (bias, em inglês) é mais comprado.',
  'Ações Índice': 'Inclui fundos de “ações” chamados de passivos, que seguem o Índice Bovespa de perto. No período analisado, registraram as estatísticas “tracking error” de até 4% ao ano, “correlação” com o Ibovespa acima de 98% e “beta” entre 0,95 e 1,03.',
  'Ações': 'Inclui fundos de “ações” que tentam superar o retorno do Ibovespa. Inclui fundos com “tracking error” acima de 4% ao ano, o que os distancia dos retornos do Ibovespa. Na prática, possuem uma ampla variedade de estratégias no mercado de renda variável.',
  'Investimento no Exterior': 'Inclui fundos que possuem no nome o sufixo “IE”, de investimento no exterior, ou que aplicam em ativos ou fundos de investimentos no exterior. Podem ser de renda fixa ou renda variável, indexados ao real ou a moedas estrangeiras.',
  'Ações no Exterior': 'Inclui fundos “multimercados” ou fundos de “ações” que investem no exterior por meio de “Brazilian Depositary Receipts” (BDRs), ETFs ou operações de derivativos.',
  'Alocação Multimercado': 'Inclui fundos que investem em outros fundos “multimercados” para montar uma carteira balanceada.',
  'Alocação Ações': 'Inclui fundos que investem em outros fundos de “ações” para montar uma carteira balanceada.',
  'Taxa de adm. a.a. e performance': 'Taxa de administração é cobrada do cotista pela gestão do fundo, ao ano, sobre o saldo médio aplicado no período. A de performance é cobrada sobre a diferença de rentabilidade do fundo e o indicador de referência (CDI, Ibovespa etc) definido em regulamento, como um prêmio que recompensa a performance superior obtida. Ambas já estão descontadas da rentabilidade apresentada.'
}

export const QUOTATION_TOOLTIPS = {
  'Preço': 'Último preço disponível do ativo (inclui after market no caso de ações). Mostra taxas no caso de juros futuros e Tesouro Direto.',
  'Var. Dia': 'Variação percentual do preço no dia.',
  'Var. Dia (pts)': 'Variação absoluta (em reais, pontos de índice, dólares, pontos percentuais etc) no dia.',
  'Min.': 'Preço mínimo durante o dia.',
  'Max.': 'Maior preço durante o dia.',
  'Compra': 'Melhor oferta de compra.',
  'Venda': 'Melhor oferta de venda.',
  'Negócios': 'Quantidade de negócios durante o dia com o ativo.',
  'Volume Financeiro': 'Volume financeiro, na moeda correspondente, com o ativo no dia.',
  'Var. Ano': 'Variação percentual do preço no ano corrente até o dia.',
  'Var. 12 meses': 'Variação percentual do preço em 12 meses até o dia.',
  'Valor de Mercado': 'Soma do valor de todas as ações da empresa, conforme a cotação mais recente.',
  'Retorno com Dividendos': 'Dividend Yield - Rentabilidade percentual dos dividendos e juros sobre capital próprio pagos por uma empresa em 12 meses em relação ao preço da ação.',
  'Preço/Lucro': 'Medida simples usada para avaliações comparativas de empresas mais "caras ou baratas". Compara o valor de mercado com o lucro líquido e indica o tempo esperado de retorno de um investimento. Quanto maior, mais cara (valorizada) a empresa.',
  'EV/EBITDA': 'Medida simples usada para avaliações comparativas de empresas mais "caras ou baratas". Compara o valor da empresa (Enterprise Value), que é a soma do valor de mercado com a dívida líquida, com o Ebitda, uma métrica de geração de caixa potencial da empresa, em 12 meses. Quanto maior, mais cara (valorizada) a empresa.',
  'Preço/Valor Patrimonial': 'Medida simples usada para avaliações comparativas de empresas mais "caras ou baratas". Compara o valor de mercado da empresa com o capital dos acionistas (PL) contábil. Quanto maior, mais cara (valorizada) a empresa.',
  'Retorno Sobre o Patrimônio': 'Medida de rentabilidade que mede relação percentual entre o lucro líquido e o capital investido pelos acionistas (Patrimônio Líquido), também conhecida pela sigla em inglês RoE, de Return on Equity. Quanto maior, melhor. Dados se referem aos últimos 12 meses, conforme o balanço publicado por cada empresa.',
  'Retorno Sobre Bens e Direitos': 'Medida de rentabilidade que mede relação percentual entre o lucro operacional e o total de bens e direitos que a empresa controla (ativo total), também conhecida pela sigla em inglês como RoA, de Return on Assets. Quanto maior, melhor. Dados se referem aos últimos 12 meses, conforme o balanço publicado por cada empresa.',
  'Margem Bruta': 'Medida de rentabilidade que mede a relação percentual entre o lucro bruto e a receita líquida, ou seja, quantos reais sobram de lucro bruto para a empresa a cada R$ 100 de receita. Quanto maior, melhor. Dados se referem aos últimos 12 meses, conforme o balanço publicado por cada empresa.',
  'Margem EBITDA': 'Medida de rentabilidade que mede a relação percentual entre o Ebitda, uma métrica de geração de caixa potencial da empresa, e a receita líquida, ou seja, quantos reais sobram de Ebitda para a empresa a cada R$ 100 de receita. Quanto maior, melhor. Dados se referem aos últimos 12 meses, conforme o balanço publicado por cada empresa.',
  'Margem Operacional': 'Medida de rentabilidade que mede a relação percentual entre o lucro operacional e a receita líquida, ou seja, quantos reais sobram de lucro operacional para a empresa a cada R$ 100 de receita. Quanto maior, melhor. Dados se referem aos últimos 12 meses, conforme o balanço publicado por cada empresa.',
  'Margem Líquida dos Acionistas': 'Medida de rentabilidade que mede a relação percentual entre o lucro líquido e a receita líquida, ou seja, quantos reais sobram de lucro líquido para os acionistas a cada R$ 100 de receita. Quanto maior, melhor. Dados se referem aos últimos 12 meses, conforme o balanço publicado por cada empresa.'
}