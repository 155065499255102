function Card({ children, style={}, className }) {

  return (
    <div style={style} className={`
      bg-white
      rounded-md
      flex
      shadow-md
      flex-col
      p-4
      flex-grow
      ${className || ''}
    `}>
      { children }
    </div>
  )
}

export default Card