import { FundsContext } from 'context/FundsContext'
import { useContext, useState } from "react"
import { MdKeyboardArrowRight } from "react-icons/md"
import Modal from "react-modal"
import { toast } from "react-toastify"
import CloseButton from "shared/CloseButton"
import NotificationModal from 'shared/modal/NotificationModal'
import iconPanelDelete from 'assets/icons/panel/botao-excluir.svg'
import iconPanelEdit from 'assets/icons/panel/botao-editar.svg'
import useWindowSize from "hooks/useWindowSize"
import { LAYOUT_BREAKPOINT_WIDTH_MOBILE } from 'const'

export function ModalManageFunds({ isOpen = true, closeModal, createNewList, editList }) {

  const { width } = useWindowSize()
  const { listPanel, favoriteFundList, formattedListEditing, deleteListPanel, updatedListFunds, setSelectedCategory } = useContext(FundsContext)
  const [isOpenNotification, setIsOpenNotification] = useState({ status: false })
  const isMobile = width < LAYOUT_BREAKPOINT_WIDTH_MOBILE

  const selectList = (list) => {
    updatedListFunds(list)
    setSelectedCategory(list)
    closeModal()
  }

  function listEditing(list) {
    formattedListEditing(list)
    editList()
  }

  const removeList = async (list) => {
    try {
      await deleteListPanel(list)
      setIsOpenNotification({ status: false })
      closeModal()
      toast.success(`A lista ${list.panelName} foi deletada com sucesso!`)
    } catch (error) {
      toast.error('Não foi possível deletar a lista...')
    }
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={{
      content: { 
        maxWidth: 648,
        borderRadius: 12,
        margin: "auto",
        marginRight: isMobile ? -40 : "auto",
        marginLeft: isMobile ? -40 : "auto"
      }
    }}>
      <CloseButton className='absolute right-5 top-5 z-50' size={20} color={'#472F92'} onClick={closeModal} />
      <div className="relative flex flex-col h-full">
        <div className="mb-5">
          <h2 className="font-bold text-xl text-primary uppercase mb-2">Gerenciar Listas</h2>
          <p className="font-semibold text-base text-primary uppercase">Renomeie ou exclua as suas listas</p>
        </div>
        <div className="p-2">
          {
            listPanel?.map(list => favoriteFundList?.id !== list.id && (
              <div className="flex text-xs items-center space-x-2 py-2" key={list.id}>
                <div className="flex-1 text-primary font-bold space-x-2 flex items-center opacity-90 hover:opacity-100">
                  {list.panelName}
                </div>
                <div className="flex space-x-5">
                  <MdKeyboardArrowRight className="text-xl cursor-pointer text-primary" title="Visualizar painel" onClick={() => selectList(list)} />
                  <button className="text-primary text-xl opacity-90 hover:opacity-100 cursor-pointer" title="Editar painel" onClick={() => listEditing(list)}>
                    <img src={iconPanelEdit} alt="icone de editar" />
                  </button>
                  <button
                    onClick={() => setIsOpenNotification({ status: true, list })}
                    className={`text-loss text-xl opacity-90 cursor-pointer hover:opacity-100`}
                    title="Remover painel"
                  >
                    <img src={iconPanelDelete} alt="icone de excluir" />
                  </button>
                </div>
              </div>
            ))
          }
        </div>
        <div className='flex justify-center'>
          <button className="btn btn-primary m-2 text-secondary" onClick={createNewList}>Criar uma nova lista</button>
        </div>
      </div>
      <NotificationModal
        isOpen={isOpenNotification.status}
        closeModal={() => setIsOpenNotification({ status: false })}
        title={`Confirma a exclusão da lista ${isOpenNotification?.list?.panelName}?`}
        style={{ content: { maxWidth: 460, maxHeight: width < 500 ? 360 : 260, borderRadius: 10, margin: "auto" } }}
        successModal={() => removeList(isOpenNotification.list)}
        actionButtons />
    </Modal >
  )

}