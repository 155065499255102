import { useEffect } from "react"

function useClickout(elementRefs, callback) {

  if (!Array.isArray(elementRefs)) elementRefs = [elementRefs]

  useEffect(() => {
    const fnClickout = ev => {
      let el = ev.target, clickIn = false
      while (el) {
        clickIn = elementRefs.some(ref => el === ref.current)
        if (clickIn) return
        el = el.parentNode
      }
      callback(!clickIn)
    }

    if (elementRefs.every(x => x?.current))
      document.addEventListener('click', fnClickout)
    return () => document.removeEventListener('click', fnClickout)
  }, [elementRefs, callback])
}

export default useClickout