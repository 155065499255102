export class FetchError extends Error {

  constructor(response, responseBody) {

    super(response?.message || responseBody?.message || null)
    this.name = 'FetchError'
    this.status = response.status
    this.code = response?.message || responseBody?.code || null
  }

}

function handleMessage(error) {
  if (error instanceof SyntaxError) {
    return "Não foi possível interpretar a resposta do servidor. Consulte o administrador do sistema."
  } else {
    return "Não foi possível estabelecer uma comunicação com o servidor. Consulte sua conexão ou informe o administrador do sistema."
  }
}
export class InternalFetchError extends Error {

  constructor(error) {
    super(handleMessage(error || null))
    this.name = 'InternalFetchError'
  }

}
