import { createContext, useState } from 'react'

const SimulatorContext = createContext({
  selectedOption: null,
  onSelectedOption: () => { },
  result: null,
  isFirstDisplay: true,
  onSetDisplay: () => { }
})

export const SelectedOptionEnum = {
  INITIAL: 0,
  COMPARE_INTEREST: 1,
  GOAL_VALUE_CALCULATOR: 2,
  GOAL_TIME_CALCULATOR: 3,
  INCOME_CALCULATOR: 4
}

export function SimulatorContextProvider({ children, ...props }) {
  const [isFirstDisplay, setFirstDisplay] = useState()
  const [selectedOption, setSelectedOption] = useState()
  const [result, setResult] = useState()

  const onSelectOption = (id) => {
    setSelectedOption(id)
  }

  const onSetDisplay = () => {
    if (!selectedOption)
      setFirstDisplay(false)
  }

  return (
    <SimulatorContext.Provider value={{
      result,
      onSelectOption,
      selectedOption,
      isFirstDisplay,
      onSetDisplay,
    }} >
      {children}
    </SimulatorContext.Provider>
  )
}

export default SimulatorContext