import useClickout from "hooks/useClickout"
import { forwardRef } from "react"
import { createRef, useState } from "react"
import { MdOutlineMoreVert } from "react-icons/md"

function WidgetContextualMenu({...props}) {

  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const dropdownRef = createRef()
  const dropdownTriggerRef = createRef()
  useClickout([dropdownTriggerRef, dropdownRef], clickout => {
    if (clickout) setDropdownOpen(false)
  })

  return (
    <>
      <span
        onClick={() => setDropdownOpen(true)}
        onTouchEnd={() => setDropdownOpen(true)}
        ref={dropdownTriggerRef}
        className="absolute right-2 top-4 text-2xl text-primary cursor-pointer">
        <MdOutlineMoreVert />
      </span>
      {isDropdownOpen && <ContextualMenuOptions {...props} ref={dropdownRef} />}
    </>
  )
}

const ContextualMenuOptions = forwardRef(({ options = [], removeItem }, ref) => {
  return (
    <div ref={ref} className="fixed bg-white shadow right-8 top-1 rounded-sm text-danger border" style={{ zIndex: 10000 }}>
      {options.map(opt => <ContextualMenuOption key={'ctx-menu-opt-' + opt.label} {...opt} />)}
      {removeItem && <ContextualMenuOption label="Excluir módulo" styleClass={options?.length && 'border-t'} onClick={removeItem} />}
    </div>
  )
})

const ContextualMenuOption = ({ label, onClick, styleClass = '' }) => {
  return (
    <div onClick={onClick} onTouchEnd={onClick} className={`text-xs font-semibold hover:bg-gray-100 cursor-pointer py-2 px-2 text-primary ${styleClass}`}>{label}</div>
  )
}

export default WidgetContextualMenu