import { cloneElement } from "react"
import { createContext, useCallback, useContext, useEffect, useState } from "react"
import { SimpleTooltip } from "shared/HelpTooltip"
import { useCustomId } from "hooks/useCustomId"
import { memoize } from "lodash"

const { MdArrowDropUp, MdArrowDropDown } = require("react-icons/md")

export const OrderByColumnContext = createContext({
  orderBy: null,
  sortedData: [],
  setOrderBy: (orderBy) => { },
})

export function OrderByColumnTrigger({ children, columnName }) {
  const { orderBy, setOrderBy } = useContext(OrderByColumnContext)
  return cloneElement(children, { onClick: () => setOrderBy({ key: columnName, order: (orderBy.key === columnName && orderBy.order === -1) ? 1 : -1 }) })
}

export function OrderByColumnDisplay({ classNameTooltip, columnName, tooltip, onClick }) {
  const id = useCustomId('OrderByColumnDisplay')
  const { orderBy } = useContext(OrderByColumnContext)

  return (
    <>
      <div onClick={onClick} className={`flex flex-col`} id={id}>
        <MdArrowDropUp className={`text-xl ${(orderBy.key === columnName && orderBy.order === 1) ? 'text-primary' : 'text-[#BEBEBE]'}`} />
        <MdArrowDropDown className={`text-xl -mt-3 ${(orderBy.key === columnName && orderBy.order === -1) ? 'text-primary' : 'text-[#BEBEBE]'}`} />
      </div>
      {id && tooltip && (
        <SimpleTooltip className={classNameTooltip} anchor={`#${id}`} value={tooltip} />
      )}
    </>
  )
}

export function OrderByColumnContextProvider({ children, data = [], defaultColumn, defaultOrder = 1 }) {

  const [orderBy, setOrderBy] = useState({ key: defaultColumn, order: defaultOrder })
  const [sortedData, setSortedData] = useState(data)

  const sortFn = useCallback(({ key, order, arr }) => {
    return memoize((key, order, arr) => {
      return [...(arr || [])].sort((a, b) => {

        if (!a[key] && isNaN(parseInt(a[key]))) return Infinity      
        if (!b[key] && isNaN(parseInt(b[key]))) return -Infinity      
        if (typeof a[key] === 'number' || typeof b[key] === 'number') {
          return (a[key] - b[key]) * order
        }
        const ASC = -1 * order, DESC = 1 * order
        return a[key] === b[key] ? 0 : (a[key] > b[key] ? DESC : ASC)
      })
    })(key, order, arr)
  }, [])

  useEffect(() => {
    if (!orderBy.key) {
      setSortedData(data)
      return
    }
    const orderedData = sortFn({ ...orderBy, arr: data })
    setSortedData(orderedData)
  }, [data, orderBy])

  return (
    <OrderByColumnContext.Provider value={{ orderBy, setOrderBy, sortedData }}>
      {children}
    </OrderByColumnContext.Provider>
  )
}