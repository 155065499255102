const formartter = new Intl.DateTimeFormat('pt-BR');

export function dateStringToLocaleString(dateString) {
  if (typeof dateString !== 'string') return ''
  const date = new Date(dateString)
  if (Number.isNaN(date.getDate())) return ''
  return date.toLocaleString()
}

export function dateStringToLocaleDateString(dateString) {
  if (typeof dateString !== 'string') return ''
  const date = new Date(dateString)
  return dateToLocaleDateString(date)
}

export function dateToLocaleDateString(date) {
  if (Number.isNaN(date.getDate())) return ''
  return formartter.format(date)
}

export function dateIsValid(date) {
  return date instanceof Date && !isNaN(date)
}