import { useMemo } from "react"

export function useEvents() {

  const eventMap = useMemo(() => new Map())

  const on = (eventName, callback) => {
    if (typeof eventName !== 'string' || !eventName)
      throw new Error('EventName must be a string!')
    if (typeof callback !== 'function')
      throw new Error('Callback must be a funcion!')
 
    const callbackList = eventMap.get(eventName)
    if (callbackList)
      callbackList.push(callback)
    else
      eventMap.set(eventName, [callback])
  }

  const off = (eventName, callback) => {
    if (typeof eventName !== 'string' || !eventName)
      throw new Error('EventName must be a string!')
    if (typeof callback !== 'function')
      throw new Error('Callback must be a funcion!')

    const callbackList = eventMap.get(eventName)
    if (!callbackList) return
    const indexFound = callbackList.findIndex(x => x === callback)
    if (indexFound < 0) return
    callbackList.splice(indexFound, 1)
  }

  const send = (eventName, data) => {
    if (typeof eventName !== 'string' || !eventName)
      throw new Error('EventName must be a string!')

    const callbackList = eventMap.get(eventName)
    if (!callbackList?.length) return

    for (const callback of callbackList)
      callback(data)
  }

  return {
    on,
    off,
    send
  }

}