import { memoize } from "lodash"
import { BASE_URL, memoizeResolver, SearchParams, getApiHeaders, customFetch } from "./settings"
import { FetchError, InternalFetchError } from "errors/FetchError"

const URL_FUNDS = 'investmentfunds'

const Api = {

  getFunds: memoize(async function (args, limit) {
    let argsFormatted = args
    if (args[2] === '.' && args[6] === '.' && args[10] === '/' && args[15] === '-') {
      argsFormatted = args.replace(/\.|-|\//gm, '')
    }
    const searchParams = SearchParams({ FundName: argsFormatted, Top: limit })
    return customFetch(`${BASE_URL}/${URL_FUNDS}?${searchParams}`, {
      method: 'GET',
      headers: getApiHeaders()
    })
      .then((response) => {
        if (response.status === 200) return response.json()
        return []
      })
  }, memoizeResolver),

  getFundId: memoize(async function (id) {
    return customFetch(`${BASE_URL}/${URL_FUNDS}/${id}`, {
      method: 'GET',
      headers: getApiHeaders()
    })
      .then((response) => {
        if (response.status === 200) return response.json()
        return []
      })
  }, memoizeResolver),

  getCategories: memoize(async function () {
    return customFetch(`${BASE_URL}/${URL_FUNDS}/categorys`, {
      method: 'GET',
      headers: getApiHeaders()
    })
      .then((response) => {
        if (response.status === 200) return response.json()
        return []
      })
  }, memoizeResolver),

  getBankAndPlataforms: memoize(async function () {
    return customFetch(`${BASE_URL}/${URL_FUNDS}/bankandplataforms`, {
      method: 'GET',
      headers: getApiHeaders()
    })
      .then((response) => {
        if (response.status === 200) return response.json()
        return []
      })
  }, memoizeResolver),

  getFilterId: memoize(async function (body) {
    return customFetch(`${BASE_URL}/${URL_FUNDS}/filter`, {
      method: 'POST',
      headers: getApiHeaders(),
      body: JSON.stringify(body)
    })
      .then((response) => {
        if (response.status === 200) return response.json()
        return []
      })
  }, memoizeResolver),

  getPanel: memoize(async function () {
    return customFetch(`${BASE_URL}/${URL_FUNDS}/painel`, {
      method: 'GET',
      headers: getApiHeaders()
    })
      .then((response) => {
        if (response.status === 200) return response.json()
        return []
      })
  }, memoizeResolver),

  postPanel: memoize(async function (body) {
    try {
      const res = await customFetch(`${BASE_URL}/${URL_FUNDS}/painel`, {
        method: 'POST',
        headers: getApiHeaders(),
        body: JSON.stringify(body)
      })
      const resBody = await res.json()
      if (!res.ok) throw new FetchError(res, resBody)

      return resBody
    } catch (error) {
      if (error instanceof FetchError) throw error
      throw new InternalFetchError(error)
    }
  }, memoizeResolver),

  putPanel: memoize(async function (body) {
    return customFetch(`${BASE_URL}/${URL_FUNDS}/painel`, {
      method: 'PUT',
      headers: getApiHeaders(),
      body: JSON.stringify(body)
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.status)
        }
        return response.json()
      })
  }, memoizeResolver),

  deletePanel: memoize(async function ({ id }) {
    await customFetch(`${BASE_URL}/${URL_FUNDS}/painel?id=${id}`, {
      method: 'DELETE',
      headers: getApiHeaders(),
    })
      .then((response) => {
        if (response.status === 200) return response.json()
        return []
      })
  }, memoizeResolver),

  getPanelId: memoize(async function ({ id }) {
    return customFetch(`${BASE_URL}/${URL_FUNDS}/painel/${id}`, {
      method: 'GET',
      headers: getApiHeaders(),
    })
      .then((response) => {
        if (response.status === 200) return response.json()
        return []
      })
  }, memoizeResolver),

  putPanelOrder: memoize(async function ({ id, order }) {
    return customFetch(`${BASE_URL}/${URL_FUNDS}/painel/order?id=${id}&order=${order}`, {
      method: 'PUT',
      headers: getApiHeaders(),
    })
      .then((response) => {
        if (response.status === 200) return response.json()
        return []
      })
  }, memoizeResolver)
}

export default Api