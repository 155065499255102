import { useState, useEffect } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { MdOutlineToggleOn, MdOutlineToggleOff } from 'react-icons/md'
import NotificationsOff from 'assets/icons/notifications_off.svg'
import NotificationsOn from 'assets/icons/notifications.svg'

function Toggle({ isActive, onChange = () => { } }) {

  const [value, setValue] = useState(isActive)
  const Component = value ? MdOutlineToggleOn : MdOutlineToggleOff

  const toggleValue = (newValue) => {
    const returnValue = onChange(newValue)
    if (!returnValue === undefined)
      setValue(returnValue)
    setValue(newValue)
  }

  return <Component className={`text-4xl cursor-pointer ${value ? 'text-primary' : 'text-gray-400'}`} onClick={() => toggleValue(!value)} />
}

export function ToggleCustom({ id, isChecked, onChange = () => {}, disabled = false }) {

  const [value, setValue] = useState(isChecked)

  const toggle = ({ target }) => {
    onChange(target.checked)
    setValue(target.checked)
  }

  return (
    <label htmlFor={`toggle_Button_${id}`} className="flex items-center cursor-pointer">
      <div className="relative">
        <input disabled={disabled} id={`toggle_Button_${id}`} type="checkbox" checked={value} className="sr-only" onChange={toggle} />
        <div className="block border-2 border-primary w-[50px] h-[29px] box-border rounded-full"></div>
        <img className={`transition ease-in-out duration-500 absolute w-5 h-5 top-1 p-1 rounded-full ${value ? 'right-1 bg-primary' : 'left-1 bg-[#CCC7DB]'} `} src={value ? NotificationsOn : NotificationsOff} alt="Icone notificação" />
      </div>
    </label>
  )
}

export function ControlledToggle({ name }) {
  const { control, setValue, watch } = useFormContext()
  const value = watch(name)

  useEffect(() => {
    if (value === undefined) {
      setValue(name, false)
    }
  }, [name, setValue, value])

  const Component = value ? MdOutlineToggleOn : MdOutlineToggleOff

  const toggleValue = () => {
    setValue(name, !value)
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={false}
      render={() => (
        <Component
          className={`text-4xl cursor-pointer ${value ? 'text-primary' : 'text-gray-400'}`}
          onClick={toggleValue}
        />
      )}
    />
  )
}

export default Toggle