import { MdClose } from "react-icons/md"


function CloseButton({ ...props }) {
  return (
    <button className="absolute right-5 top-5 opacity-50 hover:opacity-100 z-50" {...props}>
      <MdClose color={props.color} size={props.size} />
    </button>
  )
}

export default CloseButton