import useWidgets from 'hooks/useWidgets'
import { useState } from 'react'
import { Link } from "react-router-dom"
import { FormProvider, useForm } from 'react-hook-form'
import { MdAdd, MdOutlineDiamond } from 'react-icons/md'
import Modal from 'react-modal'
import { Checkbox } from 'shared/Checkbox'
import CloseButton from 'shared/CloseButton'
import useWindowSize from 'hooks/useWindowSize'

export function AddWidgetButton({ onAddItems }) {

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const { width } = useWindowSize()
  const [isHovered, setIsHovered] = useState(false)

  const openModal = () => {
    setModalIsOpen(true)
  }
  const closeModal = () => setModalIsOpen(false)
  const submitData = (data) => {
    onAddItems(data)
    closeModal()
  }

  return (
    <>
      <button
        style={{ width: 330 }} 
        className="bg-primary h-full py-4 px-8 flex items-center rounded-xl space-x-4 transition hover:brightness-90 justify-between focus-visible:outline-primaryFaded"
        onClick={openModal}
        onMouseOver={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <h6 className="text-secondary font-semibold uppercase">Adicione Módulo(s)</h6>
        <span className={`rounded-full bg-secondary ${width <= 350 ? 'h-[38px] w-[48px]' : ''} ${width < 402 ? 'h-[38px] w-[42px] text-2xl' : ''} h-12 w-12 flex items-center justify-center cursor-pointer hover:scale-110 transition-all text-3xl text-primary ${isHovered ? 'scale-110' : ''}`}>
          <MdAdd />
        </span>
      </button>
      <AddWidgetModal modalIsOpen={modalIsOpen} closeModal={closeModal} submitData={submitData} />
    </>
  )
}

function AddWidgetModal({ modalIsOpen, closeModal, submitData }) {

  const { getWidgetsByCategory, generateId, widgets } = useWidgets()
  const widgetsByCategory = getWidgetsByCategory()
  const methods = useForm()

  const onSubmit = (data) => {
    const widgetTypes = Object
      .keys(data)
      .filter(type => data[type])
      .map(type => ({ id: generateId(type), defaults: widgets.find(x => x.type === type || x.genericType === type).defaults || {} }))
    methods.reset()

    submitData(widgetTypes)
  }

  return (
    <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={{ content: { maxWidth: '512px', margin: 'auto', borderRadius: '12px' } }}>
      <CloseButton onClick={closeModal} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="h-full flex flex-col items-center">
          <div className="flex-grow">
            <h2 className="text-primary uppercase text-xl font-bold mb-2">Adicione Novo(s) Módulo(s)</h2>
            <h4 className="text-primary uppercase text-base font-semibold mb-2">Escolha o(s) módulo(s) que deseja adicionar:</h4>
            {
              widgetsByCategory.map(({ category, items }) => (
                <div key={'category-' + category} className="mb-2">
                  <h6 className="text-primary text-base font-semibold my-1">{category}</h6>
                  <div className="grid sm:grid-cols-2 grid-cols-1 gap-1">
                    {
                      items.map(({ type, genericType, label, restricted }) => (
                        <Checkbox className="text-primary" key={genericType || type} disabled={restricted} name={genericType || type}>
                          <span className="ml-2 text-base flex items-center">
                            {label}
                            {restricted && <MdOutlineDiamond className="text-base text-terciary ml-2 inline" />}
                          </span>
                        </Checkbox>
                      ))
                    }
                  </div>
                </div>
              ))
            }

            <div className="text-terciary my-5">
              <p className="text-sm font-bold uppercase flex items-center space-x-2 mt-5">
                <MdOutlineDiamond className='flex-none text-base' />
                <span>Destrave conteúdo e funcionalidades</span>
              </p>
              <p className="text-sm font-semibold">
                <span>Conheça nossos planos e os recursos indispensáveis para a melhor decisão de negócios. </span>
                <Link to={'/planos'} className='underline'>Faça upgrade.</Link>
              </p>
            </div>
          </div>
          <button type="submit" className="btn btn-primary text-secondary inline">Adicionar</button>
        </form>
      </FormProvider>

    </Modal>
  )
}