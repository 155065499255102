import { createContext, useState } from "react";

const FixedIncomeCalculatorContext = createContext({
    result: { values: [], totalInvested: 0, amount: 0 },
    resultLayout: false,
    onSetResult: () => { },
    onSetResultLayout: () => { },
})

export function FixedIncomeCalculatorContextProvider({ children, ...props }) {
    const [result, setResult] = useState({values: [], totalInvested: 0, amount: 0})
    const [resultPage, setResultPage] = useState(false)

    const onSetResult = (data) => {
        setResult(data)
    }

    const onSetResultLayout = (data) => {
        setResultPage(data)
    }

    return (
        <FixedIncomeCalculatorContext.Provider value={{
            result,
            resultPage,
            onSetResult: onSetResult,
            onSetResultLayout: onSetResultLayout,
        }} >
            {children}
        </FixedIncomeCalculatorContext.Provider>
    )
}

export default FixedIncomeCalculatorContext