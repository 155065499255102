import IcoAcompanhar from 'assets/icons/menu/ico-menu-acompanhar.svg'
import IcoAlertas from 'assets/icons/menu/ico-menu-alertas.svg'
import IcoCentralAlertas from 'assets/icons/menu/ico-sub-central-alertas.svg'
import IcoAnalisar from 'assets/icons/menu/ico-menu-analisar.svg'
import IcoAprender from 'assets/icons/menu/ico-menu-aprender.svg'
import IcoInicio from 'assets/icons/menu/ico-menu-inicio.svg'
import IcoInvestir from 'assets/icons/menu/ico-menu-investir.svg'
import IcoPerfil from 'assets/icons/menu/ico-menu-perfil.svg'
import IcoMedal from 'assets/icons/menu/ico-sub-medal.svg'
import IcoNews from 'assets/icons/menu/ico-sub-newspaper.svg'
import IcoBovespaB3 from 'assets/icons/menu/ico-sub-bovespa-b3.svg'
import IcoPainelCotacao from 'assets/icons/menu/ico-sub-painel-cotacao.svg'
import IcoTreeMapChart from 'assets/icons/menu/ico-sub-treemap-chart.svg'
import IcoInventory from 'assets/icons/menu/ico-sub-inventory.svg'
import IcoQuickQuote from 'assets/icons/menu/ico-sub-quick-quotes.svg'
import IcoAreaChart from 'assets/icons/menu/ico-sub-area-chart.svg'
import IcoCalculate from 'assets/icons/menu/ico-calculate.svg'
import IcoSettings from 'assets/icons/menu/ico-sub-settings.svg'
import IcoLocal from 'assets/icons/menu/ico-sub-local.svg'
import IcoSavings from 'assets/icons/menu/ico-sub-savings.svg'
import IcoWallet from 'assets/icons/menu/ico-sub-wallet.svg'
import IcoLogout from 'assets/icons/menu/ico-logout.svg'
import IcoAlertMonitor from 'assets/icons/menu/ico-alert-monitor.svg'
import IcoQuestionMark from 'assets/icons/menu/ico-question_mark.svg'
import { useContext, useEffect, useReducer, useMemo } from 'react'
import AuthContext from 'context/AuthContext'
import CoursesApi from 'api/CoursesApi'
import QuickQuotationModalContext from 'context/QuickQuotationModalContext'
import { MdOutlineStarOutline } from 'react-icons/md'
import IcoMainWorkspace from 'assets/icons/menu/ico-main-workspace.svg'

function useMenu() {

  const { userInfo, genericModules, onLogout } = useContext(AuthContext)
  const { open: openQuickQuotationModal } = useContext(QuickQuotationModalContext)

  const initialMenuItems = useMemo(() => [
    {
      name: 'Início',
      url: '/workspace',
      type: 'inverted',
      ico: IcoInicio,
      defaultSubitems: [
        { name: 'Workspace Principal', url: '/workspace', type: 'workspace', isMain: true, ico: IcoMainWorkspace, onlyMobile: true },
      ]
    },
    {
      name: 'Aprender',
      url: null,
      type: 'regular',
      ico: IcoAprender,
      subitems: [
        { name: 'Cursos', widgetType: 'Course', permissionType: 'Course', url: null, ico: IcoMedal, target: '_blank' },
        { name: 'Simuladores', widgetType: "Simulator", permissionType: "Simulator", url: '/simulador', type: 'subitem', ico: IcoSavings },
        { name: 'Guia de Uso', widgetType: "Guia", permissionType: "GuiaDeUso", url: '/guia', type: 'subitem', ico: IcoQuestionMark }
      ]
    },
    {
      name: 'Acompanhar',
      url: null,
      type: 'regular',
      ico: IcoAcompanhar,
      subitems: [
        { name: 'Notícias', widgetType: 'News', permissionType: 'News', url: '/noticias', type: 'subitem', ico: IcoNews },
        { name: 'Painel de Cotações', widgetType: 'QuotationPanel', permissionType: 'QuotationPanel', url: '/painel-de-cotacoes', type: 'subitem', ico: IcoPainelCotacao },
        { name: 'Resumo da Bolsa', widgetType: 'TradingSummary', permissionType: 'TradingSummary', url: '/resumo-da-bolsa', type: 'subitem', ico: IcoBovespaB3 },
        { name: 'Mapa de Mercado', widgetType: 'MarketMap', permissionType: 'MarketMap', url: '/mapa-de-mercado', type: 'subitem', ico: IcoTreeMapChart },
      ]
    },
    {
      name: 'Analisar',
      url: null,
      type: 'regular',
      ico: IcoAnalisar,
      subitems: [
        { name: 'Recomendações e Análises', widgetType: "Recommendation", permissionType: "Recommendation", url: '/recomendacoes-e-analises', type: 'subitem', ico: IcoInventory },
        { name: 'Cotação Rápida', widgetType: "QuickQuote", permissionType: "QuickQuote", url: null, type: 'subitem', ico: IcoQuickQuote, fn: openQuickQuotationModal },
        { name: 'Fundos', permissionType: 'Funds', url: '/fundos', type: 'subitem', ico: IcoLocal },
        { name: 'Análise Gráfica', widgetType: "Graphics", permissionType: "Graphics", url: '/analise-grafica', type: 'subitem', ico: IcoAreaChart },
        { name: 'Calculadora de Renda Fixa', widgetType: "FixedIncomeCalculator", permissionType: "FixedIncomeCalculator", url: '/calculadora-renda-fixa', type: 'subitem', ico: IcoCalculate },
      ]
    },
    {
      name: 'Investir',
      url: null,
      type: 'regular',
      ico: IcoInvestir,
      subitems: [
        { name: 'Minha Carteira', widgetType: "Wallet", permissionType: "Wallet", url: '/minha-carteira', type: 'subitem', ico: IcoWallet },
      ]
    },
    {
      name: 'Alertas',
      url: '/alerts',
      type: 'inverted',
      ico: IcoAlertas,
      subitems: [
        { name: 'Central de Alertas', url: '/alerts', permissionType: 'Alerts', type: 'subitem', ico: IcoCentralAlertas },
        { name: 'Monitoração de Investimentos', url: '/alert-monitor', permissionType: 'Alerts', type: 'subitem', ico: IcoAlertMonitor },
      ]
    },
    {
      name: 'Perfil',
      url: '/configuracoes',
      type: 'inverted',
      ico: IcoPerfil,
      subitems: [
        { name: 'Configurações', url: '/configuracoes', type: 'subitem', ico: IcoSettings },
        { name: 'Planos', url: '/planos', type: 'subitem', md: <MdOutlineStarOutline /> },
        { name: 'Sair', url: null, type: 'subitem', ico: IcoLogout, fn: () => onLogout() },
      ]
    }], [onLogout])

  const Actions = {
    UPDATE_WORKSPACES: 1,
    SET_GENERIC_MODULES: 2,
    UPDATE_SUBITEM: 3,
  }

  function menuReducer(state, action) {
    const newState = [...state]
    switch (action.type) {
      case Actions.UPDATE_WORKSPACES: {
        const { workspace: workspaces = [], permissions } = action.payload
        const [init, ...items] = state
        init.subitems = [
          ...init.defaultSubitems
        ]
        newState.splice(0, 1, init)
        for (const subitem of items.reduce((ac, x) => [...ac, ...x.subitems], [])) {
          subitem.restricted = (subitem.restricted || subitem.permissionType) && !permissions?.some(permission => permission.key === subitem.permissionType)
        }
        break
      }
      case Actions.SET_GENERIC_MODULES: {
        const { genericModules, permissions } = action.payload
        if (!genericModules || !userInfo) break
        state.forEach((x) => {
          const items = genericModules?.filter((y) => x.name === y.parentMenu && y.exhibition.home)
            .map(y => ({
              name: y.title,
              type: 'subitem',
              url: `modulo/${y.id}`,
              ico: y.icon,
              sourceCode: y.sourceCode,
              restricted: !permissions?.some(permission => +permission.key === y.id),
            }))
            .filter(item => !x.subitems.some(subitem => subitem.url === item.url));
          x.subitems?.push(...(items || []))
        })
        break
      }
      case Actions.UPDATE_SUBITEM: {
        const { menuName, submenuName, value } = action.payload
        const menu = newState.find(x => x.name === menuName)
        if (!menu) break
        const submenu = menu.subitems.find(x => x.name === submenuName)
        if (!submenu) break
        Object.assign(submenu, value)
        break
      }
      default: throw new Error(`Não foi possível encontrar ${action.type}`)
    }
    return newState
  }

  const [menuItems, dispatch] = useReducer(menuReducer, initialMenuItems)

  useEffect(() => {
    dispatch({ type: Actions.UPDATE_WORKSPACES, payload: userInfo })
    if (!userInfo) return
    const { name, email } = userInfo
    CoursesApi.fetchAlpaclassIframe({ name, email }).then(data => {
      dispatch({ type: Actions.UPDATE_SUBITEM, payload: { menuName: 'Aprender', submenuName: 'Cursos', value: { url: data.url } } })
    })
  }, [userInfo, dispatch, Actions.UPDATE_SUBITEM])

  useEffect(() => {
    dispatch({ type: Actions.SET_GENERIC_MODULES, payload: { genericModules, ...userInfo } })
  }, [genericModules, userInfo, dispatch, Actions.SET_GENERIC_MODULES])

  return {
    menuItems
  }

}

export default useMenu
