import { useState, useEffect, useRef, useCallback, useMemo, useContext } from "react"
import WidgetContextualMenu from "shared/WidgetContextualMenu"
import StatisticApi from "api/StatisticApi"
import { XAxis, XYPlot, YAxis, VerticalBarSeries, LabelSeries, VerticalGridLines } from "react-vis"
import { abs, format, formatterBRL } from "helpers/numberHelper"
import { BtnToggleMyList } from "shared/MyList"
import { IbovInfo } from "shared/IbovInfo"
import { useTimer } from "hooks/useTimer"
import { LAYOUT_BREAKPOINT_WIDTH_MOBILE } from "const"
import useMaxWindowSize from "hooks/useMaxWindowSize"
import Modal from "react-modal"
import { WidgetTitle, WidgetLinkTitle } from "shared/WidgetTitle"
import { OrderByColumnContext, OrderByColumnContextProvider, OrderByColumnDisplay, OrderByColumnTrigger } from "context/OrderByColumnContext"
import { MdClose } from "react-icons/md"
import useWindowSize from "hooks/useWindowSize"
import { LAYOUT_BREAKPOINT_WIDTH } from "const"
import useTitle from "hooks/useTitle"
import DeniedPermissionWidget from "shared/DeniedPermissionWidget"

export function TradingSummaryPage() {
  useTitle('Resumo da Bolsa')
  const isMobile = useMaxWindowSize(LAYOUT_BREAKPOINT_WIDTH_MOBILE)
  return (
    <div className="bg-white min-h-full rounded flex flex-col">
      <div className="pt-4 px-4">
        <WidgetTitle title="Resumo da Bolsa" />
      </div>
      <TradingSummary colsSize={isMobile ? 1 : 3} />
    </div>
  )
}

export function TradingSummaryWidget({ id, ...props }) {

  const isMobile = useMaxWindowSize(LAYOUT_BREAKPOINT_WIDTH_MOBILE)
  const widgetSize = props[isMobile ? 'mobile' : 'desktop'].w

  const options = useMemo(() => [
    { label: "Modo Compacto", onClick: () => props.resizeItem({ id, desktop: { w: 1 } }) },
    { label: "Modo Expandido", onClick: () => props.resizeItem({ id, desktop: { w: 2 } }) },
  ], [id, props])

  return (
    <div className="bg-white min-h-full rounded flex flex-col">
      <div className="drag-handle pt-4 px-4">
        <WidgetContextualMenu options={options} removeItem={() => props.removeItem(id)} />
        <WidgetLinkTitle route="/resumo-da-bolsa" title="Resumo da Bolsa" />
      </div>

      <DeniedPermissionWidget widgetSize={widgetSize} widgetType="TradingSummary">
        <TradingSummary colsSize={widgetSize} isMobile={isMobile} />
      </DeniedPermissionWidget>

    </div>
  )
}

function TradingSummary({ colsSize, isMobile }) {
  const [dataGroups, setDataGroups] = useState([])
  const [initialDataset, setInitialDataset] = useState([])
  const [selectedDataset, setSelectedDataset] = useState(null)
  const [chartBounds, setChartBounds] = useState(null)
  const [lastUpdate, setLastUpdate] = useState(null)
  const [modalMobileIsOpen, setModalMobileIsOpen] = useState(false)
  const timer = useTimer(60000)
  // const timer = useTimer(5000) // TODO: TROCAR PARA TIMER 1 minuto - 60000
  const chartMargin = { left: 55, bottom: 45, top: 30 }

  const ref = useRef()

  const selectDataset = useCallback((key = 'ALL', options = { preventOpenModal: false }) => {

    if (!key) {
      setModalMobileIsOpen(false)
      setSelectedDataset(false)
      return
    }

    let dataset
    if (key === 'ALL')
      dataset = { type: 'ALL', data: initialDataset, label: `Todos os ativos do IBOVESPA: ${initialDataset.length}`, key }
    if (!dataset) {
      const legendFound = dataGroups.find(item => item.key === key)
      dataset = legendFound && { type: 'LEGEND_GROUP', data: legendFound.data.reduce((ac, x) => [...ac, ...x.data], []), label: legendFound.fullLegend, key }
    }
    if (!dataset) {
      const itemFound = dataGroups.reduce((ac, x) => [...ac, ...x.data], []).find(item => item.key === key)
      dataset = itemFound && { type: 'DATA_ITEM', data: itemFound.data, label: `${itemFound.data.length} ${itemFound.data.length === 1 ? 'ativo' : 'ativos'} ${itemFound.label}`, key }
    }
    if (!dataset)
      throw new Error(`Not found any data with key ${key}!`)

    setSelectedDataset(dataset)
    if (!options?.preventOpenModal) setModalMobileIsOpen(true)
  }, [setSelectedDataset, initialDataset, dataGroups])

  useEffect(() => {
    StatisticApi.fetchIBOVQuotationAsset({ timer }).then((marketList) => {
      setDataGroups(handleData(marketList))
      setInitialDataset(marketList)
      setLastUpdate(new Date())
    })
  }, [timer])

  useEffect(() => { selectDataset(selectedDataset?.key || 'ALL', { preventOpenModal: true }) }, [selectDataset, initialDataset])
  useEffect(() => {
    if (!isMobile && colsSize > 1)
      setModalMobileIsOpen(false)
    setTimeout(() => setChartBounds(ref?.current?.getClientRects()[0]), 10)
  }, [colsSize, isMobile, selectDataset])

  return (
    <div className="px-4 pb-4 h-full flex-1 flex flex-col">
      <span className="text-gray-500 relative bottom-2 text-sm md:text-base">Clique no título, nas barras ou nas legendas para ver detalhes do grupo de ativos.</span>
      <div className="grid max-h-full flex-1" style={{ gridTemplateColumns: `repeat(${colsSize}, minmax(0, 1fr))` }} >
        <div style={{ gridColumn: `span ${Math.max(colsSize - 1, 1)} / span ${Math.max(colsSize - 1, 1)}`, }} className="mb-4 relative">
          <div ref={ref} className="h-full">
            <div className="cursor-pointer hover:opacity-80" onClick={() => selectDataset('ALL')}>
              <IbovInfo />
            </div>
            {dataGroups && (
              <XYPlot
                xType="ordinal"
                margin={chartMargin}
                width={chartBounds?.width || 300}
                height={(chartBounds?.height || 300) - (isMobile ? 210 : 135)}
                xDistance={100}>
                <VerticalGridLines style={{ strokeDasharray: 4, strokeWidth: 2 }} />
                {dataGroups.map(datagroup => (
                  <LabelSeries
                    key={`LabelSeries_${datagroup.legend}`}
                    getLabel={d => d.y}
                    data={datagroup.data.map(({ x, y }) => ({ x, y }))}
                    labelAnchorY="top"
                    labelAnchorX="middle"
                    className="font-bold text-sm z-50"
                    marginTop={chartMargin.top - 10} />
                ))}
                <XAxis />
                <YAxis hideLine />
                {
                  dataGroups.reduce((ac, { data, color }) => [...ac, ...data.map(x => ({ ...x, color }))], []).map(seriesData => (
                    <VerticalBarSeries
                      key={'VerticalBarSeries_' + seriesData.x}
                      data={[{ x: seriesData.x, y: seriesData.y }]}
                      color={seriesData.color}
                      cluster="main"
                      className={`cursor-pointer hover:brightness-75 ${selectedDataset?.key === seriesData.x && 'brightness-50'}`}
                      onValueClick={() => selectDataset(seriesData.key)}
                    />
                  ))
                }
              </XYPlot>
            )}
            <div className="font-bold text-xs text-center -mt-4">Variação em %</div>
            <div className="font-bold text-xs absolute top-[calc(50%-20px)] -left-14 -rotate-90">Quantidade de ativos</div>
            <div className={`flex mb-1 mt-3 ${!isMobile ? 'space-x-4 justify-center mb-3 flex-wrap' : 'flex-col items-start'}`}>
              {dataGroups.map(group => (
                <div
                  key={`legend_${group.legend}`}
                  className={`cursor-pointer flex items-center border-b-[3px] whitespace-nowrap ${selectedDataset?.key === group.key ? 'border-[#C5C5C5]' : 'border-transparent'}`}
                  onClick={() => selectDataset(group.key)}
                >
                  <div className={`h-4 w-4 mr-2 ${group.colorClassName}`} />
                  <div>{group.legend}</div>
                </div>
              ))}
            </div>
            <p className="dark-gray text-xs text-center">
              Atualizado às {lastUpdate?.toLocaleString('PT-br').slice(-8)}
            </p>
          </div>
        </div>
        {
          !isMobile && colsSize > 1 ? (
            <div
              style={{
                gridColumn: `span ${Math.min(colsSize - 1, 1)} / span ${Math.min(colsSize - 1, 1)}`,
                // gridColumn: `1`,
                height: chartBounds ? chartBounds?.height : 300
              }}
              className="overflow-y-auto max-h-full mini-scrollbar"
            >
              <DatasetDetails {...(selectedDataset || {})} />
            </div>
          ) : (
            <DatasetDetailsModal isOpen={modalMobileIsOpen} selectedDataset={selectedDataset} close={() => { setModalMobileIsOpen(false); selectDataset(null) }} />
          )
        }
      </div>
    </div>
  )
}

function DatasetDetailsModal({ isOpen, selectedDataset = null, close = () => { } }) {

  const { width } = useWindowSize()
  const isMobile = width < LAYOUT_BREAKPOINT_WIDTH

  return (
    <Modal isOpen={isOpen && !!selectedDataset} style={{
      content:
      {
        maxWidth: isMobile ? width : 648,
        margin: "auto",
        marginRight: isMobile ? -40 : "auto",
        marginLeft: isMobile ? -40 : "auto",
      }
    }} onRequestClose={() => close()}>
      <DatasetDetails {...selectedDataset} openFromModal="true" close={() => close()} />
    </Modal>
  )
}

function DatasetDetails({ type, data, label, openFromModal = false, close = () => { } }) {

  // const [orderBy, setOrderBy] = useOrderBy()
  // const [sortedData, setSortedData] = useState(data)

  const cols = useMemo(() => [
    { key: 'symbol', label: 'Código' },
    { key: 'ult', label: 'Último Preço' },
    { key: 'var', label: 'Variação' },
    { key: 'neg', label: 'Número de Negócios' },
  ], [])


  return (
    <OrderByColumnContextProvider data={data} defaultColumn="symbol">
      <button className="sticky float-right right-2 top-2 text-xl text-primary z-50" title="Fechar" onClick={() => close()}>
        <MdClose />
      </button>
      <div className="space-y-2">
        <div className="h-full relative">
          <div className={`sticky bg-white ${openFromModal ? '-top-5 pt-4' : 'top-0'}`}>
            {label && <h4 className="font-semibold text-xl mb-2">{label}</h4>}
            <div className="grid grid-cols-4 border-y font-semibold text-xs uppercase">
              {cols.map((col, index) => (
                <OrderByColumnTrigger key={'COL_' + col.key} columnName={col.key}>
                  <div className={`border-r first:border-l p-2 flex cursor-pointer ${index === 0 ? 'justify-between' : 'justify-end'} items-center text-right`}>
                    <span>{col.label}</span>
                    <OrderByColumnDisplay columnName={col.key} />
                  </div>
                </OrderByColumnTrigger>
              ))}
            </div>
          </div>
          <DatasetDetail type={type} />
        </div>
      </div>
    </OrderByColumnContextProvider>
  )
}

function DatasetDetail({ type }) {
  const { sortedData } = useContext(OrderByColumnContext)

  return sortedData?.length
    ? sortedData?.map((item) => <DatasetDetailItem key={`list-${type}-${item.id}-${item.symbol}`} item={item} type={type} />)
    : <p className="text-gray-500 my-2 text-sm">Nenhum ativo a ser exibido...</p>
}


function DatasetDetailItem({ item }) {
  const color = item.var === 0 ? "dark-gray" : item.var > 0 ? "text-profit" : "text-loss"
  return (
    <div className="border border-t-0 border-gray-200 p-2 hover:bg-gray-100 items-center grid grid-cols-4 text-sm">
      <div className="flex space-x-2 overflow-hidden whitespace-nowrap text-ellipsis">
        <div className="pr-2">
          <div className="flex space-x-1 items-center">
            <h5 className="font-semibold">{item.symbol}</h5>
            <BtnToggleMyList id={item.id} symbol={item.symbol} origin={item.origin} />
          </div>
          <h6 className="text-xs dark-gray">
            {item.companyName}
          </h6>
        </div>
      </div>

      <div className="font-semibold text-right px-2" title="Última cotação">
        {formatterBRL.format(item.ult)}
      </div>
      <div className={`space-x-1 font-semibold text-right px-2 ${color}`} title="Variação no dia de hoje">
        {typeof item.var === 'number' ? (abs(item.var, "percent")) : ''}
      </div>

      <div className="text-right font-semibold px-2 dark-gray">
        {format(item.neg, 'integer')}
      </div >
    </div>
  )
}

function handleData(data) {
  const getInterval = (min, max, key) => {
    let filteredData = data.filter(x => x.neg > 0)
    if (min) filteredData = filteredData.filter((x) => x.var >= min)
    if (max) filteredData = filteredData.filter((x) => x.var <= max)
    return {
      data: filteredData,
      y: filteredData.length,
      x: key,
      key
    }
  }
  const getGroupInfo = ({ key, data = [] }) => {
    const totalLength = data.reduce((ac, x) => x.data.length + ac, 0)
    return { data, length: totalLength, legend: `${totalLength} ${key}`, fullLegend: `${totalLength} ativos ${key}`, key }
  }

  const unnegociatedData = data.filter(({ neg }) => neg === 0)
  const dataGroups = [
    {
      ...getGroupInfo({
        key: 'em baixa',
        data: [
          { label: "abaixo de -2,50%", ...getInterval(null, -2.51, '< -2.50%') },
          { label: "de -2,50% a -1,51%", ...getInterval(-2.5, -1.51, '-2%') },
          { label: "de -1,50% a -0,51%", ...getInterval(-1.5, -0.51, '-1%') },
        ]
      }),
      color: "#FE260E",
      colorClassName: "bg-legend-loss"
    },
    {
      ...getGroupInfo({
        key: 'estáveis',
        data: [{ label: "de -0,50% a 0,50%", ...getInterval(-0.5, 0.5, '0%') }]
      }),
      color: "#707070",
      colorClassName: "bg-gray-70"
    },
    {
      ...getGroupInfo({
        key: 'em alta',
        data: [
          { label: "de 0,51% a 1,50%", ...getInterval(0.51, 1.5, '1%') },
          { label: "de 1,51% a 2,50%", ...getInterval(1.51, 2.5, '2%') },
          { label: "acima de 2,50%", ...getInterval(2.51, null, '2,50% >') },
        ]
      }),
      color: "#65A503",
      colorClassName: "bg-profit"
    }
  ]

  if (unnegociatedData.length) {
    dataGroups.push({
      ...getGroupInfo({
        key: 'não negociados',
        data: [{ x: "", label: "não negociados", key: 'não negociados', ...(data => ({ y: data.length, data }))(unnegociatedData) }]
      }),
      color: "#00AEEF",
      colorClassName: "bg-legend-unnegociated"
    })
  }

  return dataGroups
}
