import { useState, useEffect } from "react"

function useMaxWindowSize(maxSize) {
  const [windowSize, setWindowSize] = useState(window.innerWidth < maxSize)
  useEffect(() => {
    function handleResize() {
      setWindowSize(window.innerWidth < maxSize)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return windowSize
}

export default useMaxWindowSize