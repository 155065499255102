import { useMemo, useContext, Fragment, useState, useEffect } from 'react'
import Modal from "react-modal"
import { MdOutlineModeEditOutline, MdArrowBackIos, MdOutlineManageSearch } from "react-icons/md"
import { toast } from 'react-toastify'
import { AlertMonitorProvider, AlertMonitorContext } from 'context/AlertMonitorContext'
import useWindowSize from 'hooks/useWindowSize'
import useMobile from 'hooks/useMobile'
import Tabs from 'shared/Tabs'
import { WidgetTitle } from "shared/WidgetTitle"
import { ToggleCustom } from 'shared/ToggleButton'
import { CheckboxBasic } from 'shared/Checkbox'
import NotificationModal from 'shared/modal/NotificationModal'
import CloseButton from "shared/CloseButton"


function useAlertMonitor() {
  const [isOpenNotification, setIsOpenNotification] = useState(false)
  const [activeListChosen, setActiveListChosen] = useState(null)

  return {
    isOpenNotification,
    setIsOpenNotification,
    activeListChosen,
    setActiveListChosen
  }
}

function AlertMonitor() {

  const { height, width } = useWindowSize()
  const { isMobile } = useMobile()
  const [mobileView, setMobileView] = useState(0)
  
  const menuActive = (e) => {
    setMobileView(e)
  }

  return (
    <AlertMonitorProvider>
      <div style={{height: isMobile ? mobileView === 0 ? '100%' : '' : `${height - 140}px`}} className={`flex flex-col ${isMobile ? '' : 'm-[30px]' } p-[30px] bg-white rounded-xl`}>
        <WidgetTitle title='Monitoração de Investimentos' />
        {isMobile 
          ? { 
              0 : <Menu mobile={isMobile} onChange={menuActive} />,
              1 : <Content mobile={isMobile} size={{width, height}} onChangeViewMobile={menuActive} />
            }[mobileView]
            
          : <div className="h-full flex rounded-xl p-2">
              <Menu size={{width}} />
              <Content size={{width, height}} />
            </div>
        }
      </div>
    </AlertMonitorProvider>
  )
}

function Menu({width, mobile, onChange = () => {}}) {
  
  const { listsPanel, activeList, updateListsPanel, selectedActiveList, saveLists, cancelUpdateList } = useContext(AlertMonitorContext)
  const use = useAlertMonitor()
  
  const onChangeToggle = (e) => {
    updateListsPanel({ flgEnabled: e })
  }

  const expectedActiveList = (list) => {
    if (mobile) selectedActiveList(list)
    else {
      use.setActiveListChosen(list)
      use.setIsOpenNotification(true)
    }
    onChange(1)
  }

  const updateActiveList = () => {
    saveLists(activeList)
    if (!mobile) {
      selectedActiveList(use.activeListChosen)
      use.setIsOpenNotification(false)
    }
    toast.success(`As alterações foram salvas com sucesso!`)
  }

  const closeModal = () => {
    selectedActiveList(use.activeListChosen)
    use.setIsOpenNotification(false)
    cancelUpdateList()
  }

  useEffect(() => {
  }, [listsPanel])

  const tabs = useMemo(() => [{ name: "PAINEL" }], [])
  return (
    <div className={`h-full bg-light-gray ${mobile ? 'w-full rounded-xl' : 'w-[368px] rounded-s-xl'} `}>
      <div className='px-[30px]'>
        <Tabs
          tabs={tabs}
          activeTabIndex={{index: 0}}
          onChange={(args) => {console.log({ args })}}
          parentClass={`flex space-x-[50px] mt-[22px] mb-[10px]`}
          tabClass={'pb-2 text-base'}
          tabSelectedClass={'border-b-4'}
        />
      </div>
      <div>
        {listsPanel.length > 0 && listsPanel.map((list) => {
          return (
            <Fragment key={`List_Menu_Alert_${list.id}`}>
              <div
                onClick={() => mobile ? expectedActiveList(list) : list.id !== activeList.id && expectedActiveList(list)}
                className={`${activeList.name === list.name && !mobile ? 'bg-white border-y-2 border-l-2 rounded-s-xl' : ''} flex justify-between cursor-pointer px-[30px] py-3`}
              >
                { activeList && <>
                  <span className='text-base text-primary font-semibold'>{list.name}</span>
                  <ToggleCustom id={list.id} isChecked={list.flgEnabled} onChange={onChangeToggle} disabled={!mobile && list.id !== activeList.id} />
                </>}
              </div>
              <NotificationModal
                isOpen={use.isOpenNotification}
                closeModal={() => closeModal()}
                title={`Deseja salvar as modificações?`}
                text='Você alterou as configurações desta lista, e perderá as alterações se não forem salvas.'
                style={{ content: { maxWidth: 400, maxHeight: width < 500 ? 360 : 270, borderRadius: 10, margin: "auto" } }}
                successModal={() => updateActiveList()}
                actionButtons 
              />
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}

function Content({ width, height, mobile, onChangeViewMobile = () => {} }) {
  const [isOpenModalActivesInPanel, setIsOpenModalActivesInPanel] = useState(false)
  const [updateSymbols, setUpdateSymbols] = useState([])
  const { listsPanel, activeList, updateListsPanel, selectedActiveList, saveLists, cancelUpdateList } = useContext(AlertMonitorContext) 

  const use = useAlertMonitor()

  const updateActiveList = () => {
    saveLists(activeList)
    if (!mobile) {
      selectedActiveList(use.activeListChosen)
      use.setIsOpenNotification(false)
    }
    onChangeViewMobile(0)
    toast.success(`As alterações foram salvas com sucesso!`)
  }

  const closeModal = () => {
    selectedActiveList(use.activeListChosen)
    use.setIsOpenNotification(false)
    cancelUpdateList()
    onChangeViewMobile(0)
  }

  function configsUpdate(data) {
    const configs = listsPanel.find(({ id }) => id === activeList.id).panelInvestDatas.filter(({ id }) => id !== data.id)
    updateListsPanel({ panelInvestDatas: [...configs, {...data}].sort((a,b) => a.id - b.id) })
  }

  function symbolsUpdate(row, info) {
    let data
    if (updateSymbols.length) {
      data = updateSymbols.map((symbol) => {
        if (symbol.symbol === row.symbol) return { ...symbol, flgEnabled: info }
          return symbol
      })
    } else {
      data = listsPanel.find(({ id }) => activeList.id === id).panelInvestSymbols.map((symbol) => {
        if (symbol.symbol === row.symbol) return { ...symbol, flgEnabled: info }
          return symbol
      })
    }
    updateListsPanel({panelInvestSymbols: [ ...data ]})
    setUpdateSymbols(data)
  }

  useEffect(() => {
    setUpdateSymbols([])
  }, [activeList])
  
  return (
    activeList && <section className={`flex flex-col w-full ${mobile ? 'rounded-xl' : 'px-[30px] border-2 border-light-gray border-l-0 rounded-e-xl'}`}>
      <div className={`flex ${mobile ? 'flex-wrap space-y-5' : 'justify-between'} py-9 border-b-2 border-light-gray`}>
        <div className='flex items-center'>
          {mobile && <MdArrowBackIos onClick={() => use.setIsOpenNotification(true)} className='text-primary cursor-pointer' size={24} />}
          {activeList.name && <span className='uppercase text-primary text-base font-semibold'>{`Monitoração - ${activeList.name}`}</span>}
        </div>
        <button onClick={() => setIsOpenModalActivesInPanel(true)} disabled={!listsPanel.find(({ id }) => activeList.id === id)?.flgEnabled} className='flex btn border-2 border-primary text-primary space-x-3 cursor-pointer'>
          <MdOutlineManageSearch size={20} />
          <span className='font-semibold'>ativos no painel</span>
        </button>
      </div>
      <div className='grow flex flex-col justify-between'>
        <div className='pt-[30px] flex flex-col'>
          <span className='uppercase text-primary text-base font-semibold'>{`Configurações`}</span>
          <span className='mt-9 mb-3 text-primary text-xs uppercase'>Ativo</span>
          <div className='flex flex-col space-y-5'>
            {listsPanel.find(({ id }) => id === activeList.id)?.panelInvestDatas?.map((item) => {
              return (
                <Fragment key={`Config_Items_Alert_Monitor_${item.id}`}>
                  {activeList && 
                    <ConfigItem
                      row={item}
                      firstText={item.firstText}
                      lastText={item.lastText}
                      numberAbove={item.numberAbove}
                      numberBelow={item.numberBelow}
                      disabled={listsPanel.find(({ id }) => activeList.id === id).flgEnabled }
                      checked={item.flgEnabled}
                      setData={configsUpdate}
                    />}
                </Fragment>
              )
            } 
            )}
          </div>
        </div>
        <div className='flex justify-center space-x-5 p-[30px]'>
          <button onClick={() => cancelUpdateList()} className={`btn btn-secondary text-primary uppercase`}>cancelar</button>
          <button onClick={() => updateActiveList()} className={`btn btn-primary text-secondary uppercase`}>salvar</button>
        </div>
      </div>
      <Modal
        isOpen={isOpenModalActivesInPanel}
        onRequestClose={() => setIsOpenModalActivesInPanel(false)}
        style={
          {
            content:
            {
              maxWidth: mobile ? width : 411,
              maxHeight: mobile ? height : 631,
              margin: "auto",
              borderRadius: 12,
              marginRight: mobile ? -40 : "auto",
              marginLeft: mobile ? -40 : "auto",
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column'
            }
          }
        }
      >
      <CloseButton onClick={() => setIsOpenModalActivesInPanel(false)} />
      <div className="mb-10">
        <h2 className="font-bold text-xl text-primary uppercase mb-2">Ativos no painel</h2>
        <p className={`text-dark-gray text-sm`}>Ativos que são monitorados e geram alertas neste painel.</p>
      </div>
      <div className={`grow mini-scrollbar overflow-y-auto border border-light-gray divide-y divide-light-gray`}>
        { updateSymbols.length 
          ? updateSymbols.map((item) => (
            item && <div key={`Alert_Monitor_List_Symbols${item.symbol}`} className='flex justify-between px-5 py-3'>
              <div className='flex flex-col text-dark-gray'>
                <span className='font-semibold'>{item.symbol}</span>
                <span>{item.name}</span>
              </div>
              <div className='flex items-center space-x-2 text-dark-gray'>
                <span className='text-xs'>{item.nameOrigin}</span>
                <ToggleCustom id={item.symbol} isChecked={item.flgEnabled} onChange={(e) => symbolsUpdate(item, e)} />
              </div>
            </div>))
          : activeList.panelInvestSymbols?.length 
            ? listsPanel.find(({ id }) => activeList.id === id)?.panelInvestSymbols.map((item) => (
              item && <div key={`Alert_Monitor_List_Symbols${item.symbol}`} className='flex justify-between px-5 py-3'>
                <div className='flex flex-col text-dark-gray'>
                  <span className='font-semibold'>{item.symbol}</span>
                  <span>{item.name}</span>
                </div>
                <div className='flex items-center space-x-2 text-dark-gray'>
                  <span className='text-xs'>{item.nameOrigin}</span>
                  <ToggleCustom id={item.symbol} isChecked={item.flgEnabled} onChange={(e) => symbolsUpdate(item, e)} />
                </div>
              </div>
            )) 
            : <span className='p-2 text-grayDisabled'>Nenhum ativo adicionado no painel.</span>
        }
      </div>
      </Modal>
      <NotificationModal
        isOpen={use.isOpenNotification}
        closeModal={() => closeModal()}
        title={`Deseja salvar as modificações?`}
        text='Você alterou as configurações desta lista, e perderá as alterações se não forem salvas.'
        style={{ 
          content: {
            maxWidth: 400,
            maxHeight: width < 500 ? 360 : 270,
            marginRight: mobile ? -40 : "auto",
            marginLeft: mobile ? -40 : "auto",
            borderRadius: 10,
            margin: "auto" 
          }
        }}
        successModal={() => updateActiveList()}
        actionButtons 
      />
    </section>
  )
}

function ConfigItem({ row, firstText, lastText, numberAbove, numberBelow, disabled = true, checked, setData = () => {}}) {
  const [edit, setEdit] = useState(false)
  const [isChecked, setIsChecked] = useState(checked)
  const [isNumberAbove, setIsNumberAbove] = useState(numberAbove)
  const [isNumberBelow, setIsNumberBelow] = useState(numberBelow)

  const editing = () => {
    if(!disabled || !isChecked) {
      setEdit(false)
    } else setEdit(e => !e)
  }

  useEffect(() => {
    if (disabled || !isChecked) setEdit(false)
  }, [disabled, isChecked])

  useEffect(() => {
    setIsChecked(checked)
    setIsNumberAbove(numberAbove)
    setIsNumberBelow(numberBelow)
  }, [numberAbove, numberBelow, checked])

  useEffect(() => {
    setData({ ...row, flgEnabled: isChecked, numberAbove: Number(isNumberAbove), numberBelow: Number(isNumberBelow) })
  }, [isNumberAbove, isNumberBelow, isChecked])

  return (
    <div className='flex space-x-3'>
      <CheckboxBasic setIsChecked={(e) => setIsChecked(e) } isChecked={isChecked} disabled={!disabled} style={{background:  (!disabled) && 'white' }} styleChecked={`${ (!disabled) && 'color-disabled' }`} />
      <span className={`flex-wrap flex items-center space-x-1 text-base font-semibold ${!disabled || !isChecked ? 'text-grayDisabled' : 'text-dark-gray'}`}>
        <span>{firstText}</span>
        {edit 
          ? <input
              onChange={({ target }) => setIsNumberAbove(target.value)}
              value={isNumberAbove}
              type="text"
              className="input w-20 px-3 py-2 rounded-xl text-primary"
              autoFocus
            />
          : <span className={`${(disabled && isChecked) && 'text-primary'} font-bold`}>{`${isNumberAbove}%`}</span>
        }
        <span>{lastText}</span>
        {edit 
          ? <input
              onChange={({ target }) => setIsNumberBelow(target.value)}
              value={isNumberBelow}
              type="text"
              className="input w-20 px-3 py-2 rounded-xl text-primary"
              autoFocus
            />
          : <span className={`${(disabled && isChecked) && 'text-primary'} font-bold`}>{`${isNumberBelow}%`}</span>
        }
      </span>
      <MdOutlineModeEditOutline className={`flex-none ${disabled && isChecked ? 'text-primary cursor-pointer' : 'text-grayDisabled'}`} onClick={() => editing()} />
    </div>
  )
}



export { AlertMonitor }